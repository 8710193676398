import React, { useState, useEffect } from 'react';
import CustomTable from '../../components/Table.js';
import AlertBar from '../../components/AlertBar.js';
import '../../StyleSheets/InventorySystem.css';
import Button from '@mui/material/Button';
import { MdGetApp } from 'react-icons/md';
import { CSVLink } from 'react-csv';
import env from '../../utilities/env-loader.cjs';
import { useCustomContext } from '../../hoc/Context.js';
import dayjs from 'dayjs';

const TableHeaders = [
    {id: 'status', name: 'Status'},
    {id: 'name', name: 'Name'},
    {id: 'start', name: 'Start'},
    {id: 'return', name: 'Return'},
    {id: 'notes', name: 'Notes'}
];

const TableHeadersHidden = [
    {id: 'status', name: 'Status'},
    {id: 'name', name: 'Name'},
    {id: 'start', name: 'Start'},
    {id: 'return', name: 'Return'}
];

const initialTimeOffObj = {
    StartDate: "",
    ReturnDate: "",
    FullName: "",
    Notes: "",
    ResponseNotes: "",
    IsActive: "",
    Status: "",
    TimeOffID: "",
    Approved: false,
    CheckApprove: false,
    CheckReject: false,
    DisableOptions: false,
};

const TimeOffCalendar = () => {
    const [Calendar, setCalendar] = useState([]);
    const [TimeOffObj, setTimeOffObj] = useState(initialTimeOffObj);
    const [snackbar, setSnackbar] = useState({
        visible: false,
        message: '',
        severity: 'success'
    });
    const [AllUsers, setAllUsers] = useState([]);
    const [Loading, setLoading] = useState(true);
    const { userState } = useCustomContext();

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const startDate = dayjs().startOf('month').format('YYYY-MM-DD');
            const endDate = dayjs().endOf('month').format('YYYY-MM-DD');
            socket.emit('selectEmpCalendar', startDate, endDate, (calendar) => {
                socket.emit('selectUserEmail', (emails) => {
                    setCalendar(calendar);
                    setAllUsers(emails);
                    setLoading(false);
                });
            });
        }
        return () => { mounted = false; };
    }, []);

    const RowClick = (e) => {
        const obj = Calendar.filter(req => req.TimeOffID == e.target.id).map(req => ({
            ...req,
            ResponseNotes: req.ResponseNotes || "",
            Approved: req.Status === 'Approved',
            CheckApprove: req.Status === 'Approved',
            CheckReject: req.Status === 'Rejected',
            DisableOptions: req.Status !== 'Pending',
        }));
        setTimeOffObj(obj[0]);
        document.getElementById('myModal').style.display = "block";
        document.getElementById('modal_timeoff').style.display = "block";
    };

    const RefreshData = () => {
        socket.emit('selectEmpCalendar', (calendar) => {
            setCalendar(calendar);
            setTimeOffObj(initialTimeOffObj);
        });
    };

    const handleSnackbar = (message, severity) => {
        setSnackbar({ visible: true, message, severity });
    };

    const handleRequest = (objectToInsert, successMessage, errorMessage, callback) => {
        objectToInsert.ResponseNotes = objectToInsert.ResponseNotes || ""; // Ensure ResponseNotes is a string
        objectToInsert.Status = objectToInsert.Status || "Pending"; // Ensure Status is a string
        objectToInsert.TimeOffID = objectToInsert.TimeOffID || 0; // Ensure TimeOffID is a number
        socket.emit('updateEmpTimeOffRequest', objectToInsert, (boolean) => {
            if (boolean) {
                handleSnackbar(successMessage, 'success');
                callback();
            } else {
                handleSnackbar(errorMessage, 'error');
            }
        });
    };

    const YesSelectedHandler = () => {
        const objectToInsert = {
            ...TimeOffObj,
            Status: "Cancelled",
        };
        const _userEmail = AllUsers.find(user => user.FullName === TimeOffObj.FullName).Email;
        socket.emit('cancelTimeOffRequest', objectToInsert, (boolean) => {
            if (boolean) {
                SendCancelEmail([_userEmail, 'brian@registration-technology.com'], objectToInsert);
                handleSnackbar("Time off response recorded. Emails will be sent shortly.", 'success');
                RefreshData();
            } else {
                handleSnackbar('ERROR: There was an error cancelling this request', 'error');
            }
        });
    };

    const SendCancelEmail = async (userEmail, { Status, Response, StartDate, ReturnDate, PrevNotes }) => {
        const to = userEmail.join(',');
        const subject = `RTI Time Off Request Cancelled- ${TimeOffObj.FullName}`;
        const text = `
            <div width="100%" style="text-align:center;">
                <h1 style="text-align:center;">Time Off Request Cancelled</h1>
                <table style="background-color:black; width: 400px;">
                    <tbody>
                        <tr><td style="background-color:bisque; width: 100px;">Employee</td><td style="background-color:white;">${TimeOffObj.FullName}</td></tr>
                        <tr><td style="background-color:bisque;">Requested Start Date</td><td style="background-color:white;">${StartDate}</td></tr>
                        <tr><td style="background-color:bisque;">Requested Return Date</td><td style="background-color:white;">${ReturnDate}</td></tr>
                        <tr><td style="background-color:bisque;">Request Notes</td><td style="background-color:white;">${PrevNotes}</td></tr>
                        <tr><td style="background-color:bisque;">Request Status</td><td style="background-color:white;">${Status}</td></tr>
                        <tr><td style="background-color:bisque;">Response</td><td style="background-color:white;">${Response}</td></tr>
                    </tbody>
                </table>
            </div>`;
        try {
            const response = await fetch(`/send?to=${encodeURIComponent(to)}&subject=${encodeURIComponent(subject)}&text=${encodeURIComponent(text)}`);
            const result = await response.text();
            return result.includes('Mail sent successfully') ? 200 : result;
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    const SaveButton = () => {
        const approve = document.getElementById("approveCheckbox").checked;
        const reject = document.getElementById("rejectCheckbox").checked;
        let status = "";

        if (approve && reject) {
            handleSnackbar("You can't check both checkboxes", 'warning');
        } else if (!approve && !reject) {
            handleSnackbar("You need to select a checkbox", 'warning');
        } else {
            status = approve ? "Approved" : "Rejected";
            const objectToInsert = { ...TimeOffObj, Status: status };
            const _userEmail = AllUsers.find(user => user.FullName === TimeOffObj.FullName).Email;
            handleRequest(objectToInsert, "Time off response recorded. Emails will be sent shortly.", "Error recording time off response.", () => {
                SendEmail([_userEmail, 'brian@registration-technology.com'], objectToInsert);
                RefreshData();
            });
        }
    };

    const SendEmail = async (userEmail, { Status, Response, StartDate, ReturnDate, PrevNotes }) => {
        const to = userEmail.join(',');
        const subject = `RTI Time Off Request Response- ${TimeOffObj.FullName}`;
        const text = `
            <div width="100%" style="text-align:center;">
                <h1 style="text-align:center;">Time Off Request Response</h1>
                <table style="background-color:black; width: 400px;">
                    <tbody>
                        <tr><td style="background-color:bisque; width: 100px;">Employee</td><td style="background-color:white;">${TimeOffObj.FullName}</td></tr>
                        <tr><td style="background-color:bisque;">Requested Start Date</td><td style="background-color:white;">${StartDate}</td></tr>
                        <tr><td style="background-color:bisque;">Requested Return Date</td><td style="background-color:white;">${ReturnDate}</td></tr>
                        <tr><td style="background-color:bisque;">Request Notes</td><td style="background-color:white;">${PrevNotes}</td></tr>
                        <tr><td style="background-color:bisque;">Request Status</td><td style="background-color:white;">${Status}</td></tr>
                        <tr><td style="background-color:bisque;">Response</td><td style="background-color:white;">${Response}</td></tr>
                    </tbody>
                </table>
            </div>`;
        try {
            const response = await fetch(`/send?to=${encodeURIComponent(to)}&subject=${encodeURIComponent(subject)}&text=${encodeURIComponent(text)}`);
            const result = await response.text();
            return result.includes('Mail sent successfully') ? 200 : result;
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    const getTableData = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - 12);
        return Calendar
            .filter(row => row.Status !== 'Cancelled' && row.Status !== 'Deleted')
            .filter(row => date.getTime() <= new Date(row.StartDate).getTime())
            .map(calendar => ({
                key: calendar.TimeOffID,
                cells: userState.user.permissions === env.USER_PERMS[0] ? [
                    { data: calendar.Status },
                    { data: calendar.FullName },
                    { data: calendar.StartDate },
                    { data: calendar.ReturnDate },
                    { data: calendar.Notes }
                ] : [
                    { data: calendar.Status },
                    { data: calendar.FullName },
                    { data: calendar.StartDate },
                    { data: calendar.ReturnDate }
                ]
            }))
            .sort((a, b) => new Date(b.cells[2].data).getTime() - new Date(a.cells[2].data).getTime());
    };

    const renderTableButtons = () => {
        return userState.user.permissions === env.USER_PERMS[0] ? (
            <div style={{ display: 'flex' }}>
                <CSVLink style={{ background: 'none', border: 'none' }} target="_blank" className="hidden" id="table-download" data={downloadData()} filename={"time-off-calendar.csv"}></CSVLink>
                <Button
                    className="rti-blue-round"
                    variant="contained"
                    startIcon={<MdGetApp />}
                    onClick={() => document.getElementById('table-download').click()}>Download</Button>
            </div>
        ) : null;
    };

    const downloadData = () => {
        return Calendar
            .filter(data => !data.StartDate.includes('undefined'))
            .map(calendar => ({
                "Name": calendar.FullName,
                "Start": calendar.StartDate,
                "Return": calendar.ReturnDate,
                "Notes": calendar.Notes,
                "Status": calendar.Status
            }))
            .sort((a, b) => new Date(b.Start.split(' ')[0]).getTime() - new Date(a.Start.split(' ')[0]).getTime());
    };

    const handleCloseSnack = () => {
        setSnackbar(prevState => ({ ...prevState, visible: false }));
    };

    return (
        <div className="ViewedContentContainer" id="OpenContainer">
            <div style={{ height: '100%' }}>
                <div className="modal" id="myModal">
                    <div className="modal-content" id="modal_timeoff">
                        <div className="DispatcherForm">
                            <div className="Modal-Background" id="cancel-request-modal" style={{ zIndex: '3', display: 'none', textAlign: 'center' }}>
                                <div className="Delete-Modal">
                                    <div className="Delete-Content">
                                        <div id="warning" style={{ color: 'red', fontSize: '100px' }}>!</div>
                                        <div id="warning-prompt">
                                            <p style={{ fontSize: '20px' }}><b>Are you sure you want to cancel this request for {TimeOffObj.FullName} on {TimeOffObj.StartDate}?</b></p>
                                            <p style={{ marginBottom: '16%' }}>(This action will remove the request from the Time Off Calendar)</p>
                                        </div>
                                        <div>
                                            <button style={{ marginRight: '12%' }} className="RTIButtonInv1" onClick={() => document.getElementById('cancel-request-modal').style.display = 'none'}>No</button>
                                            <button className="RTIButtonInv1" onClick={YesSelectedHandler}>Yes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="FirstLayer">
                                <label>Time Off Request For:</label>
                                <p><b>{TimeOffObj.FullName}</b></p>
                                <label>Time Off Start Date:</label>
                                <p><b>{TimeOffObj.StartDate}</b></p>
                                <label>Time Off Return Date:</label>
                                <p><b>{TimeOffObj.ReturnDate}</b></p>
                                <label>Provided Notes:</label>
                                <p style={{ width: "418px" }}><b>{TimeOffObj.Notes}</b></p>
                            </div>
                            <div className="SecondLayer">
                                <label>Approve</label>
                                <input type="checkbox" id="approveCheckbox" checked={TimeOffObj.CheckApprove} disabled={TimeOffObj.DisableOptions} onChange={(e) => setTimeOffObj(prevState => ({ ...prevState, CheckApprove: e.target.checked }))}></input>
                                <label>Reject</label>
                                <input type="checkbox" id="rejectCheckbox" checked={TimeOffObj.CheckReject} disabled={TimeOffObj.DisableOptions} onChange={(e) => setTimeOffObj(prevState => ({ ...prevState, CheckReject: e.target.checked }))}></input>
                                <label>Your Notes:</label>
                                <textarea className="NotesInput" id="NotesInput" value={TimeOffObj.ResponseNotes} style={{ width: '100%', height: '75px' }} onChange={(e) => setTimeOffObj(prevState => ({ ...prevState, ResponseNotes: e.target.value }))}></textarea>
                            </div>
                            <div className='flex-wrap flex-just-between'>
                                <button className="RTIButton" onClick={() => {
                                    document.getElementById('modal_timeoff').style.display = "none";
                                    document.getElementById('myModal').style.display = "none";
                                    setTimeOffObj(initialTimeOffObj);
                                }}>CLOSE</button>
                                <button className="RTIButton" onClick={() => document.getElementById('cancel-request-modal').style.display = 'block'}>CANCEL REQUEST</button>
                                <button className="RTIButton" onClick={() => {
                                    const res = prompt(`You are about to remove this request from the calendar.  If you want to remove this request, then type DELETE in the inbox below, otherwise click CANCEL.`);
                                    if (res === 'DELETE') {
                                        const objectToInsert = { ...TimeOffObj, Status: "Deleted" };
                                        handleRequest(objectToInsert, "Success!  Time off request removed.", "Error removing this time off request.", RefreshData);
                                    }
                                }}>REMOVE REQUEST</button>
                                <button className="RTIButton" onClick={SaveButton}>SAVE</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="TitleBarContainer">
                    <h1 title="Time Off Requests">Time Off Requests</h1>
                </div>
                <AlertBar
                    visible={snackbar.visible}
                    message={snackbar.message}
                    severity={snackbar.severity}
                    onClose={handleCloseSnack}
                />
                <CustomTable
                    paginate
                    loading_data={Loading}
                    headers={userState.user.permissions === env.USER_PERMS[0] ? TableHeaders : TableHeadersHidden}
                    rows={getTableData()}
                    onClick={RowClick}
                    table_buttons={renderTableButtons()}
                />
            </div>
        </div>
    );
};

export default TimeOffCalendar;