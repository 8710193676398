import React, { useState } from 'react';
import PropTypes from 'prop-types';

const IssueModal = (props) => {
	const [state, setState] = useState({
		KioskIssueID: props.issueModal?.KioskIssueID || '',
		ErrorCode: props.issueModal?.ErrorCode || '',
		Description: props.issueModal?.Description || '',
		ToDoNext: props.issueModal?.ToDoNext ? JSON.parse(props.issueModal.ToDoNext) : {},
		ToDoNextInput: '',
	});

	const IssueCloseButton = () => {
		document.getElementById('modal_kiosk_issues').style.display = 'none';
		document.getElementById('myModal').style.display = 'none';
	};

	const onChange = (e, whichValue) => {
		setState((prevState) => ({
			...prevState,
			[whichValue]: e.target.value,
		}));
	};

	const RenderList = (passedValue, passedArray, passedListClassName, passedTableListClassName) => {
		const returnArray = [];
		const keys = Object.keys(passedArray);
		if (passedArray) {
			for (let i = 0; i < keys.length; i++) {
				returnArray.push(
					<li key={i} className={passedListClassName} id={passedArray[keys[i]]} value={passedArray[keys[i]]}>
						{passedArray[keys[i]]}
					</li>
				);
			}
		}

		return (
			<ul className={passedTableListClassName} id={passedTableListClassName}>
				{returnArray}
			</ul>
		);
	};

	const PlusTechButton = () => {
		if (document.getElementById('ToDoNextTextInput').value) {
			const ToDoNext = state.ToDoNext;
			const selectValue = document.getElementById('ToDoNextTextInput').value;
			if (Object.values(ToDoNext).indexOf(selectValue) > -1) {
				return false;
			}
			const step = String.fromCharCode(Object.keys(ToDoNext).length + 65);
			ToDoNext[step] = selectValue;
			setState((prevState) => ({
				...prevState,
				ToDoNext: ToDoNext,
			}));
			document.getElementById('ToDoNextTextInput').value = '';
		}
	};

	const MinusTechButton = () => {
		let ToDoNext = state.ToDoNext;
		let search_term = state.PrimaryTechValue;
		let partsListArray = document.getElementsByClassName('ToDoNextList');
		// ...existing code...
	};

	const IssueDeleteButton = () => {
		const areYouSure = prompt(
			"Are you sure you want to delete this issue? This could cause problems. Alerts will not properly display if you remove the alerts that come in from Timothy. Type 'YES' to confirm",
			''
		);
		if (areYouSure) {
			if (areYouSure.toLowerCase() === 'yes') {
				const confirmDelete = prompt("Type 'DELETE' to confirm deleting this issue.", '');
				if (confirmDelete) {
					if (confirmDelete.toLowerCase() === 'delete') {
						const KioskIssueID = state.KioskIssueID;
						socket.emit('deleteKioskIssueForTM', KioskIssueID, (boolean) => {
							if (boolean) {
								props.refresh_data();
								IssueCloseButton();
							} else {
								alert('Kiosk Issue was not deleted.');
							}
						});
					}
				}
			}
		}
	};

	const IssueSaveButton = () => {
		const objectToInsert = {
			KioskIssueID: state.KioskIssueID,
			ErrorCode: state.ErrorCode,
			Description: state.Description,
			ToDoNext: state.ToDoNext,
		};

		socket.emit('updateIssueForTM', objectToInsert, (boolean) => {
			if (boolean) {
				props.refresh_data();
				IssueCloseButton();
			} else {
				alert('You did not fill the form out correctly');
			}
		});
	};

	const IssueAddButton = () => {
		const objectToInsert = {
			ErrorCode: state.ErrorCode,
			Description: state.Description,
			ToDoNext: state.ToDoNext,
		};

		socket.emit('insertIssueForTM', objectToInsert, (boolean) => {
			if (boolean) {
				alert('Issue Saved Successfully.');
				props.refresh_data();
				IssueCloseButton();
			} else {
				alert('You did not fill the form out correctly');
			}
		});
	};

	return (
		<div className='IssueForm'>
			<div className='FirstLayer'>
				<div className='DispatcherNameInput'>
					<label>Error</label>
					<br />
					<input
						className='IssueErrorCodeInput'
						id='IssueErrorCodeInput'
						type='text'
						maxLength='40'
						placeholder='Error Code'
						title='This is the Error code we recieve from Timothy. This needs to match.'
						value={state.ErrorCode}
						onChange={(e) => onChange(e, 'ErrorCode')}
					/>
				</div>
			</div>

			<div className='SecondLayer'>
				<div className='IssueDescription'>
					<label>Description</label>
					<br />
					<textarea
						className='IssueDescriptionInput'
						id='IssueDescriptionInput'
						value={state.Description}
						onChange={(e) => onChange(e, 'Description')}
						placeholder='Description of Error.'
						title='This is the error that we see on our end in Kiosk Alerts. It does not need to match what Timothy sends over.'
					></textarea>
				</div>
			</div>

			<div className='ToDoNextTableContent'>
				<div className='ToDoNextDiv'>
					<label className='ToDoNextLabel'>To Do Next</label>
					<div className='ToDoNextHeader'>
						<input
							className='ToDoNextTextInput'
							id='ToDoNextTextInput'
							type='text'
							placeholder='Next Step'
							value={state.ToDoNextInput}
							title='These are the steps take to solve this issue. It is better to edit the steps in the MySQL Database, but if you just need to add a quick step, this will work too.'
							onChange={(e) => onChange(e, 'ToDoNextInput')}
						/>
						<button className='MinusTechButton' id='MinusTechButton' onClick={MinusTechButton}>
							-
						</button>
						<button className='PlusTechButton' id='PlusTechButton' onClick={PlusTechButton}>
							+
						</button>
					</div>
					{RenderList('', state.ToDoNext, 'ToDoNextList', 'ToDoNextListTable')}
				</div>
			</div>
			<div>
				<button className='RTIButton' id='ISSUFormClose' onClick={IssueCloseButton}>
					CLOSE
				</button>
				{props.issueKey && props.role ? (
					<button
						className='RTIButton'
						id='ISSUFormDelete'
						onClick={IssueDeleteButton}
						style={{ position: 'absolute', top: '0', right: '0' }}
					>
						DELETE
					</button>
				) : undefined}
				{props.issueKey && props.role ? (
					<button className='RTIButton' id='ISSUFormSave' onClick={IssueSaveButton} style={{ float: 'right' }}>
						SAVE
					</button>
				) : undefined}
				{!props.issueKey && props.role ? (
					<button className='RTIButton' id='ISSUFormAdd' onClick={IssueAddButton} style={{ float: 'right' }}>
						ADD
					</button>
				) : undefined}
			</div>
		</div>
	);
};

IssueModal.propTypes = {
	issueModal: PropTypes.shape({
		KioskIssueID: PropTypes.string,
		ErrorCode: PropTypes.string,
		Description: PropTypes.string,
		ToDoNext: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	}),
	refresh_data: PropTypes.func,
	issueKey: PropTypes.string,
	role: PropTypes.string,
};

export default IssueModal;
