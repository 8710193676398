import mysql from 'promise-mysql';
import env from '../../utilities/env-loader.cjs';
import dotenv from 'dotenv';
dotenv.config();

async function createPool() {
    try {
        const pool = await mysql.createPool({
            connectionLimit: 60,
            host: env.DB.HOST,
            user: process.env.DB_USER,
            password: process.env.DB_PASSWORD,
            database: env.DB.NAME,
            port: env.DB.PORT
        });

        return pool;
    } catch (error) {
        console.error('Error creating database pool:', error);
        throw error;
    }
}

const pool = await createPool();

export default pool;
