import React, { useState, useEffect } from 'react';
import CustomTable from '../../components/Table';
import Modal from '../../components/Modal';
import { AiOutlineSearch } from 'react-icons/ai';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { MdCropFree } from 'react-icons/md';
import AlertBar from '../../components/AlertBar';
import { DateToLocale } from '../API/TextFormatingFunctions';
import { useNavigate, useLocation } from 'react-router-dom';
import $ from 'jquery';

const TableHeaders = [
	{ id: 'item', name: 'Item', sortable: true, numeric: false },
	{ id: 'item-type', name: 'Type', sortable: true, numeric: false },
	{ id: 'qty', name: 'Qty', sortable: true, numeric: true },
	{ id: 'model', name: 'Model #', sortable: true, numeric: false },
	{ id: 'vendor', name: 'Vendor', sortable: true, numeric: false },
];

const TableHeaders2 = [
	{ id: 'bin', name: 'item', sortable: true, numeric: false },
	{ id: 'location', name: 'location', sortable: true, numeric: false },
	{ id: 'bin #', name: 'bin #', sortable: true, numeric: false },
	{ id: 'qty', name: 'Qty', sortable: true, numeric: true },
	{ id: 'contractor', name: 'contractor', sortable: true, numeric: false },
];

const TableHeaders3 = [
	{ id: 'item', name: 'item', sortable: true, numeric: false },
	{ id: 'qty', name: 'qty', sortable: true, numeric: true },
	{ id: 'rid', name: 'rid', sortable: true, numeric: false },
	{ id: 'timestamp', name: 'timestamp' },
	{ id: 'updated by', name: 'updated by', sortable: true, numeric: false },
	{ id: 'location', name: 'location', sortable: true, numeric: false },
	{ id: 'serial/sim', name: 'serial/sim', sortable: true, numeric: true },
	{ id: 'imei', name: 'imei', sortable: true, numeric: false },
];

const SearchOptions = [
	{ id: 'item', name: 'Item', pidx: 0 },
	{ id: 'item-type', name: 'Type', pidx: 1 },
	{ id: 'model', name: 'Model', pidx: 3 },
	{ id: 'vendor', name: 'Vendor', pidx: 4 },
];

const SearchOptions2 = [
	{ id: 'bin', name: 'Bin', pidx: 0 },
	{ id: 'contractor', name: 'Contractor', pidx: 3 },
];

const SubSearchOpts = [{ id: 'rid', name: 'RID', pidx: 2 }];

const TableTabs = [
	{ id: 'items', name: 'items', filter: 'items' },
	{ id: 'bins', name: 'bins', filter: 'bins' },
];

const AllItems = () => {
	const _query = new URLSearchParams(window.location.search);
	const navigate = useNavigate();
	const location = useLocation();
	const _params = Array.from(_query.keys()).reduce(
		(acc, val) => ({
			...acc,
			[val]: _query.get(val),
		}),
		{}
	);

	const [loading, setLoading] = useState(true);
	const [searchVal, setSearchVal] = useState(_params.search ? _params.search : '');
	const [searchParam, setSearchParam] = useState(_params.param ? _params.param : '');
	const [subVal, setSubVal] = useState('');
	const [subParam, setSubParam] = useState('');
	const [inventory, setInventory] = useState([]);
	const [activeTab, setActiveTab] = useState(_params.filter ? _params.filter : TableTabs[0].filter);
	const [invItem, setInvItem] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [findItem, setFindItem] = useState(false);
	const [itemLocation, setItemLocation] = useState('');
	const [kioskItems, setKioskItems] = useState([]);
	const [kioskModal, setKioskModal] = useState(false);
	const [snackbar, setSnackbar] = useState({
		visible: false,
		message: '',
		severity: 'success',
	});

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			retrieveData(true);
		}

		return () => {
			mounted = false;
		};
	}, [activeTab]);

	useEffect(() => {
		$('#item-modal').toggleClass('flex-box');
	}, [modalOpen]);

	useEffect(() => {
		$('#find-item').toggleClass('flex-box');
	}, [findItem]);

	useEffect(() => {
		$('#kiosk-modal').toggleClass('flex-box');
	}, [kioskModal]);

	const retrieveData = (load) => {
		if (load) setLoading(true);
		socket.emit('getAllInventory', activeTab, ([items]) => {
			if (!items) {
				console.error(`AllItems.getAllInventory: There was an issue calling this method`);
				setLoading(false);
			} else {
				setInventory(items);
				setLoading(false);
			}
		});
	};

	const handleURLParams = (_params, _action) => {
		const urlParams = new URLSearchParams(location.search);
		_params.forEach((param) => {
			if (_action === 'add') {
				urlParams.set(param.key, param.val);
			} else {
				urlParams.delete(param.key);
			}
		});
		navigate(`${location.pathname}?${urlParams.toString()}`, { replace: true });
	};

	const rowClick = (event) => {
		const id = event.target.id;

		socket.emit('getInventoryByItem', id, (res) => {
			if (!res) {
				console.error(`AllItems.getInventoryByItem: There was an issue calling this method`);
			} else {
				setInvItem(res);
				setModalOpen(true);
			}
		});
	};

	const rowBinClick = (event) => {
		const id = event.target.id;
		const bin_id = inventory.find((bin) => bin.id == id);

		if (bin_id) {
			navigate(`/inventory/bin/${bin_id.code}`);
		}
	};

	const renderTablebody = () => {
		return inventory
			.map((item) => ({
				key: item.id,
				cells: [
					{ data: item.item_name },
					{ data: item.class_name },
					{ data: item.qty, color: item.qty <= 5 ? 'red' : null },
					{ data: item.model },
					{ data: item.vendor },
				],
			}))
			.filter((row) =>
				searchVal && searchParam
					? row.cells[SearchOptions[searchParam - 1].pidx].data &&
					  row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchVal.toUpperCase())
					: row
			);
	};

	const handleCloseSnack = () => {
		setSnackbar((prevState) => ({
			...prevState,
			visible: false,
		}));
	};

	const renderBinBody = () => {
		return inventory
			.map((item) => ({
				key: item.id,
				cells: [
					{ data: item.name },
					{ data: item.location ? item.location : '-' },
					{ data: item.code },
					{ data: item.qty, color: item.qty <= 5 ? 'red' : null },
					{ data: item.cname ? item.cname : '' },
				],
			}))
			.filter((row) =>
				searchVal && searchParam
					? row.cells[SearchOptions2[searchParam - 1].pidx].data &&
					  row.cells[SearchOptions2[searchParam - 1].pidx].data.toUpperCase().includes(searchVal.toUpperCase())
					: row
			);
	};

	const renderItemBody = (_data) => {
		const _arr = !_data ? invItem : kioskItems;

		return _arr
			.map((item) => {
				const item_info = JSON.parse(item.item_info);
				return {
					key: item.id,
					cells: [
						{ data: item.item_name ?? '-' },
						{ data: item.qty ?? '-' },
						{
							data: item.barcode && item.barcode != 'null' ? item.barcode : '-',
						},
						{ data: item.created && item.created !== 'null' ? DateToLocale(new Date(item.created)) : '-' },
						{ data: item.created_by ?? '-' },
						{ data: item.location ?? '-' },
						{ data: item_info?.sim?.item_info?.serial ?? '-' },
						{ data: item_info?.item_info?.imei ?? '-' },
					],
				};
			})
			.filter((row) =>
				subVal && subParam
					? row.cells[SubSearchOpts[subParam - 1].pidx].data &&
					  row.cells[SubSearchOpts[subParam - 1].pidx].data.toUpperCase().includes(subVal.toUpperCase())
					: row
			);
	};

	const onClickItem = (event) => {
		const id = event.target.id;
		const data = kioskItems.length ? kioskItems.find((item) => item.id == id) : invItem.find((item) => item.id == id);

		if (data) {
			socket.emit('getInventoryItems', ([items, models]) => {
				if (!items) {
					console.error('getInventoryItems: There was an issue calling this method');
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message:
							'SERVER ERROR: There was an issue retrieving inventory items from the database. Please notify the developer of this issue.',
						severity: 'error',
					}));
				} else {
					const item = items.find((el) => data && data.barcode.substring(0, 4) == el.code);

					if (item) {
						const copy = { ...item, barcode: data.barcode };
						navigate(`/inventory/receive/${data.barcode}`, { state: { inv_item: copy, models: models } });
					}
				}
			});
		}
	};

	const renderTableButtons = () => {
		return (
			<div>
				<Button
					className='rti-blue-round'
					endIcon={<AiOutlineSearch />} // Updated icon usage
					onClick={onToggleFindItem}
				>
					Locate Item
				</Button>
				<Button className='rti-blue-round' onClick={onToggleKioskItems}>
					Kiosk Items
				</Button>
			</div>
		);
	};

	const Search = (param, searchVal) => {
		setSearchVal(searchVal);
		setSearchParam(param);
		handleURLParams(
			[
				{
					key: 'search',
					val: searchVal,
				},
				{
					key: 'param',
					val: param,
				},
			],
			'add'
		);
	};

	const SubSearch = (param, searchVal) => {
		setSubVal(searchVal);
		setSubParam(param);
	};

	const ClearSubSearch = () => {
		setSubVal('');
		handleURLParams(
			[
				{
					key: 'search',
				},
				{
					key: 'param',
				},
			],
			'remove'
		);
	};

	const ClearSearch = () => {
		setSearchVal('');
		handleURLParams(
			[
				{
					key: 'search',
				},
				{
					key: 'param',
				},
			],
			'remove'
		);
	};

	const filterTable = (filter) => {
		setActiveTab(filter);
		handleURLParams(
			[
				{
					key: 'filter',
					val: filter,
				},
			],
			'add'
		);
	};

	const onToggleModal = () => {
		setModalOpen((prevState) => !prevState);
	};

	const onToggleFindItem = () => {
		setFindItem((prevState) => !prevState);
	};

	const onToggleKioskItems = () => {
		if (kioskModal) setKioskItems([]);
		setKioskModal((prevState) => !prevState);
	};

	const onLocateItem = () => {
		const _item = document.getElementById('locate-item').value;
		setItemLocation('');

		if (_item) {
			socket.emit('getInventory', _item, (res) => {
				if (!res) {
					console.error(`AllItems.getInventory: There was an issue calling this method`);
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: 'SERVER ERROR: Our system is having troubles locating this item. Please notify the developer.',
						severity: 'error',
					}));
				} else {
					if (res.length) {
						const _location = res[0].location;
						setItemLocation(_location);
					} else {
						setSnackbar((prevState) => ({
							...prevState,
							visible: true,
							message:
								'WARNING: Sorry we could not find the location of this item. Make sure the RID# you have entered is correct, and try again.',
							severity: 'warning',
						}));
					}
				}
			});
		} else {
			setSnackbar((prevState) => ({
				...prevState,
				visible: true,
				message: 'WARNING: Please scan the items RID#.',
				severity: 'warning',
			}));
		}
	};

	const onGoToLocation = (event) => {
		event.preventDefault();
		navigate(`/inventory/bin/${itemLocation}`);
	};

	const onGetKioskItems = () => {
		const _kiosk = document.getElementById('search-kiosk').value;

		if (_kiosk) {
			socket.emit('getItemsInKiosk', _kiosk, (res) => {
				if (!res) {
					console.error(`AllItems.getItemsInKiosk: There was an issue calling this method`);
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message:
							'SERVER ERROR: Our system is having troubles obtaining the items for this kiosk. Please notify the developer.',
						severity: 'error',
					}));
				} else if (!res.length) {
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: 'WARNING: Looks like there are no items assigned to this kiosk.',
						severity: 'warning',
					}));
				} else {
					setKioskItems(res);
				}
			});
		} else {
			setSnackbar((prevState) => ({
				...prevState,
				visible: true,
				message: 'WARNING: Please enter a valid Kiosk#.',
				severity: 'warning',
			}));
		}
	};

	return (
		<div style={{ width: '98%' }} className='ViewedContentContainer' id='OpenContainer'>
			<div style={{ height: '100%' }}>
				<div className='TitleBarContainer'>
					<h1>Inventory</h1>
				</div>
				{modalOpen ? (
					<Modal
						modal_id='item-modal'
						dimension={{ width: 1400 }}
						modal_header={
							invItem.length && activeTab == 'items' ? invItem[0].item_name : invItem.length && invItem[0].bin_name
						}
						onClose={onToggleModal}
					>
						<CustomTable
							searchable
							paginate
							rpp={50}
							search={SubSearch}
							clear_search={ClearSubSearch}
							search_options={SubSearchOpts}
							headers={TableHeaders3}
							rows={renderItemBody()}
							onClick={onClickItem}
							table_buttons={renderTableButtons()}
						/>
					</Modal>
				) : null}

				{findItem ? (
					<Modal modal_id='find-item' dimension={{ width: 500 }} modal_header='Locate Item' onClose={onToggleFindItem}>
						<div className='text-center'>
							<div className='innerContainer-pcore vert-margin-med'>
								<FormControl variant='standard'>
									<Input
										style={{ width: 232, fontSize: 16 }}
										id='locate-item'
										placeholder='Scan barcode...'
										startAdornment={
											<InputAdornment position='start'>
												<MdCropFree /> {/* Updated icon usage */}
											</InputAdornment>
										}
									/>
								</FormControl>
							</div>
							{itemLocation ? (
								<div>
									<label>Item Found!</label>
									<br></br>
									<a style={{ color: 'blue', textDecoration: 'underline' }} href='#' onClick={(e) => onGoToLocation(e)}>
										{itemLocation}
									</a>
								</div>
							) : null}
							<div className='vert-margin-med'>
								<Button className='rti-blue-round' onClick={onLocateItem}>
									Locate
								</Button>
							</div>
						</div>
					</Modal>
				) : null}

				{kioskModal ? (
					<Modal
						modal_id='kiosk-modal'
						dimension={!kioskItems.length ? { width: 500 } : { width: 1400 }}
						modal_header={kioskItems.length ? `Kiosk Items - ${kioskItems[0].location}` : 'Kiosk Items'}
						onClose={onToggleKioskItems}
					>
						<div className='text-center'>
							{!kioskItems.length ? (
								<div className='innerContainer-pcore vert-margin-med'>
									<FormControl variant='standard'>
										<Input
											style={{ width: 232, fontSize: 16 }}
											id='search-kiosk'
											placeholder='Kiosk#...'
											startAdornment={
												<InputAdornment position='start'>
													<AiOutlineSearch /> {/* Updated icon usage */}
												</InputAdornment>
											}
										/>
									</FormControl>
								</div>
							) : (
								<CustomTable
									searchable
									paginate
									rpp={50}
									search={SubSearch}
									clear_search={ClearSubSearch}
									search_options={SubSearchOpts}
									headers={TableHeaders3}
									rows={renderItemBody(true)}
									onClick={onClickItem}
									table_buttons={renderTableButtons()}
								/>
							)}
							{!kioskItems.length ? (
								<div className='vert-margin-med'>
									<Button className='rti-blue-round' onClick={onGetKioskItems}>
										Go
									</Button>
								</div>
							) : null}
						</div>
					</Modal>
				) : null}

				<CustomTable
					searchable
					paginate
					rpp={50}
					loading_data={loading}
					search={Search}
					clear_search={ClearSearch}
					headers={activeTab == TableTabs[0].filter ? TableHeaders : TableHeaders2}
					search_options={activeTab == TableTabs[0].filter ? SearchOptions : SearchOptions2}
					tabs={TableTabs}
					tab_style='button'
					active_tab={activeTab}
					filter_data={filterTable}
					rows={activeTab == TableTabs[0].filter ? renderTablebody() : renderBinBody()}
					onClick={activeTab == TableTabs[0].filter ? rowClick : rowBinClick}
					table_buttons={renderTableButtons()}
					onRefresh={() => retrieveData(true)}
				/>

				<AlertBar
					visible={snackbar.visible}
					onClose={handleCloseSnack}
					message={snackbar.message}
					severity={snackbar.severity}
				/>
			</div>
		</div>
	);
};

export default AllItems;
