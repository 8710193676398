// Counter Component
/**
 * TODO: When adding to an empty repurpose bin, need to have it automatically add a base barcode, name, etc. Currently if adding to a completely empty bin, nothing will show up. Something has to be in there first.
 * Look at the socket call and see what it is updating on the DB. Once the qty is set to 0 it is probably losing the barcode information that is there.
 *
 * TODO: Add checking to the scan function to check the year of the barcode. If year !== name reject it and throw an error
 * TODO: Set param to the appropriate bin dynamically instead of setting the bins via string in the useEffect. Possibly change the rprpos bin to have a year after it e.g., rprpos25
 */

import React from 'react';
import { useState, useEffect } from 'react';
import '../../StyleSheets/Button.css';
import '../../StyleSheets/StickerCounter.css';
import '../../StyleSheets/InventorySystem.css';
import AlertBar from '../../components/AlertBar';
import { validateBinLocation } from '../../utilities/util';
import { TransferItemModal } from './InventoryItemModals/TransferItemModal';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

// Updated imports from react-icons
import { MdCompareArrows as TransferIcon, MdPublish as PublishIcon, MdCropFree as ScanIcon } from 'react-icons/md';
import PropTypes from 'prop-types'; // Ensure this import is present
import $ from 'jquery'; // Add this import

const Counter = (props) => {
	const [stickerCount, setStickerCount] = useState(0);
	const [binID, setbinID] = useState(0);
	const [inputField, setinputField] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [, setErrors] = useState({});
	const [loading, setLoading] = useState(true);
	const [bins, setBins] = useState([]);
	const [bin, setBin] = useState(null);
	const [items, setItems] = useState([]);
	const [nextBarcodeItem, setNextBarcodeItem] = useState({});
	const [, setBinQty] = useState('');
	const [submitQty, setSubmitQty] = useState(0);
	const [, setTransferring] = useState(false);
	const [ridTrigger, setRIDTrigger] = useState(false);
	const [, setComponentsToUpdate] = useState([]);
	const { name } = props;

	const [snackbar, setSnackbar] = useState({
		visible: false,
		message: '',
		severity: 'success',
	});
	const transferItemModalProps = {
		items: items,
		bins: bins,
		toggleModal: toggleModal,
		stickerCount: stickerCount,
		handleTransferItems: handleTransferItems,
		parent: 'Sticker Counter',
		name,
		loading,
	};
	const currentYear = new Date().getFullYear();
	const nextYear = currentYear + 1;
	const futureYear = currentYear + 2;

	useEffect(() => {
		getStickerCount();
	}, []);

	useEffect(() => {
		$('#transfer-items').toggleClass('flex-box');
	}, [modalOpen]);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			findNextRID();
		}

		return () => {
			mounted = false;
		};
	}, [ridTrigger]);
	useEffect(() => {
		let mounted = true;

		if (mounted) {
			if (name === currentYear) {
				const param = `BIN-RPRPOS${name - 2022}`;
				retrieveData(true, param);
			} else if (name === nextYear) {
				const param = `BIN-RPRPOS${name - 2022}`;
				retrieveData(true, param);
			} else if (name === futureYear) {
				const param = `BIN-RPRPOS${name - 2022}`;
				retrieveData(true, param);
			}
		}
		return () => {
			mounted = false;
		};
	}, []);

	const handleCloseSnack = () => {
		setSnackbar((prevState) => ({
			...prevState,
			visible: false,
		}));
	};

	const findRepurposedItemsForTransfer = async (transfer_location, repurpose_transfer_qty) => {
		return new Promise((resolve, reject) => {
			socket.emit('findRepurposedItemsForTransfer', transfer_location, repurpose_transfer_qty, (res) => {
				if (!res) {
					console.error(
						`Counter.findRepurposedItemsForTransfer: There was an issue calling this method - ${new Date()}`
					);
					reject(new Error(`Counter.findRepurposedItemsForTransfer: There was an issue calling this method`));
				} else {
					setComponentsToUpdate(...res[0]);
					resolve(res[0]);
				}
			});
		});
	};

	const retrieveData = (_load, _param) => {
		if (_load) setLoading(true);

		socket.emit('getAllBinLocations', (res) => {
			if (!res) {
				console.error(`Counter.getAllBinLocations: There was an issue calling this method - ${new Date()}`);
			} else {
				setBins(res);
			}
		});

		socket.emit('getInventoryBins', _param, (bin) => {
			if (!bin) {
				console.error(`Counter.getInventoryBins: There was an issue calling this method - ${new Date()}`);
				setLoading(false);
			} else {
				socket.emit('getBuildItems', (res) => {
					if (!res) {
						console.error(`Counter.getBuildItems: There was an issue calling this method - ${new Date()}`);
						setLoading(false);
					} else {
						setItems(bin);
						setBin(_param);
						setLoading(false);
					}
				});
			}
		});
	};

	async function handleTransferItems(controllerData) {
		const found_items = await findRepurposedItemsForTransfer(bin, parseInt(controllerData.transfer_qty));
		for (let i = 0; i < found_items.length; i++) {
			if (!found_items[i].previous_remainder) {
				found_items[i].location = controllerData.location;
			}
		}
		setLoading(true);
		const valid = await validateForm(found_items, 'transfer');
		if (valid.valid) {
			const vals = [...valid.vals];
			await updateIntoInventory(vals, 'update');

			const currentDateTime = new Date().toISOString().slice(0, 19).replace('T', ' ');
			const contractorJobID = await getNextStickerJobID();
			const findContractor = vals.find((el) => el.contractor !== '');
			const nonContractorComponent = vals.find((el) => el.contractor === '');
			const nonContractorComponentIndex = vals.indexOf(nonContractorComponent);

			if (nonContractorComponentIndex > -1) {
				vals.splice(nonContractorComponentIndex, 1);
			}
			if (findContractor) {
				await insertStickerJob(
					contractorJobID,
					localStorage.getItem('FullName'),
					currentDateTime,
					vals,
					name,
					findContractor.contractor,
					'Pending'
				);
			}
			const initialValue = 0;
			const total_stickers_out = vals.reduce((accumulator, currentValue) => {
				if (!currentValue.barcode.includes('SIL')) {
					return accumulator + currentValue.qty;
				}
				return accumulator;
			}, initialValue);
			setLoading(false);
			setStickerCount(stickerCount - total_stickers_out);
		} else {
			setSnackbar((prevState) => ({
				...prevState,
				visible: true,
				message: `WARNING: ${valid.msg}`,
				severity: 'warning',
			}));
			setLoading(false);
		}
	}

	const updateIntoInventory = (vals, action, _multi, _msg) => {
		return new Promise((resolve, reject) => {
			try {
				socket.emit('updateInventory', vals, vals[0].info, action, (res) => {
					if (!res) {
						console.error(`Counter.updateIntoInventory: There was an issue calling this method - ${new Date()}`);
						reject(false);
					} else {
						if (!_multi) {
							setSnackbar((prevState) => ({
								...prevState,
								visible: true,
								message: `Success! ${_msg ? _msg : `Item(s) transferred successfully.`}`,
								severity: 'success',
							}));
							retrieveData(false, bin);
							setModalOpen(false);
						}
						resolve(true);
					}
				});
			} catch (error) {
				console.error(`Counter.updateIntoInventory: ${error} - ${new Date()}`);
				reject(error);
			}
		});
	};

	const getNextStickerJobID = () => {
		return new Promise((resolve, reject) => {
			socket.emit('GenerateStickerContractorJobID', (generatedJobID) => {
				try {
					resolve(generatedJobID[0][0].next_job_id);
				} catch (error) {
					console.error(`ERROR: inventoryBin.GenerateStickerContractorJobID: ${error} on ${new Date()}`);
					reject(error);
				}
			});
		});
	};

	const insertStickerJob = (job_id, user, completed, components, sticker_year, contractor, status) => {
		return new Promise((resolve, reject) => {
			socket.emit(
				'InsertOrUpdateStickerContractorJobs',
				job_id,
				user,
				completed,
				components,
				sticker_year,
				contractor,
				status,
				(result) => {
					try {
						resolve(result);
					} catch (error) {
						console.error(`ERROR: inventoryBin.InsertOrUpdateStickerContractorJobs: ${error} on ${new Date()}`);
						reject(error);
					}
				}
			);
		});
	};

	const getItemByModel = (model) => {
		return new Promise((resolve, reject) => {
			socket.emit('getItemByModel', model, (res) => {
				if (!res) {
					console.error(`InventoryBin.getItemByModel: There was an issue calling this method`);
					reject(false);
				} else {
					if (!res.length) {
						setErrors({
							model: true,
						});
						resolve({ found: false });
					} else {
						setLoading(true);
						resolve({ found: true, item_id: res[0].id, item_name: res[0].item_name });
					}
				}
			});
		});
	};
	const getItemByItemID = (item_id) => {
		return new Promise((resolve, reject) => {
			socket.emit('getItemById', item_id, (res) => {
				if (!res) {
					console.error(`InventoryBin.getItemByItemID: There was an issue calling this method`);
					reject(false);
				} else {
					if (!res.length) {
						setErrors({
							item_id: true,
						});
						resolve({ found: false });
					} else {
						resolve(res[0].model);
					}
				}
			});
		});
	};

	const findNextRID = () => {
		socket.emit('FindNextInvBarcode', (res) => {
			if (!res) {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: 'SERVER ERROR: There was an issue calling this method. Please contact the developer for this issue.',
					severity: 'error',
				}));
				console.error(`InventoryBin.findNextRID: There was an issue calling this method`);
			} else {
				const foundRID = res[0].find((el) => el.item_type.includes(name));
				setNextBarcodeItem(foundRID);
			}
		});
	};

	const validateForm = async (controllerData, type) => {
		const inputs = [];
		let binFound = false;
		let errMsg = '';
		let _bin;

		for (const data of [controllerData]) {
			const { barcode, running_total, pcid, item_id, created, true_qty, previous_remainder, location, transfer_qty } =
				data[0];
			
			const model = await getItemByItemID(item_id);
			const found = await getItemByModel(model);
			if (location) {
				_bin = bins.find(
					(bin) =>
						bin.name.toUpperCase() === location.toUpperCase() || bin.code.toUpperCase() === location.toUpperCase()
				);
				binFound = await validateBinLocation(location);

				if (!_bin) {
					binFound = false;
					errMsg = `It seems you're trying to transfer items to a bin that doesn't exist or accept these items. Please double check that the destination you have entered is correct.`;
				}

				inputs.push({
					qty: parseInt(transfer_qty),
					location: _bin && _bin.code ? _bin.code : location,
					barcode: type === 'submit' ? nextBarcodeItem.generated_barcode : barcode,
					true_qty,
					item_name: found.item_name,
					running_total,
					previous_remainder,
					pcid,
					contractor: _bin.name.includes('Contractor') ? _bin.name.substring(12) : '',
					created,
					info: {
						sim: '',
						imei: '',
						phone: '',
						serial: '',
						carrier: '',
						firmware: '',
						part_num: '',
						password: '',
						attached_items: { id: '' },
					},
					action: binFound.offsite || location.toUpperCase().startsWith('WI') ? 'OUT' : 'UPDATED',
					user: localStorage.getItem('FullName'),
					refurb: binFound.offsite || location.toUpperCase().startsWith('WI') ? 1 : 0,
					item_id,
				});

				setRIDTrigger(true);
			} else {
				errMsg = `Destination cannot be blank`;
			}

			if (!binFound && !_bin) {
				errMsg = `It seems you're trying to transfer items to a bin that doesn't exist or accept these items. Please double check that the destination you have entered is correct.`;
			}

			if (errMsg) {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: `WARNING: ${errMsg}`,
					severity: 'warning',
				}));
			}
		}

		return { valid: !errMsg, msg: errMsg ? errMsg : '', vals: inputs };
	};

	const getStickerCount = () => {
		socket.emit('getRepurposeCount', (result) => {
			const yearKeys = [currentYear, nextYear, futureYear];
			const repurposedObj = {};

			yearKeys.forEach((year) => {
				const yearObj = result[0];
				const count = yearObj[`${year} Repurposed Stickers`];
				const ID = yearObj[`${year} ID`];
				repurposedObj[year] = { count, ID };
			});

			if (name === currentYear || name === nextYear || name === futureYear) {
				setStickerCount(repurposedObj[name].count >= 0 ? repurposedObj[name].count : (repurposedObj[name].count = 0));
				setbinID(repurposedObj[name].ID);
				setBinQty(repurposedObj[name].count);
			}
			if (stickerCount === null || stickerCount === undefined) {
				setStickerCount(0);
			}
			return repurposedObj;
		});
	};

	const readCounterInputBar = (e) => {
		const searchValue = e.target.value;
		setinputField(searchValue);
		return searchValue;
	};

	const toggleModal = () => {
		setModalOpen((prevState) => !prevState);
		setTransferring((prevState) => !prevState);
	};

	const handleCounts = (e) => {
		const buttonText = e.target.innerText;
		const addOne = 1;
		const addTen = 10;
		const addHundred = 100;
		let newSubmitQty = submitQty;
		switch (buttonText) {
			case '+1':
				setStickerCount(stickerCount + addOne);
				setSubmitQty((newSubmitQty += addOne));
				break;
			case '+10':
				setStickerCount(stickerCount + addTen);
				setSubmitQty((newSubmitQty += addTen));
				break;
			case '+100':
				setStickerCount(stickerCount + addHundred);
				setSubmitQty((newSubmitQty += addHundred));
				break;
			case '-1':
				setStickerCount(Math.max(stickerCount - addOne, 0));
				setSubmitQty((newSubmitQty -= addOne));
				break;
			case '-10':
				setStickerCount(Math.max(stickerCount - addTen, 0));
				setSubmitQty((newSubmitQty -= addTen));
				break;
			case '-100':
				setStickerCount(Math.max(stickerCount - addHundred, 0));
				setSubmitQty((newSubmitQty -= addHundred));
				break;
			default:
				break;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (inputField === '') {
			await findRepurposedItemsForTransfer(bin, submitQty);
			const valid = await validateForm(
				[
					{
						barcode: nextBarcodeItem.generated_barcode,
						transfer_qty: submitQty,
						binID: binID,
						location: bin,
						action: 'UPDATED',
						item_id: nextBarcodeItem.item_id,
					},
				],
				'submit'
			);
			if (valid.valid) {
				await updateIntoInventory(valid.vals, 'update');
			} else {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: `WARNING: ${valid.msg}`,
					severity: 'warning',
				}));
			}
			return;
		} else if (inputField.includes(name)) {
			const splitInputField = inputField.split(' ');
			
			const valid = await validateForm(
				{
					transfer_qty: splitInputField[1],
					binID: binID,
					barcode: nextBarcodeItem.generated_barcode,
					action: 'IN',
					item_id: nextBarcodeItem.item_id,
				},
				'submit'
			);
			if (valid.valid) {
				await updateIntoInventory(valid.vals, 'update');
			} else {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: `WARNING: ${valid.msg}`,
					severity: 'warning',
				}));
			}

			setinputField('');
		} else {
			setSnackbar((prevState) => ({
				...prevState,
				visible: true,
				message: `WARNING: Wrong year number entered, please ensure that you are entering the right barcode`,
				severity: 'warning',
			}));
			setinputField('');
		}
	};

	return (
		<div>
			{modalOpen ? TransferItemModal(transferItemModalProps) : null}
			<AlertBar
				visible={snackbar.visible}
				onClose={handleCloseSnack}
				message={snackbar.message}
				severity={snackbar.severity}
			/>
			<div className="main-counter-container">
				<div className="count-title-container">
					<h1 className="counter-title">{name}</h1>
					<h1 className="counter-title">Count: {stickerCount}</h1>
				</div>

				<div className="button-group">
					<div className="addition">
						<button className="rti-white ButtonUI counter-button" onClick={handleCounts}>
							+1
						</button>
						<button className="rti-white ButtonUI counter-button" onClick={handleCounts}>
							+10
						</button>
						<button className="rti-white ButtonUI counter-button" onClick={handleCounts}>
							+100
						</button>
					</div>

					<div className="subtraction">
						<button className="rti-white ButtonUI counter-button" disabled={stickerCount <= 0} onClick={handleCounts}>
							-1
						</button>
						<button className="rti-white ButtonUI counter-button" disabled={stickerCount <= 0} onClick={handleCounts}>
							-10
						</button>
						<button className="rti-white ButtonUI counter-button" disabled={stickerCount <= 0} onClick={handleCounts}>
							-100
						</button>
					</div>
				</div>

				<div className="submit-counter-button-container">
					<form onSubmit={handleSubmit}>
						<Button type="submit" value="SUBMIT" className="rti-blue-round" variant="contained" startIcon={<PublishIcon />}>
							Submit
						</Button>
					</form>
					<Button className="rti-blue-round" variant="contained" startIcon={<TransferIcon />} onClick={toggleModal}>
						Transfer Items
					</Button>
				</div>
			</div>

			<div className="input-container">
				<form>
					<TextField
						id="sticker-entry"
						placeholder="Scan barcode..."
						value={inputField}
						onChange={readCounterInputBar}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<ScanIcon />
								</InputAdornment>
							),
						}}
					/>
				</form>
			</div>
		</div>
	);
};
Counter.propTypes = {
	name: PropTypes.string.isRequired,
};
export default Counter;
