import React, { useState, useEffect } from 'react';
import '../StyleSheets/TechPortal.css';
import Logo from '../../public/Images/regitechLogo.png';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import Modal from '../components/Modal';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import { version } from '../../package.json';
import '../utilities/util';
import { getEmailSignature, _hashCode } from '../utilities/util';
import PropTypes from 'prop-types';
import moment from 'moment';
import buffer from 'buffer';

const LoginModal = ({ location }) => {
	const [Verified, setVerified] = useState(false);
	const [pathname, setPathname] = useState('/');
	const [Users, setUsers] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [sending, setSending] = useState(false);
	const [Form, setForm] = useState({ Username: '', Password: true });
	const [error, setError] = useState('');

	useEffect(() => {
		socket.emit('selectAllUsers', (result) => {
			setUsers(result);
		});

		if (location && location.state && location.state.redirectFrom) {
			setPathname('/inventory/');
		}
	}, []);

	useEffect(() => {
		const passReset = document.getElementById('pass-reset');
		if (passReset) {
			passReset.classList.toggle('flex-box', modalOpen);
		}
	}, [modalOpen]);

	const VerifyCredentials = async (e) => {
		e.preventDefault();
		setError('');

		let found = false;
		const username = document.getElementById('tech-id').value;

		Users.forEach((user) => {
			if (username.trim() === user.Username) {
				found = true;
			}
		});

		if (!found) {
			setForm({ ...Form, Username: username });
		} else {
			try {
				await loginUser();
			} catch (e) {
				console.error(`ERROR: LoginModal.VerifyCredentials: ${e} - ${new Date()}`);
				setError("There was an issue verifying this user's credentials");
			}
		}
	};

	const loginUser = async () => {
		let body;
		const username = document.getElementById('tech-id').value;
		const password = document.getElementById('tech-password').value;

		try {
			body = await axios.post('/api/login', {
				username: username,
				password: password,
				inventory: pathname === '/inventory/' ? true : null,
			});

			if (body.status !== 200) {
				setForm({ ...Form, Password: false });
				setError('Incorrect username or password, please try again');
			} else {
				setUser(body.data, body.data.Token, pathname === '/inventory/' ? true : null);
				setVerified(true);
			}
		} catch (e) {
			console.error(`ERROR: There was an issue logging in: ${e} - ${new Date()}`);
			console.error(`Response: ${e.response ? JSON.stringify(e.response.data) : 'No response data'}`);
			setForm({ ...Form, Password: false });
			setError('There was an issue logging in, please try again later');
		}
	};

	const setUser = (user, token, inventory) => {
		if (inventory) {
			sessionStorage.setItem('invAcc', token);
			localStorage.setItem('user', user.Username);
			localStorage.setItem('FullName', user.FullName);
		} else {
			localStorage.setItem('user', user.Username);
			localStorage.setItem('FullName', user.FullName);
			localStorage.setItem('local-dispatch-console-user', token);
		}
	};

	const UsernameInputHandler = () => {
		setForm({ ...Form, Username: '' });
	};

	const PasswordInputHandler = () => {
		setForm({ ...Form, Password: true });
	};

	const RenderUserNameError = () => {
		let divToRender = null;

		if (Form.Username && !Users.some((user) => user.Username === Form.Username)) {
			socket.emit('logInvalidLoginAttempt', Form.Username, 'Invalid username', () => {});
			divToRender = (
				<div>
					<label className='user-error'>**Incorrect username or password, please try again</label>
					<br></br>
				</div>
			);
		}
		return divToRender;
	};

	const RenderPasswordError = () => {
		let divToRender = null;

		if (!Form.Password) {
			socket.emit('logInvalidLoginAttempt', Form.Username, 'Invalid password', () => {});
			divToRender = (
				<div>
					<label className='pass-error'>**Incorrect username or password, please try again</label>
					<br></br>
				</div>
			);
		}
		return divToRender;
	};

	const handleForgotPassword = () => {
		setModalOpen(true);
	};

	const onModalClose = () => {
		setModalOpen(false);
	};

	const handleConfirmReset = () => {
		const uid = document.getElementById('uid').value;
		const phone4 = document.getElementById('phone4').value;
		const delivery_method = document.getElementById('sms-reset').checked
			? 'sms'
			: document.getElementById('email-reset').checked
			? 'email'
			: null;

		if (delivery_method) {
			setSending(true);
			socket.emit('validateUserByPhone', uid, phone4, (res) => {
				if (!res) {
					console.error('There was an issue validating this user to reset their password.');
					setSending(false);
				} else {
					const expiration = new Date();
					expiration.setHours(expiration.getHours() + 1);
					const encoded_request = Buffer.from(expiration.getTime().toString()).toString('base64');
					const requestCode = _hashCode(encoded_request).toString().slice(-6).toUpperCase();
					const rid_link = `https://console.ez-tab.net/forgot-pass?rid=${requestCode}&uid=${
						res[0].uid
					}&exp=${expiration.getTime()}`;

					if (delivery_method === 'email') {
						const msg =
							`<span width="100%">` +
							`<h1 style="text-align:center;">Password Reset</h1>` +
							`<h2>THIS LINK EXPIRES IN 1 HOUR</h2>` +
							`<p>You have requested to reset your password.</p>` +
							`<p>If you <b>did not</b> request to reset your password, please contact the developer immediately.</p>` +
							`<p>If you <b>did</b> request to reset your password, then click the link below.</p>` +
							`<p>Verification #: <b>${requestCode}</b></p>` +
							`<span>
                            <a href=${rid_link} style="text-align: center;">Reset Password</a></span>
                        </span>
                        ` +
							`${getEmailSignature('Auto Message')}` +
							`</span>`;

						const mailOpts = {
							params: {
								to: res[0].email,
								subject: `Password Reset Request - RTI`,
								text: msg,
							},
						};

						fetch('/send', {
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify(mailOpts),
						})
							.then((res) => res.text())
							.then((res) => {
								if (res.includes('250') || res.includes('200')) {
									alert('A password reset email will be delivered to your email address shortly!');
									onModalClose();
									setSending(false);
								}
							});
					} else {
						const msgOpts = {
							to: res[0].phone,
							body: `Your verification # is ${requestCode}.\n\nA password reset has been requested for your RTI account.\nIf you did NOT request to reset your password, please contact the developer immediately.\nIf you did request to reset your password please click on the link below and follow the instructions on the following page.\n\n${rid_link}\n THIS LINK EXPIRES IN 1 HOUR.`,
							from: 'Auto Msg',
							type: 'standard',
							private: true,
							time: moment.format('YYYY-MM-DD HH:mm:ss'),
						};

						fetch('/api/send_message_async', {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({ messages: msgOpts }),
						})
							.then((res) => res.json())
							.then((res) => {
								if (res.status === 200) {
									alert('Your password reset verification # has been sent to your phone.');
									onModalClose();
									setSending(false);
								} else {
									alert('There was an issue sending your password reset verification # to this phone number');
									onModalClose();
									setSending(false);
								}
							});
					}
				}
			});
		} else {
			alert('Please select a delivery method.');
		}
	};

	return (
		<div className='main-login-container'>
			{modalOpen ? (
				<Modal modal_id='pass-reset' dimension={{ width: 400 }} onClose={onModalClose}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							height: 368,
						}}
					>
						<h2>Password Reset</h2>
						<p>
							Fill out the information below. After clicking <b>CONFIRM</b>, a verification code will be sent to your
							delivery method shortly.
						</p>
						<div>
							<TextField id='uid' type='text' variant='standard' label='Username' />
						</div>
						<div>
							<TextField id='phone4' type='text' variant='standard' label='Last 4 of Phone #' />
						</div>
						<p>How would you like to receive your verification code?</p>
						<div className='vert-margin flex-just-even'>
							<FormControlLabel control={<Checkbox id='sms-reset' color='primary' />} label='SMS' />
							<FormControlLabel control={<Checkbox id='email-reset' color='primary' />} label='Email' />
						</div>
						<div>
							{!sending ? (
								<Button className='rti-blue-round' variant='contained' onClick={handleConfirmReset}>
									Confirm
								</Button>
							) : (
								<div>
									<label>Sending...</label>
									<LinearProgress />
								</div>
							)}
						</div>
					</div>
				</Modal>
			) : null}
			<div className='tech-login-container'>
				<div className='inner-container-login'>
					<div className='inner-container-greeting'>
						<img style={{ boxShadow: 'none' }} src={`${Logo}`} width='68%'></img>
					</div>
					<div className='login-form-container'>
						<div className='login-form-label'>
							<h1>Login</h1>
						</div>
						<div className='form-divider'></div>
						<div className='login-form-main-body'>
							<div className='inner-form-body'>
								<form autoComplete='off'>
									<div className='tech-id'>
										<label htmlFor='username'>Username</label>
										<br></br>
										{RenderUserNameError()}
										<input type='text' id='tech-id' name='username' onChange={UsernameInputHandler}></input>
									</div>
									<div className='tech-pass'>
										<label htmlFor='password'>Password</label>
										<br></br>
										{RenderPasswordError()}
										<input type='password' id='tech-password' name='password' onChange={PasswordInputHandler}></input>
									</div>
									<div>
										<a style={{ color: 'blue' }} href='#' onClick={handleForgotPassword}>
											Forgot password?
										</a>
									</div>
									<div className='login-button'>
										<input
											type='submit'
											value='LOGIN'
											onSubmit={() => {
												return false;
											}}
											onClick={VerifyCredentials}
										></input>
										{Verified ? <Navigate to={pathname} /> : null}
									</div>
									{error && <div className='error-message'>{error}</div>}
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ textAlign: 'center', color: 'white' }} id='app-tag'>
				&copy; Registration Technology Inc. {`v${version}`}
			</div>
		</div>
	);
};

LoginModal.propTypes = {
	location: PropTypes.shape({
		state: PropTypes.shape({
			redirectFrom: PropTypes.string,
		}),
	}),
};

export default LoginModal;

const Buffer = buffer.Buffer;
