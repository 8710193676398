/**
 * TODO: Figure out how to render the movement of bulk items the same way that single items are treated. Successfully can transfer the qty but does not render removing them from the table
 * Might be better to limit what items can be moved into bins, this would prevent bulk items getting stuck in single item bins.
 * TODO: Update the way that the items render in contractor bin. Allow the user to select what type of item they want to move and how much they want to move.
 * 		Make sure that this is updated via the unit amount for the selected item, so that you cannot move less than what the item actually is.
 *
 * TODO: Get the transferred item from 2025 repurpose to show that it is a repurposedYear sticker
 * TODO: Change it so that the lookup of the items is by child_id and model instead of RID
 * TODO: If bin is not a sticker family bin, count the number of child_ids and not the count that is inaccurate. Currently shows the wrong count on most bins
 * TODO: Change the method for if more than one item is being built, have it scan through
 */

import React, { useState, useEffect } from 'react';
import CustomTable from '../../components/Table';
import Button from '@mui/material/Button';
import AlertBar from '../../components/AlertBar';
import { validateBinLocation } from '../../utilities/util';
import { DateToYMD } from '../API/TextFormatingFunctions';
import { CSVLink } from 'react-csv';
import { TransferItemModal } from './InventoryItemModals/TransferItemModal';
import { ItemLocationModal } from './InventoryItemModals/ItemLocationModal';
import { BuildItemModal } from './InventoryItemModals/BuildItemModal';
import { StickerContractorJobs } from './StickerContractorUI/StickerContractorJobs';
import { StickerContractorJobModal } from './InventoryItemModals/StickerContractorJobModal';
import '../../StyleSheets/InventorySystem.css';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	MdCompareArrows as TransferIcon,
	MdHistory as HistoryIcon,
	MdEdit as EditIcon,
	MdBuild as BuildIcon,
	MdGetApp as DownloadIcon,
} from 'react-icons/md';
import $ from 'jquery';

const TableHeaders = [
	{ id: 'select', name: '', size: 'xsmall-header', type: 'checkbox' },
	{ id: 'rid', name: 'RID #', sortable: true, numeric: false },
	{ id: 'item', name: 'item', sortable: true, numeric: false },
	{ id: 'model', name: 'model', sortable: true, numeric: false },
	{ id: 'qty', name: 'qty', sortable: true, numeric: true },
	{ id: 'refurbished', name: 'refurbished', sortable: true, numeric: false },
];

const SearchOptions = [
	{ id: 'rid', name: 'RID #', pidx: 0 },
	{ id: 'name', name: 'Item Name', pidx: 1 },
	{ id: 'model', name: 'Model', pidx: 2 },
];

const TableTabs = [
	{ id: 'Pending', name: 'Pending', filter: 'pending' },
	{ id: 'Completed', name: 'Completed', filter: 'complete' },
];
const InventoryBin = () => {
	const navigate = useNavigate();
	const path_location = useLocation();
	const subPathKeywordArr = path_location.pathname.split('/');
	const subPath = subPathKeywordArr.slice(-1).pop();
	const [item, setItem] = useState(
		path_location.pathname.split('/').at(1).toLowerCase === 'inventory'
			? path_location.state.inv_item
			: subPath === 'bulk-item'
			? { barcode: null, bulk: true }
			: { barcode: null }
	);
	const [loading, setLoading] = useState(true);
	const [searchVal, setSearchVal] = useState('');
	const [searchParam, setSearchParam] = useState('');
	const [items, setItems] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [buildOpen, setBuildOpen] = useState(false);
	const [bin, setBin] = useState(null);
	const [bins, setBins] = useState([]);
	const [, setErrors] = useState({});
	const [ctr, setCTR] = useState(null);
	const [contractorOpen, setContractorOpen] = useState(null);
	const [locationOpen, setLocationOpen] = useState(false);
	const [dataDownload, setDataDownload] = useState([]);
	const [builds, setBuilds] = useState([]);
	const [, setBuildOptions] = useState([]);
	const [building] = useState(false);
	const [buildingMessage, setBuildingMessage] = useState('');
	const [maxBuildCount, setMaxBuildCount] = useState(1);
	const [buildCount, setBuildCount] = useState(1);
	const [buildItems, setBuildItems] = useState([]);
	const [selectedBuild, setSelectedBuild] = useState('');
	const [filteredBuild, setFilteredBuild] = useState([]);
	const [itemTotals, setItemTotals] = useState([]);
	const [stickerContractorJobs, setStickerContractorJobs] = useState([]);
	const [contractorComponentScan, setContractorComponentScan] = useState([]);
	const [bulk, setBulk] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [stickerYear] = useState('');
	const [activeTab, setActiveTab] = useState(TableTabs[0].name);
	const [selectedContractorJob, setSelectedContractorJob] = useState('');
	const [newItemOptions, setNewItemOptions] = useState([]);
	const [customChecked, setCustomChecked] = useState(false);
	const [customBuildAmount, setCustomBuildAmount] = useState(1);
	const contractorOptionsOpen = Boolean(anchorEl);
	const [, setContractorComponents] = useState([]);
	const [repurposedYear, setRepurposedYear] = useState('');
	const [repurposedQty, setRepurposedQty] = useState(0);
	const [repurposedCheck, setRepurposedYearCheck] = useState(false);
	const [, setRepurposedToUpdate] = useState([]);

	const [snackbar, setSnackbar] = useState({
		visible: false,
		message: '',
		severity: 'success',
	});

	const transferItemModalProps = {
		items: items,
		bins: bins,
		toggleModal: toggleModal,
		handleTransferItems: handleTransferItems,
		renderTransferItems: renderTransferItems,
		parent: 'Inventory Bin',
		loading,
	};

	const buildItemProps = {
		itemTotals,
		builds,
		filteredBuild,
		building,
		selectedBuild,
		bin,
		bins,
		buildCount,
		toggleBuild,
		selectBuild,
		handleClearItems,
		handleBuildQty,
		buildingMessage,
		onAddItem,
		loading,
		handleCustomAmountCheck,
		customChecked,
		customBuildAmount,
	};

	const itemLocationProps = { toggleLocation, handleUpdateLocation };

	const StickerContractorProps = {
		ClearSearch,
		Search,
		loading,
		stickerContractorJobs,
		toggleContractor,
		handleContractorOptionSelect,
		handleOpenContractorOptions,
		handleContractorOptionClose,
		anchorEl,
		contractorOptionsOpen,
		toggleBuild,
		activeTab,
		TableTabs,
		filterTable,
		RefreshTableData,
	};

	const StickerContractorJobModalProps = {
		toggleContractor,
		handleTransferItems,
		buildItems,
		removeContractorComponent,
		onScanContractorComponents,
		contractorComponentScan,
		bins,
		bin,
		onAddItem,
		stickerYear,
		loading,
		handleRepurposedChange,
		repurposedYear,
		repurposedCheck,
		handleRepurposeCheck,
		handleRepurposeQty,
		repurposedQty,
	};

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			const param = subPath;
			retrieveData(true, param);
		}

		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		$('#transfer-items').toggleClass('flex-box');
	}, [modalOpen]);

	useEffect(() => {
		$('#build-items').toggleClass('flex-box');
	}, [buildOpen]);

	useEffect(() => {}, [customChecked]);

	useEffect(() => {
		$('#contractor').toggleClass('flex-box');
	}, [contractorOpen]);

	useEffect(() => {
		$('#location-modal').toggleClass('flex-box');
	}, [locationOpen]);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			setItemTotals(calculateItemTotals(items));
		}
		return () => {
			mounted = false;
		};
	}, [items]);

	useEffect(() => {
		let mounted = true;

		if (mounted && buildOpen && ctr[0].contractor) {
			let contractorComponents;
			setItemTotals(calculateItemTotals(items));
			const specificComponents = stickerContractorJobs.find((el) => el.job_id === selectedContractorJob);
			if (typeof specificComponents.remaining_components === 'string') {
				// Parse components only if they are stored as a string
				contractorComponents = JSON.parse(specificComponents.remaining_components);
			} else {
				contractorComponents = specificComponents.remaining_components;
			}
			const foundComponents = findComponentsInItems(contractorComponents);
			setContractorComponents(foundComponents);
			setItemTotals(calculateItemTotals(foundComponents));
			const filtered_builds = filterBuilds(builds, foundComponents);
			setFilteredBuild(filtered_builds.length ? filtered_builds : builds);
		}
		return () => {
			mounted = false;
		};
	}, [buildOpen, ctr]);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			setItemTotals(calculateItemTotals(buildItems));
		}

		return () => {
			mounted = false;
		};
	}, [buildItems]);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			const fetch_components = async () => {
				// Gives the calculation a baseline of 1 before it tries to calculate dynamically based on parts accepted.
				if (!bin.startsWith('BIN-KIOSK')) {
					const counts = await calculateComponentsRequired(1);
					setMaxBuildCount(counts.roundedResult);
				} else {
					setMaxBuildCount(1);
				}
			};

			if (selectedBuild) {
				try {
					fetch_components();
				} catch (error) {
					console.error(`ERROR: inventoryBin.calculateComponentsRequired: ${error} on ${new Date()}`);
				}
			}
		}

		return () => {
			mounted = false;
		};
	}, [selectedBuild]);

	useEffect(() => {
		// Check if contractor is not null or undefined and has at least one element
		let mounted = true;
		if (ctr && ctr.length > 0 && mounted) {
			getPendingStickerJobsByContractor(activeTab);
			findNextRID();
		}

		return () => {
			mounted = false;
		};
	}, [ctr, activeTab]);

	const retrieveData = (_load, subPath) => {
		if (_load) setLoading(true);
		socket.emit('getAllBinLocations', (res) => {
			if (!res) {
				console.error(`InventoryBin.getAllBinLocations: There was an issue calling this method - ${new Date()}`);
			} else {
				setBins(res);
			}
		});

		if (subPath === 'bulk-item') {
			socket.emit('getBulkItems', (res) => {
				if (!res) {
					console.error(`InventoryBin.getBulkItems: There was an issue calling this method`);
					setLoading(false);
				} else {
					setBulk(res);
					setLoading(false);
				}
			});
		}
		socket.emit('getInventoryBins', subPath, ([bin, contractor]) => {
			if (!bin) {
				console.error(`InventoryBin.getInventoryBins: There was an issue calling this method - ${new Date()}`);
				setLoading(false);
			} else {
				setCTR(null);
				socket.emit('getBuildItems', (res) => {
					if (!res) {
						console.error(`InventoryBin.getBuildItems: There was an issue calling this method - ${new Date()}`);
						setLoading(false);
					} else {
						const _contractor = contractor.length ? contractor : null;
						const filtered_builds = filterBuilds(res, bin);
						setBuilds(filtered_builds);
						setItems(bin);
						setBin(subPath);
						setCTR(_contractor);
						setLoading(false);
					}
				});
			}
		});
	};

	// BEGIN CONTRACTOR SECTION

	// Get all pending jobs for a specific contractor
	const getPendingStickerJobsByContractor = (filter_type) => {
		socket.emit('getStickerContractorJobs', ctr[0].contractor, filter_type, (res) => {
			if (!res) {
				console.error(`InventoryBin.getStickerContractorJobs: There was an issue calling this method - ${new Date()}`);
				setLoading(false);
			} else {
				setStickerContractorJobs(res);
			}
		});
	};

	function RefreshTableData() {
		const filter = activeTab === 'Pending' ? 'pending' : activeTab.toLowerCase();
		if (contractorOpen) {
			toggleContractor();
		}
		if (buildOpen) {
			toggleBuild();
		}

		filterTable(filter);
		setSelectedContractorJob('');
		setContractorComponentScan([]);
		setMaxBuildCount(1);
		setBuildingMessage('');
		setBuilds([]);
		setBuildOptions([]);
		setBuildCount(1);
		setBuildItems([]);
		setSelectedBuild('');
		setFilteredBuild([]);
		setItemTotals([]);
		setCustomBuildAmount(1);
		setCustomChecked(false);
		setAnchorEl(null);
	}

	function filterTable(filter) {
		setStickerContractorJobs([]);
		setLoading(true);

		switch (filter) {
			case 'pending':
				setActiveTab('Pending');
				getPendingStickerJobsByContractor('Pending');
				setLoading(false);
				break;
			case 'complete':
				setActiveTab('Completed');
				getPendingStickerJobsByContractor('Completed');
				setLoading(false);
				break;
		}
	}

	// Find the next available jobID for sticker contractors
	const getNextStickerJobID = () => {
		return new Promise((resolve, reject) => {
			socket.emit('GenerateStickerContractorJobID', (generatedJobID) => {
				try {
					resolve(generatedJobID[0][0].next_job_id);
				} catch (error) {
					console.error(`ERROR: inventoryBin.GenerateStickerContractorJobID: ${error} on ${new Date()}`);
					reject(error);
				}
			});
		});
	};

	// Find repurposed stickers that will allow a custom qty to be transferred.
	const findRepurposedItemsForTransfer = async (transfer_location, repurpose_transfer_qty) => {
		return new Promise((resolve, reject) => {
			socket.emit('findRepurposedItemsForTransfer', transfer_location, repurpose_transfer_qty, (res) => {
				if (!res) {
					console.error(
						`Counter.findRepurposedItemsForTransfer: There was an issue calling this method - ${new Date()}`
					);
					reject(new Error(`Counter.findRepurposedItemsForTransfer: There was an issue calling this method`));
				} else {
					setRepurposedToUpdate(...res[0]);
					resolve(res[0]);
				}
			});
		});
	};

	// Insert or update sticker contractor jobs
	const insertStickerJob = (
		job_id,
		user,
		completed,
		components,
		remaining_components,
		sticker_year,
		contractor,
		status
	) => {
		return new Promise((resolve, reject) => {
			socket.emit(
				'InsertOrUpdateStickerContractorJobs',
				job_id,
				user,
				completed,
				components,
				remaining_components,
				sticker_year,
				contractor,
				status,
				(result) => {
					try {
						resolve(result); // Resolve the Promise with the result
					} catch (error) {
						console.error(`ERROR: inventoryBin.InsertOrUpdateStickerContractorJobs: ${error} on ${new Date()}`);
						reject(error); // Reject the Promise with the error
					}
				}
			);
		});
	};

	const findNextRID = () => {
		socket.emit('FindNextInvBarcode', (res) => {
			if (!res) {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: 'SERVER ERROR: There was an issue calling this method. Please contact the developer for this issue.',
					severity: 'error',
				}));
				console.error(`InventoryBin.findNextRID: There was an issue calling this method`);
			} else {
				setNewItemOptions(res[0]);
			}
		});
	};

	const deleteStickerContractorJob = (job_id) => {
		socket.emit('deleteStickerContractorJob', job_id, (res) => {
			if (!res) {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: 'SERVER ERROR: There was an issue calling this method. Please contact the developer for this issue.',
					severity: 'error',
				}));
				console.error(`InventoryBin.deleteStickerContractorJob: There was an issue calling this method`);
			} else {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: 'Success! Job has been deleted',
					severity: 'success',
				}));
				RefreshTableData();
				// setNewItemOptions(res[0]);
			}
		});
	};

	function handleOpenContractorOptions(event, jobID) {
		setSelectedContractorJob(jobID);
		setAnchorEl(event.currentTarget);
	}

	function handleContractorOptionClose() {
		setAnchorEl(null);
	}

	// Function to find components in the items state that match the components in a contractor job
	const findComponentsInItems = (components) => {
		const componentsByJobID = components.map((componentId) => componentId.barcode);
		return componentsByJobID.map((componentId) => items.find((item) => item.barcode == componentId));
	};

	function handleContractorOptionSelect(jobId, option) {
		switch (option) {
			case 'Mark as Completed':
				toggleBuild();
				setAnchorEl(null);
				break;
			case 'Transfer Items':
				toggleModal();
				setAnchorEl(null);
				break;
			case 'Delete Job':
				deleteStickerContractorJob(jobId);
				setAnchorEl(null);
				break;
			default:
				break;
		}
	}

	// Finds the available builds for the selected bin.
	const filterBuilds = (_builds, _items) => {
		return _builds.reduce((avail, build) => {
			const requirements = JSON.parse(build.requirements);
			for (let i = 0; i < requirements.length; i++) {
				const item = _items.find((el) => el.item_id == requirements[i].item);
				if (!item) {
					// if build item is not found, break loop
					break;
				} else if (item && i == requirements.length - 1) {
					// if item is in build and is the last item to check, push build item
					avail.push(build);
				}
			}
			return avail;
		}, []);
	};

	function removeContractorComponent(event) {
		// Remove item from the dispatch modal table.
		const id = event.target.id;
		const _copy = [...contractorComponentScan];
		_copy.splice(id, 1);
		const _final_copy = _copy.map((el, idx) => ({
			id: idx,
			barcode: el.barcode,
		}));
		setContractorComponentScan(_final_copy);
	}
	/**
	 * The function `onScanContractorComponents` scans component items into inventory and assigns them to a
	 * selected contractor, handling validation and displaying a warning message if needed.
	 * @param event - The `event` parameter in the `onScanContractorComponents` function represents the
	 * event object that is generated when a specific event occurs, such as a key press. In this case, the
	 * function is checking if the key that triggered the event has a key code of 13, which corresponds to
	 */
	async function onScanContractorComponents(event) {
		// Scan Component Items into inventory, assigning them to the selected contractor - e.g. sticker bundles
		if (event.keyCode === 13) {
			const barcode = document.getElementById('barcode').value;
			const valid = validateContractorComponents(barcode);

			onNavigateToItem(event, barcode);
			if (valid.valid) {
				document.getElementById('barcode').value = '';
				if (contractorComponentScan.length) {
					const _copy = [...contractorComponentScan];
					_copy.unshift({ id: 0, barcode });
					const _final_copy = _copy.map((el, idx) => ({
						id: idx,
						barcode: el.barcode,
					}));
					setContractorComponentScan(_final_copy);
				} else {
					setContractorComponentScan([{ id: 0, barcode }]);
				}

				document.getElementById('barcode').focus();
			} else {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: `WARNING: ${valid.msg}`,
					severity: 'warning',
				}));
			}
		}
	}

	function validateContractorComponents(_barcode) {
		// Make sure that the item barcode was not already scanned or empty
		document.getElementById('barcode').value = '';
		return contractorComponentScan.find((el) => el.barcode === _barcode)
			? {
					valid: false,
					msg: `Oops! Looks like you have already scanned this item in. Please double check that you are scanning the correct barcode.`,
			  }
			: contractorComponentScan.find((el) => !el.barcode)
			? {
					valid: false,
					msg: `Oops! Looks like you are missing a barcode. Please double check that you are scanning the correct barcode.`,
			  }
			: { valid: true };
	}

	/**
	 * @description Takes an array of objects, calculates the totals of these items
	 * @date 01/22/2024
	 * @param {*} item_arr array of objects
	 * @return {*}  Returns the finished product with all total's combined, and the number of items that create that total
	 * @author Jackson Kupris
	 */
	function calculateItemTotals(item_arr) {
		const item_counts = [];
		item_arr.forEach((comp) => {
			if (comp) {
				const { item_name, item_qty, item_id, model, units } = comp;
				const existingItem = item_counts.find((item) => item.item_name === item_name);
				const sticker_year = item_name && item_name.length ? (item_name.match(/\d+/) || [''])[0] : '';
				if (existingItem) {
					existingItem.count += 1;
					existingItem.item_id = item_id;
					existingItem.items.push(comp);
					existingItem.units = units ? units : item_qty;
					existingItem.total += item_qty;
					existingItem.year = sticker_year[1];
				} else {
					item_counts.push({
						item_name,
						count: 1,
						items: [comp],
						units: units ? units : item_qty,
						total: item_qty ? item_qty : 0,
						item_id: item_id,
						year: sticker_year.length ? sticker_year[1] : '',
						model: model,
					});
				}
			}
		});
		return item_counts;
	}

	/**
	 * @description Calculates the total amount of components required to create a build
	 * Will recognize the bottleneck component and limit the build to that bottleneck to ensure all components are available
	 * @date 01/22/2024
	 * @param {*} user_input - The number of units the user wants to build
	 * @return {*} An object containing calculated values
	 * @author Jackson Kupris
	 */

	async function calculateComponentsRequired(user_input) {
		// Convert user input to integer
		user_input = parseInt(user_input);

		// Variables for primary and secondary components
		let primaryComponent;
		let secondaryComponent;

		// Variables for remaining components after building
		let remainder;
		let remainderItem;

		// Variables for counts
		let rawBundleCount, rawSiliconeCount, totalForBuildCount;

		// Retrieve build requirements based on user input
		const buildsForSelect = !filteredBuild.length ? builds : filteredBuild;
		const selectedBuildObject = buildsForSelect.find((element) => element.id === selectedBuild);
		const selectedBuildRequirements =
			selectedBuildObject && selectedBuildObject.requirements ? JSON.parse(selectedBuildObject.requirements) : '';
		const requiredItems =
			selectedBuildRequirements && selectedBuildRequirements.length && !bin.startsWith('BIN-KIOSK')
				? selectedBuildRequirements.map((idObj) => {
						const matchingItem = itemTotals.find((item) => item.item_id === idObj.item);
						return matchingItem;
				  })
				: null;

		// Sort required items by units
		requiredItems.sort((a, b) => a.units - b.units);

		// Determine primary and secondary components based on build type
		if (
			(selectedBuildObject.item_name.includes('Stickers on Silicone') ||
				selectedBuildObject.item_name.includes('Large Sticker Paper Roll')) &&
			!bin.startsWith('BIN-KIOSK')
		) {
			primaryComponent = requiredItems.find(
				(item) => item.item_name.includes('Sticker WI') || item.item_name.includes('Large Blank Sticker Paper Roll')
			);
			secondaryComponent = requiredItems.find((item) => item.item_name.includes('Silicone'));
		} else {
			primaryComponent = requiredItems[0];
			secondaryComponent = requiredItems[1];
		}

		// Sort items within primary and secondary components
		primaryComponent.items.sort((a, b) => b.item_qty - a.item_qty);
		secondaryComponent.items.sort((a, b) => b.item_qty - a.item_qty);

		// Calculate bottleneck component and min/max unit quantity
		const buildComponentBottleneck = Math.min(primaryComponent.total, secondaryComponent.total);
		const min_unit_qty = Math.min(primaryComponent.units, secondaryComponent.units);
		const max_unit_qty = Math.max(primaryComponent.units, secondaryComponent.units);

		// Determine remainder component and count
		if (primaryComponent.total > secondaryComponent.total) {
			remainder = primaryComponent.total - secondaryComponent.total;
			remainderItem = primaryComponent.item_name;
		} else if (secondaryComponent.total > primaryComponent.total) {
			remainder = secondaryComponent.total - primaryComponent.total;
			remainderItem = secondaryComponent.item_name;
		} else {
			remainder = 0;
			remainderItem = '';
		}

		// Calculate adjusted build factors
		const roundedResult = Math.ceil(
			selectedBuildObject.item_name.includes('Stickers on Silicone')
				? Math.min(buildComponentBottleneck / min_unit_qty, buildComponentBottleneck / max_unit_qty)
				: Math.max(buildComponentBottleneck / min_unit_qty, buildComponentBottleneck / max_unit_qty)
		);
		const buildAdjustmentFactor = user_input === 1 ? roundedResult : user_input / roundedResult;

		// Calculate counts based on build adjustment factor
		if (buildAdjustmentFactor === roundedResult && Number.isInteger(buildAdjustmentFactor) && !customChecked) {
			rawBundleCount = buildComponentBottleneck / min_unit_qty / buildAdjustmentFactor;
			rawSiliconeCount = Math.ceil(buildComponentBottleneck / max_unit_qty) / buildAdjustmentFactor;
			totalForBuildCount = Math.round(buildComponentBottleneck / buildAdjustmentFactor);
		} else if (customChecked && customBuildAmount >= 1) {
			const customMinComponents = user_input ? user_input / min_unit_qty : null;
			const customMaxComponents = user_input ? Math.ceil(user_input / max_unit_qty) : null;
			rawBundleCount = customMinComponents;
			rawSiliconeCount = customMaxComponents;
			totalForBuildCount = user_input;
		} else {
			rawBundleCount = (buildComponentBottleneck / min_unit_qty) * buildAdjustmentFactor;
			rawSiliconeCount = Math.ceil(buildComponentBottleneck / max_unit_qty) * buildAdjustmentFactor;
			totalForBuildCount = Math.round(buildComponentBottleneck * buildAdjustmentFactor);
		}

		// Round counts for display
		const roundedSiliconeCount = Math.round(Math.ceil(rawSiliconeCount * 10) / 10);
		const roundedBundleCount = !customChecked
			? Math.round(Math.ceil(rawBundleCount))
			: Math.ceil(rawBundleCount * 10) / 10;

		// Update maximum build count if necessary
		if (roundedResult >= maxBuildCount && roundedResult !== Infinity) {
			setMaxBuildCount(roundedResult);
		}

		// Retrieve build name and components for inventory update
		const build_name = buildsForSelect.find((element) => element.id === selectedBuild)
			? buildsForSelect.find((element) => element.id === selectedBuild).item_name
			: '';

		// const repurposedBundleCount = Math.ceil(roundedBundleCount);
		const bundlesForInvUpdate = primaryComponent.items.slice(0, Math.ceil(roundedBundleCount));
		// const siliconeForInvUpdate = secondaryComponent.items.slice(0, roundedSiliconeCount);

		let sum = 0;
		let index = 0;

		for (let i = 0; i < secondaryComponent.items.length; i++) {
			sum += secondaryComponent.items[i].item_qty;
			if (sum >= totalForBuildCount) {
				index = i;
				break;
			}
		}

		const siliconeForInvUpdate = secondaryComponent.items.slice(0, index + 1);

		// Check if additional components are needed and issue warnings if necessary
		const total_silicone_check = secondaryComponent.total;
		const total_bundle_check = primaryComponent.total;

		if (total_silicone_check < total_bundle_check && user_input > roundedResult) {
			const additionalSecondaryNeeded = Math.ceil((total_bundle_check - total_silicone_check) / max_unit_qty);
			if (additionalSecondaryNeeded >= 1) {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: `WARNING: ${additionalSecondaryNeeded} more ${secondaryComponent.item_name} is needed to complete this build order`,
					severity: 'warning',
				}));
			}
		} else if (total_silicone_check > total_bundle_check && user_input > roundedResult) {
			const additionalPrimaryNeeded = Math.ceil((total_bundle_check - total_silicone_check) / max_unit_qty);
			if (additionalPrimaryNeeded >= 1) {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: `WARNING: ${additionalPrimaryNeeded} more ${primaryComponent.item_name} is needed to complete this build order`,
					severity: 'warning',
				}));
			}
		}

		// Set building message based on user input
		if ((user_input !== 0 && user_input <= roundedResult) || customChecked) {
			setBuildingMessage(
				`To make ${customChecked ? 1 : user_input} unit(s) of ${build_name}, you will need ${roundedBundleCount} ${
					primaryComponent.item_name
				}(s) and ${roundedSiliconeCount} ${
					secondaryComponent.item_name
				}(s) Totaling: ${totalForBuildCount}. The maximum number of units you can make with the available components is ${roundedResult}. If you use all available components, the remainder will be ${remainder} ${remainderItem}.`
			);
		}

		// Return calculated values
		return {
			roundedSiliconeCount: roundedSiliconeCount || 'N/A',
			totalForBuildCount: totalForBuildCount || 'N/A',
			roundedBundleCount: roundedBundleCount || 'N/A',
			roundedResult: roundedResult || 'N/A',
			bundlesForInvUpdate: bundlesForInvUpdate || 'N/A',
			siliconeForInvUpdate: siliconeForInvUpdate || 'N/A',
			build_name: build_name || 'N/A',
			max_unit_qty: max_unit_qty || 'N/A',
			min_unit_qty: min_unit_qty || 'N/A',
			buildsForSelectId: selectedBuildObject.id || 'N/A',
		};
	}
	/**
	 * The function `processBuildItems` processes build items by adjusting quantities, generating barcodes,
	 * and updating item properties based on the input parameters.
	 * @param passedArray - The `passedArray` parameter in the `processBuildItems` function is an array
	 * containing items to be processed. Each element in the array represents an item with properties like
	 * `item_id`, `item_qty`, `barcode`, `qty`, `item_name`, `id`, `location`, `info
	 * @param builtItemQty - The `builtItemQty` parameter represents the quantity of the item being built.
	 * It is used to calculate the total quantity to deduct based on this value during the build process.
	 * @param builtItemRID - The `builtItemRID` parameter seems to represent an object containing
	 * information related to the built item, including a generated barcode. In the function
	 * `processBuildItems`, the `builtItemRID.generated_barcode` is used to initialize the `newBarcode`
	 * variable, which is then used to assign bar
	 * @param buildCount - The `buildCount` parameter in the `processBuildItems` function represents the
	 * number of times a particular build item should be processed. It is used to determine how many times
	 * the components for the selected build should be processed in the loop.
	 * @param buildsForSelectId - `buildsForSelectId` is the ID of the build that is currently selected for
	 * processing in the `processBuildItems` function. This ID is used to identify the components/items
	 * that belong to the selected build for further processing within the function.
	 * @param build_name - The `build_name` parameter in the `processBuildItems` function represents the
	 * name of the build item being processed. It is used to update the `item_name` property of the build
	 * items in the inventory.
	 * @returns - The function `processBuildItems` returns an array `updatedValsForInv` containing updated
	 * item objects after processing the passed array based on the provided parameters and conditions.
	 */
	async function processBuildItems(passedArray, builtItemQty, builtItemRID, buildCount, buildsForSelectId, build_name) {
		const updatedValsForInv = [];

		if (!bin.startsWith('BIN-KIOSK')) {
			const usedBarcodes = new Set(); // Store used barcodes
			let newBarcode = builtItemRID.generated_barcode;
			let remainingToDeduct = builtItemQty; // Calculate total quantity to deduct based on builtItemQty

			for (const el of passedArray) {
				if (el.item_id === buildsForSelectId) {
					// Process components for the selected build
					for (let j = 0; j < buildCount; j++) {
						const buildItem = { ...el }; // Clone the current build item
						// Check if the barcode is already used
						while (usedBarcodes.has(newBarcode)) {
							const lastFive = newBarcode.substr(newBarcode.length - 5);
							const incrementedNumber = parseInt(lastFive) + 1;
							const paddedIncrementedNumber = String(incrementedNumber).padStart(lastFive.length, '0');
							newBarcode = newBarcode.slice(0, -5) + paddedIncrementedNumber;
						}
						usedBarcodes.add(newBarcode); // Add the new barcode to the set of used barcodes
						buildItem.barcode = newBarcode;
						buildItem.qty = Math.round(builtItemQty / buildCount); // Adjusted quantity for the dynamic totalToDeduct
						buildItem.item_name = build_name;
						buildItem.id = buildsForSelectId;
						updatedValsForInv.push(buildItem);
						buildItem.action = 'IN';
					}
				} else {
					// Update properties for items not matching the buildsForSelectId
					const quantityToDeduct = Math.round(Math.min(remainingToDeduct, el.item_qty)); // Adjust quantity to deduct
					remainingToDeduct -= Math.round(quantityToDeduct); // Update remaining to deduct
					let qty_copy = el.item_qty;
					el.qty = Math.max(qty_copy - quantityToDeduct, 0); // Ensure quantity doesn't go negative
					el.location = Math.max(qty_copy - quantityToDeduct, 0) > 0 ? el.code : 'BIN-SPENTSTC'; // Calculate new location
					el.info = el.info ? JSON.parse(el.info) : el.info;
					el.item_info = el.item_info ? JSON.parse(el.item_info) : el.item_info;
					el.action = 'UPDATED';
					updatedValsForInv.push(el); // Add the updated item to the array
					if (remainingToDeduct <= 0) break; // Exit loop if all deducted
				}
			}
		} else {
			for (const el of passedArray) {
				el.action = 'OUT';
				el.refurb = 1;
				el.qty = el.item_qty;
				el.info = JSON.parse(el.item_info);
				el.location = document.getElementById('location').value;
				el.user = localStorage.getItem('FullName');
				updatedValsForInv.push(el); // Add the updated item to the array
			}
		}

		return updatedValsForInv;
	}

	async function onAddItem(controllerData) {
		// Handles adding singular and bulk items into inventory
		const combinedArray = contractorComponentScan.map(async (el) => {
			// Fetch information for each scanned barcode
			const getInfoByBarcode = await onSerialLookUp(el.barcode);
			const scannedComponentItemID = getInfoByBarcode[0].item_id;
			const scannedComponentUnits = getInfoByBarcode[0].units;
			const scannedComponentItemName = getInfoByBarcode[0].item;
			const scannedComponentModel = await getItemByItemID(scannedComponentItemID);

			// Extract year from model if present
			const year =
				scannedComponentModel && scannedComponentModel.length && scannedComponentModel.includes('2')
					? scannedComponentModel.split('-')
					: '';

			// Return formatted object for each scanned component
			return {
				...el,
				location: controllerData.location,
				barcode: el.barcode,
				item_name: scannedComponentItemName,
				model: scannedComponentModel,
				qty: scannedComponentUnits,
				year: year && year.length ? year[1] : year,
				item_id: scannedComponentItemID,
			};
		});
		const inputs = await Promise.all(combinedArray);
		bin && bin.startsWith('BIN-KIOSK') ? inputs.push(...items) : null;
		// Validate scanned items
		const valid =
			contractorOpen || bin.startsWith('BIN-KIOSK') ? await validate(inputs) : await validate([controllerData]);

		if (valid.valid && !valid.confirm) {
			// Item is ok to enter into inventory
			const vals = [...valid.values];
			// Calculate components required for build if job is completed
			const stickerContractorJobStatus = controllerData.action;
			const buildItemNumbers =
				stickerContractorJobStatus === 'Completed' && !bin.startsWith('BIN-KIOSK')
					? await calculateComponentsRequired(customBuildAmount > 1 ? customBuildAmount : buildCount)
					: '';
			const bundlesForInvUpdate = buildItemNumbers.bundlesForInvUpdate;
			const siliconeForInvUpdate = buildItemNumbers.siliconeForInvUpdate;
			const buildsForSelectId = buildItemNumbers.buildsForSelectId;
			const build_name = buildItemNumbers.build_name;
			const valsForInv = (vals || []).concat(bundlesForInvUpdate || [], siliconeForInvUpdate || []);
			const builtItemQty = buildItemNumbers.totalForBuildCount;
			const builtItemRID = newItemOptions.find((el) => parseInt(el.item_id_number) === controllerData.code_id);
			const contractorForJob = ctr[0].contractor;
			const contractorJobID =
				stickerContractorJobStatus === 'Completed' ? selectedContractorJob : await getNextStickerJobID();
			const userForJobDispatch = localStorage.getItem('FullName');
			const currentDateTime = new Date().toISOString().slice(0, 19).replace('T', ' ');
			const completedDate = stickerContractorJobStatus === 'Completed' ? currentDateTime : '';
			let updatedValsForInv = [];

			if (stickerContractorJobStatus === 'Completed' && !bin.startsWith('BIN-KIOSK')) {
				const valsForInsert = await processBuildItems(
					vals,
					builtItemQty,
					builtItemRID,
					buildCount,
					buildsForSelectId,
					build_name
				);
				const siliconeForInsert = await processBuildItems(
					siliconeForInvUpdate,
					builtItemQty,
					builtItemRID,
					buildCount,
					buildsForSelectId,
					build_name
				);
				const bundlesForInsert = await processBuildItems(
					bundlesForInvUpdate,
					builtItemQty,
					builtItemRID,
					buildCount,
					buildsForSelectId,
					build_name
				);
				updatedValsForInv = (valsForInsert || []).concat(bundlesForInsert || [], siliconeForInsert || []);
			} else if (bin.startsWith('BIN-KIOSK')) {
				const kioskItems = await processBuildItems(inputs);
				updatedValsForInv = kioskItems;
			}

			const repurposed_stickers = repurposedCheck
				? await findRepurposedItemsForTransfer(`BIN-RPRPOS${repurposedYear - 2022}`, repurposedQty)
				: [];
			let finalValsForInv = updatedValsForInv.length ? updatedValsForInv : valsForInv;

			if (repurposedCheck && repurposedYear) {
				repurposed_stickers.forEach((el) => {
					el.item_name = `${repurposedYear} Repurposed Sticker WI`;
					el.qty = el.transfer_qty;
					const contractor_location = finalValsForInv.find((el) => el.location);
					el.location = contractor_location.location;
					finalValsForInv.push(el);
					updatedValsForInv.push(el);
				});
			}

			const combinedItems = [...contractorComponentScan, ...repurposed_stickers];

			let filteredItems = combinedItems.filter(
				(item, index, self) => index === self.findIndex((el) => el.barcode === item.barcode)
			);
			// Filter out items that match any item in finalValsForInv

			filteredItems = filteredItems.map((item) => {
				if (typeof item.item_info === 'string') {
					try {
						item.item_info = JSON.parse(item.item_info);
					} catch (error) {
						console.error(`Error parsing JSON for item with barcode ${item.barcode}: ${error}`);
						// Handle the error appropriately here
					}
				}
				return item;
			});

			const findStickerYearObj = finalValsForInv.find((element) => element.item_name.includes('2'));
			const item_name =
				findStickerYearObj && !repurposedCheck
					? findStickerYearObj.item_name
					: `${repurposedYear} Repurposed Sticker WI`;
			const year_number =
				item_name && item_name.length && !repurposedYear && !repurposedCheck
					? (item_name.match(/\d+/) || [''])[0]
					: repurposedYear;

			filteredItems = filteredItems.map((item) => {
				// Parse the item_info property if it's a string
				if (typeof item.item_info === 'string') {
					try {
						item.item_info = JSON.parse(item.item_info);
					} catch (error) {
						console.error(`Error parsing JSON for item with barcode ${item.barcode}: ${error}`);
					}
				}
				return item;
			});
			// Insert sticker job and update inventory
			if (ctr[0].contractor) {
				await insertStickerJob(
					contractorJobID,
					userForJobDispatch,
					completedDate,
					stickerContractorJobStatus === 'Completed' ? filteredItems : finalValsForInv,
					filteredItems.length ? filteredItems : finalValsForInv,
					year_number,
					contractorForJob,
					stickerContractorJobStatus
				);
			}
			await updateIntoInventory(finalValsForInv, 'add');
			RefreshTableData();
		} else {
			// Error has occurred
			setSnackbar((prevState) => ({
				...prevState,
				visible: true,
				message: `WARNING: ${valid.msg}`,
				severity: 'warning',
			}));
		}
	}

	function handleCustomAmountCheck() {
		setCustomChecked((prevState) => !prevState);
		setBuildCount(1);
	}

	function handleRepurposeQty() {
		const repurposedQtyCopy = document.getElementById('repurpose-qty').value;
		setRepurposedQty(repurposedQtyCopy);
	}

	function handleRepurposeCheck() {
		setRepurposedYearCheck((prevState) => !prevState);
	}

	function handleRepurposedChange() {
		const repurposedYearCopy = document.getElementById('repurposedYear').value;
		setRepurposedYear(repurposedYearCopy);
	}

	/**
	 * The function `handleBuildQty` takes an event input, calculates the maximum build quantity based on
	 * the input value, and updates the build count and custom build amount accordingly.
	 * @param event - The `event` parameter in the `handleBuildQty` function is typically an event object
	 * that represents an event being handled, such as a change event on an input field. It is used to
	 * extract the value of the input field that triggered the event (`event.target.value`) in this case.
	 */
	async function handleBuildQty(event) {
		const value = event.target.value;
		if (value !== '' || value >= 0) {
			const max_number_getter = await calculateComponentsRequired(value);
			const max_number = max_number_getter.roundedResult;
			const max_custom_allowed = max_number_getter.max_unit_qty;
			const setValue = value <= max_number ? value : buildCount; //if the input value is greater than max available build counts, then don't change the input value
			const customAmountValue = value <= max_custom_allowed ? value : customBuildAmount; //if the input value is greater than max available build counts, then don't change the input value
			if (value !== 0 && value <= max_number && !customChecked) {
				setBuildCount(setValue);
				setCustomBuildAmount(1);
			} else if (value !== 0 && value <= max_custom_allowed && customChecked) {
				setCustomBuildAmount(customAmountValue);
				setBuildCount(1);
			}
		} else {
			customChecked ? setCustomBuildAmount(0) : setBuildCount(0);
		}
	}

	function selectBuild(event) {
		const value = event.target.value;
		setSelectedBuild(value);
		setBuildCount(1);
	}

	function handleClearItems() {
		setBuildingMessage('');
		setSelectedBuild('');
		setBuildCount(1);
		setMaxBuildCount(1);
	}

	function toggleContractor() {
		setContractorOpen((prevState) => !prevState);
	}

	const onBuildItems = () => {
		if (items.length) {
			toggleBuild();
		} else {
			setSnackbar((prevState) => ({
				...prevState,
				visible: true,
				message: 'WARNING: There are no items in this bin to build.',
				severity: 'warning',
			}));
		}
	};

	function toggleBuild() {
		setBuildOpen((prevState) => !prevState);
		setBuildOptions([]);
	}

	// END CONTRACTOR SECTION

	function onNavigateToItem(_event, _barcode) {
		//navigate to singular item or bulk item if it has a barcode
		_event.preventDefault();
		$.post(`/api/scan-item`, { data: _barcode }, (path) => {
			if (!path.error) {
				navigate(path.data);
				setItem(path.data.inv_item);
			} else {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message:
						'SERVER ERROR: There was an issue navigating to this item. Please contact the developer for this issue.',
					severity: 'error',
				}));
			}
		});
	}

	/**
	 * Had to move a barcoded sticker into the repurposedYear sticker bin and then transfer that to bin and it shows that they are the correct model and name.
	 * If you move this 25WI000001 out of repurposedYear it causes an issue and defaults back to nothing. Once we have PC codes that go into these bins they will have the correct name every time.
	 */
	const renderTablebody = () => {
		return items.length && !items[0].bulk_bin && !items[0].contractor
			? items
					.map((item) => ({
						// barcode is 'null' as a string and not typeof null, have to check for the string version.
						key: item.barcode !== 'null' ? item.barcode : item.child_id,
						cells: [
							{ data: `${item.child_id}`, type: 'checkbox' },
							{
								data:
									item.barcode !== 'null' ? item.barcode : item.pcid ? `PC${item.pcid.toString().toUpperCase()}` : '-',
							},
							{ data: item.item_name !== null ? item.item_name : item.name },
							{ data: item.model !== null ? item.model : '-' },
							{ data: item.barcode !== 'null' ? item.item_qty : item.item_qty },
							{ data: item.refurbished ? 'YES' : '' },
						],
					}))
					.filter((row) =>
						searchVal && searchParam
							? row.cells[SearchOptions[searchParam - 1].pidx].data &&
							  row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchVal.toUpperCase())
							: row
					)
			: items.length && !items[0].bulk_bin && items[0].contractor
			? calculateItemTotals(items).map((item) => ({
					key: item.item_name,
					cells: [
						{ data: '-' },
						{ data: '-' },
						{ data: item.item_name !== null ? item.item_name : item.model },
						{ data: item.model !== null ? item.model : item.item_name },
						{ data: item.total ? item.total : item.units },
						{ data: '-' },
					],
			  }))
			: items.length && items[0].bulk_bin && !items[0].contractor
			? items
					.slice(0, 1)
					.map((item) => ({
						key: item.child_id,
						cells: [
							{ data: '-' },
							{ data: '-' },
							{ data: item.item_name !== null ? item.item_name : item.name },
							{ data: item.model !== null ? item.model : '-' },
							{ data: item.qty > item.item_qty ? item.qty : item.item_qty },
							{ data: item.refurbished ? 'YES' : '' },
						],
					}))
					.filter((row) =>
						searchVal && searchParam
							? row.cells[SearchOptions[searchParam - 1].pidx].data &&
							  row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchVal.toUpperCase())
							: row
					)
			: items;
	};

	const handleBinItemDownload = () => {
		const uniq = items.reduce((final, el) => {
			final[el.item_qty] = final[el.item_qty] ? final[el.item_qty] + 1 : 1;
			return final;
		}, {});

		const list = Object.entries(uniq)
			.map(([key, val]) => ({
				'Roll Size': key,
				'# of Rolls': val,
			}))
			.sort((a, b) => (a['Roll Size'] > b['Roll Size'] ? -1 : a['Roll Size'] < b['Roll Size'] ? 1 : 0));

		setDataDownload(list);

		setTimeout(() => {
			document.getElementById('bin-item-download').click();
		}, 500);
	};

	const renderTableButtons = () => {
		return (
			<div className='flex-align-center'>
				<Button className='rti-blue-round' variant='contained' startIcon={<TransferIcon />} onClick={toggleModal}>
					Transfer Items
				</Button>
				{items.length && items[0].buildable ? (
					<div>
						<Button className='rti-blue-round' variant='contained' startIcon={<BuildIcon />} onClick={onBuildItems}>
							Build Items
						</Button>
						<Button className='rti-blue-round' variant='contained' startIcon={<HistoryIcon />}>
							Retrieve Audit
						</Button>
					</div>
				) : null}
				<Button className='rti-blue-round' variant='contained' startIcon={<EditIcon />} onClick={toggleLocation}>
					Update Location
				</Button>
				{bin && bin.includes('ONROLL') ? (
					<Button
						className='rti-blue-round'
						variant='contained'
						startIcon={<DownloadIcon />}
						onClick={handleBinItemDownload}
					>
						bin items
					</Button>
				) : null}
				<CSVLink
					id='bin-item-download'
					className='hidden'
					target='_blank'
					data={dataDownload}
					filename={`bin-items-${DateToYMD(new Date())}.csv`}
				></CSVLink>
			</div>
		);
	};

	function handleUpdateLocation() {
		const _location = document.getElementById('location').value;

		if (_location) {
			socket.emit('updateBinLocation', bin, _location, (res) => {
				if (!res) {
					console.error(`InventoryBin.updateBinLocation: There was an issue calling this method - ${new Date()}`);
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message:
							'SERVER ERROR: There was an issue completing this action. Please notify the developer of this issue.',
						severity: 'error',
					}));
				} else {
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: 'Success! Bin location set successfully.',
						severity: 'success',
					}));
					toggleLocation();
				}
			});
		} else {
			setSnackbar((prevState) => ({
				...prevState,
				visible: true,
				message: 'WARNING: Location cannot be blank.',
				severity: 'warning',
			}));
		}
	}

	const tableRowClick = (event) => {
		const navigate = useNavigate();
		const id = event.target.id;
		socket.emit('getInventoryItems', ([items, models]) => {
			if (!items) {
				console.error(`InventoryBin.getInventoryItems: There was an issue calling this method - ${new Date()}`);
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message:
						'SERVER ERROR: There was an issue retrieving inventory items from the database. Please notify the developer of this issue.',
					severity: 'error',
				}));
			} else {
				// check that code exists in inventory
				const item = items.find((el) => id && id.substring(0, 4) == el.code);

				if (item) {
					if (item.units <= 1) {
						// item is not bulk
						const copy = { ...item, barcode: id };
						navigate(`/inventory/receive/${id}`, { state: { inv_item: copy, models: models } });
					} else {
						const copy = { ...item, barcode: id, bulk: true };
						navigate(`/inventory/bulk-item`, { state: { inv_item: copy, models: models } });
					}
				}
			}
		});
	};

	function Search(param, searchVal) {
		setSearchVal(searchVal);
		setSearchParam(param);
	}

	function ClearSearch() {
		setSearchVal('');
	}

	function toggleModal() {
		setModalOpen((prevState) => !prevState);
	}

	async function handleTransferItems(controllerData) {
		const x = document.getElementsByName('selected-table-checkbox');
		const selected = [];

		for (let i = 0; i < x.length; i++) {
			if (x[i].checked) {
				const item = items.find((el) => el.child_id == x[i].id);
				selected.push(item);
			}
		}

		selected.forEach((el) => {
			el.location = controllerData.location;
			el.qty = el.item_qty;
		});

		setLoading(true);
		const valid = await validate(selected);
		const vals = [...valid.values];
		if (valid.valid) {
			await updateIntoInventory(vals, 'update');
			setLoading(false);
		} else {
			setSnackbar((prevState) => ({
				...prevState,
				visible: true,
				message: `WARNING: ${valid.msg}`,
				severity: 'warning',
			}));
			setLoading(false);
		}
	}

	const updateIntoInventory = (vals, action, _multi, _msg) => {
		return new Promise((resolve, reject) => {
			try {
				socket.emit('updateInventory', vals, vals[0].info || vals[0].item_info, action, (res) => {
					if (!res) {
						console.error(`InventoryBin.updateIntoInventory: There was an issue calling this method - ${new Date()}`);

						reject(false);
					} else {
						if (!_multi) {
							setSnackbar((prevState) => ({
								...prevState,
								visible: true,
								message: `Success! ${_msg ? _msg : `Item(s) transferred successfully.`}`,
								severity: 'success',
							}));
							retrieveData(false, bin);
							setModalOpen(false);
							updateBinQty();
						}

						resolve(true);
					}
				});
			} catch (error) {
				console.error(`InventoryBin.updateIntoInventory: ${error} - ${new Date()}`);
				reject(error);
			}
		});
	};

	const onSerialLookUp = async (serial) => {
		return new Promise((resolve, reject) => {
			socket.emit('getItemBySerial', serial, (res) => {
				if (!res) {
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message:
							'SERVER ERROR: There was an issue calling this method. Please contact the developer for this issue.',
						severity: 'error',
					}));
					console.error(`ScanItem.getItemBySerial: There was an issue calling this method`);
					reject(new Error('Error calling getItemBySerial'));
				} else {
					if (res.length) {
						resolve(res);
					} else {
						resolve(null); // or resolve with another appropriate value if needed
					}
				}
			});
		});
	};

	const validate = async (controllerData) => {
		setErrors({});
		let inputs = [];
		let binFound;
		// let avail;
		let qty;

		// Utility function to get the first available option from controllerData
		const getFirstAvailableOption = (property, dataArray) => {
			return dataArray ? dataArray[property] : '';
		};

		const formatControllerData = (property, dataArray) => getFirstAvailableOption(property, dataArray) || '';

		for (const data of controllerData) {
			const qty = formatControllerData('qty', data);
			const barcode = formatControllerData('barcode', data);
			const serial = formatControllerData('serial', data);
			const imei = formatControllerData('imei', data);
			const sim = formatControllerData('sim', data);
			const phone = formatControllerData('phone', data);
			const location = formatControllerData('location', data);
			const pass = formatControllerData('pass', data);
			const firmware = formatControllerData('firmware', data);
			const year = formatControllerData('year', data);
			const carrier = formatControllerData('carrier', data);
			const part_num = formatControllerData('part_num', data);
			const item_name = formatControllerData('item_name', data);
			const item_info = formatControllerData('item_info', data);
			const model = formatControllerData('model', data) || (await getItemByItemID(item.item_id));
			const cur_attached_items = formatControllerData('cur_attached_items', data);
			const found = await getItemByModel(model);
			const bulk_item_found =
				item.bulk && !item.item_id
					? bulk.find((el) => el.item_name.toUpperCase() === data.selected_item.toUpperCase()).id
					: null;
			const item_id = found.found ? found.item_id : item.bulk && !item.item_id ? bulk_item_found : item.item_id;
			const _bin = bins.find(
				(bin) => bin.name.toUpperCase() == location.toUpperCase() || bin.code.toUpperCase() == location.toUpperCase()
			);
			let binFound = await validateBinLocation(location);

			if (binFound && _bin) {
				const _allowed = _bin.allowed ? JSON.parse(_bin.allowed) : null;
				if (_allowed && !_allowed.includes(`${item.id}`)) {
					binFound = false;
				}
			}

			inputs.push({
				serial: item_info.serial,
				imei,
				location: _bin ? _bin.code : '',
				item_info: JSON.parse(item_info),
				info: {
					serial,
					imei,
					phone,
					sim,
					password: pass,
					firmware,
					carrier,
					part_num,
					attached_items: { id: cur_attached_items },
				},
				action: (binFound && _bin.offsite) || location.toUpperCase().startsWith('WI') ? 'OUT' : 'IN',
				user: localStorage.getItem('FullName'),
				refurb:
					(binFound && _bin.offsite) || location.toUpperCase().startsWith('WI')
						? 1
						: model.includes('WI-')
						? 0
						: item.refurbished,
				year,
				qty,
				item_name,
				barcode,
				model,
				item_id,
			});
		}

		await errorHandler(binFound, qty, location, inputs);

		return { valid: true, values: inputs };
	};

	const handleCloseSnack = () => {
		setSnackbar((prevState) => ({
			...prevState,
			visible: false,
		}));
	};

	async function errorHandler(binFound, qty, location, inputs) {
		// Handle errors and provide an error message describing what went wrong. Could move this to a function to pass around to components. Keep in parent InventoryItem.js
		// If no errors return the data to be updated into inventory
		// const regex = /https:|http:/;
		// let item_id;

		if (!binFound) {
			setErrors({
				location: true,
			});
			return {
				valid: false,
				msg: `Our system cannot validate the destination that you have selected. The destination may not exist or is not allowed to be transferred to this location.`,
			};
		} else if (!qty) {
			setErrors({
				qty: true,
			});
			return {
				valid: false,
				msg: `Please make sure that a quantity is selected.`,
			};
		} else if (!location) {
			setErrors({
				location: true,
			});
			return {
				valid: false,
				msg: `Please choose a destination for this item.`,
			};
		}
		return { valid: true, values: inputs };
	}

	const getItemByModel = (model) => {
		// get item by model. Applies to bulk items and singular items
		return new Promise((resolve, reject) => {
			socket.emit('getItemByModel', model, (res) => {
				if (!res) {
					console.error(`InventoryBin.getItemByModel: There was an issue calling this method`);
					reject(false);
				} else {
					if (!res.length) {
						setErrors({
							model: true,
						});
						resolve({ found: false });
					} else {
						setLoading(true);
						resolve({
							found: true,
							item_id: res[0].id,
							item_name: res[0].item_name,
						});
					}
				}
			});
		});
	};
	const getItemByItemID = (item_id) => {
		// get item by item_id. Applies to bulk items and singular items
		return new Promise((resolve, reject) => {
			socket.emit('getItemById', item_id, (res) => {
				if (!res) {
					console.error(`InventoryBin.getItemByItemID: There was an issue calling this method`);
					reject(false);
				} else {
					if (!res.length) {
						setErrors({
							item_id: true,
						});
						resolve({ found: false });
					} else {
						resolve(res[0].model);
					}
				}
			});
		});
	};

	const updateBinQty = () => {
		return new Promise((resolve, reject) => {
			socket.emit('updateBinQty', (res) => {
				if (!res) {
					console.error(`InventoryBin.updateBinQty: There was an issue calling this method - ${new Date()}`);
					reject(false);
				} else {
					resolve(true);
				}
			});
		});
	};

	function toggleLocation() {
		setLocationOpen((prevState) => !prevState);
	}

	function renderTransferItems() {
		const x = document.getElementsByName('selected-table-checkbox');
		const selected = [];

		for (let i = 0; i < x.length; i++) {
			if (x[i].checked) {
				const item = items.find((el) => el.child_id == x[i].id);
				selected.push(item);
			}
		}

		return selected.map((item) => (
			<tr id={item.child_id} key={item.child_id}>
				<td>{item.barcode !== 'null' ? item.barcode : item.item_name}</td>
			</tr>
		));
	}

	// Renders a different modal depending on if it is a bulk/single item bin you are transferring from. Need renderTransferItems to move from a single bin with checkmarks
	return (
		<div className='ViewedContentContainer' id='OpenContainer'>
			<div style={{ height: '100%', width: '98%' }}>
				<div className='TitleBarContainer'>
					<h1>
						{items.length ? items[0].name : ctr && ctr.length ? ctr[0].bin_name : ''}{' '}
						{ctr && ctr.length && ctr[0].contractor ? ` - (${ctr[0].contractor})` : ''}
					</h1>
				</div>
				{modalOpen ? TransferItemModal(transferItemModalProps) : null}

				{locationOpen ? ItemLocationModal(itemLocationProps) : null}

				{buildOpen ? BuildItemModal(buildItemProps) : null}

				{contractorOpen ? StickerContractorJobModal(StickerContractorJobModalProps) : null}

				<AlertBar
					visible={snackbar.visible}
					onClose={handleCloseSnack}
					message={snackbar.message}
					severity={snackbar.severity}
				/>
				{/* (StickerContractorJobs(StickerContractorProps)) */}
				{bin && bin.includes('CONTR') && ctr && ctr.length ? (
					StickerContractorJobs(StickerContractorProps)
				) : (
					<CustomTable
						searchable
						paginate
						rpp={50}
						loading_data={loading}
						search={Search}
						clear_search={ClearSearch}
						headers={TableHeaders}
						search_options={SearchOptions}
						rows={renderTablebody()}
						onClick={tableRowClick}
						table_buttons={renderTableButtons()}
					/>
				)}
			</div>
		</div>
	);
};

export default InventoryBin;
