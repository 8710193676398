import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { FormatNA, FormatDateFromYear } from '../../imports/API/TextFormatingFunctions.js';
import CustomTable from '../../components/Table';
import AlertBar from '../../components/AlertBar';
import Modal from '../../components/Modal';
import Button from '@mui/material/Button';
import { FaDownload } from 'react-icons/fa';
import { useCustomContext } from '../../hoc/Context.js';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import $ from 'jquery';

const TableHeaders = [
	{ id: 'serviced', name: 'Serviced', size: 'small-header' },
	{
		id: 'tech',
		name: 'Technician',
		size: 'small-header',
		sortable: true,
		numeric: false,
	},
	{
		id: 'job-id',
		name: 'Job #',
		size: 'small-header',
		sortable: true,
		numeric: false,
	},
	{ id: 'issue', name: 'Issue', sortable: true, numeric: false },
	{ id: 'work-time', name: 'Work Time', sortable: true, numeric: false },
	{ id: 'drive-time', name: 'Drive Time', sortable: true, numeric: false },
	{ id: 'kiosk', name: 'Kiosk', sortable: true, numeric: false },
	{ id: 'notes', name: 'Notes', size: 'xlarge-header' },
];

const SearchOptions = [
	{ id: 'tech', name: 'Technician', pidx: 1 },
	{ id: 'job#', name: 'Job#', pidx: 2 },
	{ id: 'issue', name: 'Issue', pidx: 3 },
	{ id: 'kiosk', name: 'Kiosk', pidx: 6 },
];

//Formats the TechnicianTable Information for each row
const ServiceReportTable = () => {
	const t1 = new Date().setDate(1);
	const t2 = new Date();
	t2.setMonth(t2.getMonth() + 1);

	const [afterDate] = useState(FormatDateFromYear(t1));
	const [beforeDate] = useState(FormatDateFromYear(t2));
	const [Loading, setLoading] = useState(true);
	const [ReportData, setReportData] = useState('');
	const [Lists, setLists] = useState([]);
	const [ServiceReportDB, setServiceReportDB] = useState([]);
	const [TechniciansDB, setTechniciansDB] = useState([]);
	const [OriginalReport, setOriginalReport] = useState([]);
	const [QBReport, setQBReport] = useState([]);
	const [retrieveData, setRetrieveData] = useState(true);
	const [snackbar, setSnackbar] = useState({
		visible: false,
		message: '',
		severity: 'success',
	});
	const [updating, setUpdating] = useState(false);
	const [open, setOpen] = useState(false);
	const { userState } = useCustomContext();
	const [searchVal, setSearchVal] = useState('');
	const [searchParam, setSearchParam] = useState(0);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			if (retrieveData) {
				// only retrieve this data once on page load
				$.post(`/api/table_data`, { table: 'service_report_table' }, (res) => {
					if (res.status == 'OK') {
						const [service_reports, techs, email_list] = res.data;
						setServiceReportDB(service_reports);
						setTechniciansDB(techs);
						setLists(email_list.filter((list) => list.ListName == 'Service Report')[0]);
						setRetrieveData(false);
						setLoading(false);
					} else {
						setRetrieveData(false);
						setLoading(false);
						setSnackbar((prevState) => ({
							...prevState,
							visible: true,
							message: res.error,
							severity: 'error',
						}));
					}
				});
			}
		}

		if (OriginalReport.length) {
			document.getElementById('original_download').click();
		} else if (QBReport.length) {
			document.getElementById('qb-download').click();
		}

		return () => {
			mounted = false;
		};
	}, [OriginalReport]);

	useEffect(() => {
		$('#import-modal').toggleClass('flex-box');
	}, [open]);

	const RefreshData = () => {
		$.post(`/api/table_data`, { table: 'service_report_table' }, (res) => {
			if (res.status == 'OK') {
				const [service_reports, techs, email_list] = res.data;
				setServiceReportDB(service_reports);
				setTechniciansDB(techs);
				setLists(email_list.filter((list) => list.ListName == 'Service Report')[0]);
				setLoading(false);
			} else {
				setLoading(false);
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: res.error,
					severity: 'error',
				}));
			}
		});
	};

	function FormatServiceDate(dateIn) {
		const temp = dateIn.split('T');

		const [year, month, date] = temp[0].split('-');

		const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

		return monthNames[parseInt(month) - 1] + '. ' + date.trim() + ', ' + year.trim();
	}

	const RowClicked = (e) => {
		const id = e.target.id;
		const report = ServiceReportDB.find((rep) => rep.ServiceReportID == id);
		setReportData(report);
		setOpen(true);
	};

	function generateQBReport() {
		setOriginalReport([]);
		const filteredArray = [];
		const techList = TechniciansDB;
		let q = 0;

		for (let z = 0; z < ServiceReportDB.length; z++) {
			let techCo;
			let month;

			// Do a drive time check

			// If a technician belongs to a company, replace their name with the company name (unless that company is RTI), else, leave as is.
			if (ServiceReportDB[z].ServiceTech === 'Toby Robinson') {
				techCo = 'Tobias Robinson';
			} else if (ServiceReportDB[z].ServiceTech === 'Dave Hinkel') {
				techCo = 'David Hinkel';
			} else if (ServiceReportDB[z].ServiceTech === 'Jacob Vorwald') {
				techCo = 'Jake Vorwald';
			} else if (ServiceReportDB[z].ServiceTech === 'Jon Phillips') {
				techCo = 'Jonathan Phillips';
			} else if (ServiceReportDB[z].ServiceTech === 'James Frank') {
				techCo = 'James Frank';
			} else if (
				ServiceReportDB[z].CompanyName === '' ||
				ServiceReportDB[z].CompanyName === 'Registration Technology Inc'
			) {
				techCo = ServiceReportDB[z].ServiceTech;
			} else if (ServiceReportDB[z].CompanyName !== '') {
				techCo = ServiceReportDB[z].CompanyName;
			}

			// Get the date the log was filed
			const logDate = ServiceReportDB[z].TimeSubmited.split(/[,| ]/);
			if (logDate[0] === 'Jan.') {
				month = 1;
			} else if (logDate[0] === 'Feb.') {
				month = 2;
			} else if (logDate[0] === 'Mar.') {
				month = 3;
			} else if (logDate[0] === 'Apr.') {
				month = 4;
			} else if (logDate[0] === 'May.') {
				month = 5;
			} else if (logDate[0] === 'Jun.') {
				month = 6;
			} else if (logDate[0] === 'Jul.') {
				month = 7;
			} else if (logDate[0] === 'Aug.') {
				month = 8;
			} else if (logDate[0] === 'Sep.') {
				month = 9;
			} else if (logDate[0] === 'Oct.') {
				month = 10;
			} else if (logDate[0] === 'Nov.') {
				month = 11;
			} else if (logDate[0] === 'Dec.') {
				month = 12;
			}

			const day = logDate[1];
			const year = logDate[3];
			const timeOfDay = logDate[4] + ' ' + logDate[5];

			// Format the date into XX/YY/ZZZZ
			const formattedDate = month + '/' + day + '/' + year;

			// Get the last day of the month
			const today = new Date();
			const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).toLocaleDateString();

			// Get the tech's address
			let streetAddress;
			let city;
			let state;
			let zip;

			for (let y = 0; y < techList.length; y++) {
				if (techList[y].FirstName + ' ' + techList[y].LastName === ServiceReportDB[z].ServiceTech) {
					streetAddress = techList[y].Address;
					city = techList[y].City;
					state = techList[y].State;
					zip = techList[y].Zip;
				}
			}

			// Translate issue to QB issue
			let issue;

			// Calculate the total time to pay
			const driveTimeToSplit = ServiceReportDB[z].DriveTime.split(' ');
			const workTimeToSplit = ServiceReportDB[z].WorkTime;
			let workTime = 0;
			let driveTime = 0;
			let totalTime = 0;
			let tripMinNeeded = false;

			// Calculate the drive time. If over 120 minutes or multitrip, leave as is.
			if (driveTimeToSplit[0] == '10') {
				driveTime = 10;
			} else if (driveTimeToSplit[0] == '20') {
				driveTime = 20;
			} else if (driveTimeToSplit[0] == '30') {
				driveTime = 30;
			} else if (driveTimeToSplit[0] == '40') {
				driveTime = 40;
			} else if (driveTimeToSplit[0] == '50') {
				driveTime = 50;
			} else if (driveTimeToSplit[0] == '60') {
				driveTime = 60;
			} else if (driveTimeToSplit[0] == '70') {
				driveTime = 70;
			} else if (driveTimeToSplit[0] == '80') {
				driveTime = 80;
			} else if (driveTimeToSplit[0] == '90') {
				driveTime = 90;
			} else if (driveTimeToSplit[0] == '100') {
				driveTime = 100;
			} else if (driveTimeToSplit[0] == '110') {
				driveTime = 110;
			} else if (driveTimeToSplit[0] == '120') {
				driveTime = 120;
			} else if (driveTimeToSplit[0] == '120+') {
				driveTime = '120+ Minutes';
			} else if (driveTimeToSplit[0] == 'Multi-Trip') {
				driveTime = 'Multi-Trip';
			}

			if (workTimeToSplit === '15 minutes') {
				workTime = 15;
			} else if (workTimeToSplit === '30 minutes') {
				workTime = 30;
			} else if (workTimeToSplit === '45 minutes') {
				workTime = 45;
			} else if (workTimeToSplit === '1 hour') {
				workTime = 60;
			} else if (workTimeToSplit === '1 hour 15 minutes') {
				workTime = 75;
			} else if (workTimeToSplit === '1 hour 30 minutes') {
				workTime = 90;
			} else if (workTimeToSplit === '1 hour 45 minutes') {
				workTime = 105;
			} else if (workTimeToSplit === '2 hour') {
				workTime = 120;
			} else if (workTimeToSplit === '2 hour 15 minutes') {
				workTime = 135;
			} else if (workTimeToSplit === '2 hour 30 minutes') {
				workTime = 150;
			} else if (workTimeToSplit === '2 hour 45 minutes') {
				workTime = 165;
			} else if (workTimeToSplit === '3 hour') {
				workTime = 180;
			} else if (workTimeToSplit === '3+ hour(Fill out Notes section)') {
				workTime = '3+ Hours Work';
			}

			// If drive time is over 120 or multi-trip, flag it
			if (driveTime === '120+ Minutes') {
				totalTime = 'TO BE CALCULATED';
			} else if (driveTime === 'Multi-Trip') {
				totalTime = 'TO BE CALCULATED';
			} else if (workTime === '3+ Hours Work') {
				totalTime = 'TO BE CALCULATED';
			} else if (workTime + driveTime < 60) {
				tripMinNeeded = true;
				totalTime = 60;
			} else {
				totalTime = driveTime + workTime;
			}

			// Calculate the amount to pay to the tech.
			let totalToPay = 'TO BE CALCULATED';

			if (totalTime == '120+ / Multi-Trip') {
				totalToPay = 'TO BE CALCULATED';
			} else if (totalTime == '3+ Hours Work') {
				totalToPay = 'TO BE CALCULATED';
			} else {
				totalToPay = parseFloat(totalTime * 0.5).toFixed(2);
			}

			// Convert the issue to match what's in QuickBooks
			issue = ServiceReportDB[z].KioskIssue;
			let QBissue = '';
			let driveIssue = '';
			let tripMinIssue = '';

			// If the issue is related to paper, it's handled here
			if (issue == 'Paper Change - LOW') {
				QBissue = 'Service Tech Calls:Maintenance (Paper Changes):Paper change';
				driveIssue = 'Service Tech Calls:Maintenance (Paper Changes):Drive Time Paper Change';
				tripMinIssue = 'Service Tech Calls:Maintenance (Paper Changes):Premium-Trip Minimum-Paper Chg';
			} else if (issue == 'Paper Change - RAN OUT') {
				QBissue = 'Service Tech Calls:Maintenance (Paper Changes):Paper change';
				driveIssue = 'Service Tech Calls:Maintenance (Paper Changes):Drive Time Paper Change';
				tripMinIssue = 'Service Tech Calls:Maintenance (Paper Changes):Premium-Trip Minimum-Paper Chg';
			} else if (issue == 'Paper Change - NEW YEAR') {
				QBissue = 'Service Tech Calls:Maintenance (Paper Changes):Paper change';
				driveIssue = 'Service Tech Calls:Maintenance (Paper Changes):Drive Time Paper Change';
				tripMinIssue = 'Service Tech Calls:Maintenance (Paper Changes):Premium-Trip Minimum-Paper Chg';
			}

			// If the issue is related to kiosk mantiance, it's handled here.
			if (issue == 'Datamax - PAPER JAM') {
				QBissue = 'Service Tech Calls:Service Calls:Datamax Repair';
				driveIssue = 'Service Tech Calls:Service Calls:Drive Time';
				tripMinIssue = 'Service Tech Calls:Service Calls:Premium-Trip Minimum';
			} else if (issue == 'Datamax - FAULTED NOT JAMMED') {
				QBissue = 'Service Tech Calls:Service Calls:Datamax Repair';
				driveIssue = 'Service Tech Calls:Service Calls:Drive Time';
				tripMinIssue = 'Service Tech Calls:Service Calls:Premium-Trip Minimum';
			} else if (issue == 'Star - PAPER JAM') {
				QBissue = 'Service Tech Calls:Service Calls:Star Printer Issue';
				driveIssue = 'Service Tech Calls:Service Calls:Drive Time';
				tripMinIssue = 'Service Tech Calls:Service Calls:Premium-Trip Minimum';
			} else if (issue == 'Star - OTHER ISSUE') {
				QBissue = 'Service Tech Calls:Service Calls:Star Printer Issue';
				driveIssue = 'Service Tech Calls:Service Calls:Drive Time';
				tripMinIssue = 'Service Tech Calls:Service Calls:Premium-Trip Minimum';
			} else if (issue == 'Internet - MODEM ISSUE') {
				QBissue = 'Service Tech Calls:Service Calls:Internet-kiosk connection';
				driveIssue = 'Service Tech Calls:Service Calls:Drive Time';
				tripMinIssue = 'Service Tech Calls:Service Calls:Premium-Trip Minimum';
			} else if (issue == 'Internet - KIOSK ISSUE') {
				QBissue = 'Service Tech Calls:Service Calls:Internet-kiosk connection';
				driveIssue = 'Service Tech Calls:Service Calls:Drive Time';
				tripMinIssue = 'Service Tech Calls:Service Calls:Premium-Trip Minimum';
			} else if (issue == 'Power Outage') {
				QBissue = 'Service Tech Calls:Service Calls:Other';
				driveIssue = 'Service Tech Calls:Service Calls:Drive Time';
				tripMinIssue = 'Service Tech Calls:Service Calls:Premium-Trip Minimum';
			} else if (issue == 'Other Issue - Kiosk') {
				QBissue = 'Service Tech Calls:Service Calls:Other';
				driveIssue = 'Service Tech Calls:Service Calls:Drive Time';
				tripMinIssue = 'Service Tech Calls:Service Calls:Premium-Trip Minimum';
			} else if (issue == 'Re-Image') {
				QBissue = 'Service Tech Calls:Service Calls:Other';
				driveIssue = 'Service Tech Calls:Service Calls:Drive Time';
				tripMinIssue = 'Service Tech Calls:Service Calls:Premium-Trip Minimum';
			} else if (issue == 'Credit Card Reader') {
				QBissue = 'Service Tech Calls:Service Calls:Credit Card Reader Issue';
				driveIssue = 'Service Tech Calls:Service Calls:Drive Time';
				tripMinIssue = 'Service Tech Calls:Service Calls:Premium-Trip Minimum';
			} else if (issue == 'Scanner') {
				QBissue = 'Service Tech Calls:Service Calls:Other';
				driveIssue = 'Service Tech Calls:Service Calls:Drive Time';
				tripMinIssue = 'Service Tech Calls:Service Calls:Premium-Trip Minimum';
			} else if (issue == 'Touch Monitor') {
				QBissue = 'Service Tech Calls:Service Calls:Other';
				driveIssue = 'Service Tech Calls:Service Calls:Drive Time';
				tripMinIssue = 'Service Tech Calls:Service Calls:Premium-Trip Minimum';
			} else if (issue == 'Kiosk Install') {
				QBissue = 'Service Tech Calls:Service Calls:Other';
				driveIssue = 'Service Tech Calls:Service Calls:Drive Time';
				tripMinIssue = 'Service Tech Calls:Service Calls:Premium-Trip Minimum';
			} else if (issue == 'Part Install') {
				QBissue = 'Service Tech Calls:Service Calls:Replacing Parts';
				driveIssue = 'Service Tech Calls:Service Calls:Drive Time';
				tripMinIssue = 'Service Tech Calls:Service Calls:Premium-Trip Minimum';
			}

			// Bill Number
			const billDate = new Date();
			let billNo;

			if (q < 10) {
				billNo = billDate.getMonth() + 1 + '00' + q;
			} else if (q >= 10 && q < 100) {
				billNo = billDate.getMonth() + 1 + '0' + q;
			} else if (q >= 100) {
				billNo = billDate.getMonth() + 1 + '' + q;
			}

			// Push the kiosk issue
			filteredArray.push({
				'Bill No': billNo,
				Vendor: techCo,
				Date: formattedDate,
				Timestamp: timeOfDay,
				'Due Date': lastDayOfMonth,
				'Address Line 1': streetAddress,
				'Address Line 2': city,
				City: city,
				State: state,
				'Postal Code': zip,
				'Product/Service': QBissue,
				'Product/Service Description': ServiceReportDB[z].Notes,
				'Work Time': workTime,
				'Expected Drive Time (One Way)': '',
				'Drive Time': '',
				'Product/Service Quantity': workTime,
				'Unit of Measure': 'min',
				'Product/Service Rate': 0.5,
				'Product/Service Amount': workTime * 0.5,
				Currency: 'USD',
				'Product/Service Customer': ServiceReportDB[z].QBMap,
			});

			// Add a line for trip min if necessary
			if (tripMinNeeded == true) {
				filteredArray.push({
					'Bill No': billNo,
					Vendor: techCo,
					Date: formattedDate,
					Timestamp: timeOfDay,
					'Due Date': lastDayOfMonth,
					'Address Line 1': streetAddress,
					'Address Line 2': city,
					City: city,
					State: state,
					'Postal Code': zip,
					'Product/Service': tripMinIssue,
					'Expected Drive Time (One Way)': '',
					'Drive Time': '',
					'Product/Service Quantity': 60 - driveTime - workTime,
					'Unit of Measure': 'min',
					'Product/Service Rate': 0.5,
					'Product/Service Amount': (60 - driveTime - workTime) * 0.5,
					Currency: 'USD',
					'Product/Service Customer': ServiceReportDB[z].QBMap,
				});
			}
			q++;
		}

		// Group the techs
		function NameSort(a, b) {
			if (a.Vendor < b.Vendor) {
				return -1;
			}
			if (a.Vendor > b.Vendor) {
				return 1;
			}
			return 0;
		}

		filteredArray.sort(NameSort);

		setQBReport(filteredArray);
	}

	const GenerateOriginalReport = () => {
		setOriginalReport([]);
		setQBReport([]);
		const report = ServiceReportDB.map((el) => {
			return {
				ServiceReportID: el.ServiceReportID,
				ServiceDate: el.ServiceDate,
				TimeSubmited: el.TimeSubmited,
				ServiceTech: el.ServiceTech,
				CompanyName: el.CompanyName,
				JobID: el.JobID,
				WorkTime: el.WorkTime,
				'Expected Drive Time (One Way)': el.expected_dt ? el.expected_dt : '',
				DriveTime: el.DriveTime,
				KioskID: el.kiosk_id,
				ServerID: el.KioskID.split('/')[1].trim(),
				Address: el.kiosk_address,
				Latitude: el.Latitude,
				Longitude: el.Longitude,
				PhoneNumber: el.PhoneNumber,
				KioskIssue: el.KioskIssue,
				DocsLeftWith: el.DocsLeftWith,
				Notes: el.Notes,
			};
		});

		setOriginalReport(report);
	};

	const CloseSendEmail = () => {
		setOpen(false);
	};

	const ResendSREmail = () => {
		const serviceReportObject = ReportData;
		const [serviceDate] = serviceReportObject.ServiceDate.split('T');
		const to = Lists.EmailTo;

		const text =
			`<div width="100%",style="text-align:left;">` +
			`<h1 style="text-align:center;">Service Report</h1>` +
			`<table border="1", width="100%">` +
			`<tbody style="text-align:left;">` +
			`<tr><td width="300">Report Submitted</td> <td>` +
			serviceReportObject.TimeSubmited +
			`</td></tr>` +
			`<tr><td>Service Date</td> <td>` +
			serviceDate +
			`</td></tr>` +
			`<tr><td>Technician</td> <td>` +
			serviceReportObject.ServiceTech +
			`</td></tr>` +
			`<tr><td>Job ID</td> <td>` +
			serviceReportObject.JobID +
			`</td></tr>` +
			`<tr><td>Kiosk ID/Server</td> <td>` +
			serviceReportObject.KioskID +
			`</td></tr>` +
			`<tr><td>Kiosk Issue</td> <td>` +
			serviceReportObject.KioskIssue +
			`</td></tr>` +
			`<tr><td>Drive Time</td> <td>` +
			serviceReportObject.DriveTime +
			`</td></tr>` +
			`<tr><td>Work Time</td> <td>` +
			serviceReportObject.WorkTime +
			`</td></tr>` +
			`<tr><td>Datamax - Manual Form Feeds?</td> <td>` +
			serviceReportObject.DMHowManyFormFeeds +
			`</td></tr>` +
			`<tr><td>Datamax1 - Test Prints</td> <td>` +
			serviceReportObject.DMOneTestPrints +
			`</td></tr>` +
			`<tr><td>Datamax2 - Test Prints</td> <td>` +
			serviceReportObject.DMTwoTestPrints +
			`</td></tr>` +
			`<tr><td>Star - Test Prints</td> <td>` +
			serviceReportObject.StarTestPrints +
			`</td></tr>` +
			`<tr><td>Star Paper Rolls</td> <td>` +
			serviceReportObject.HowManyExtraRollsOfStar +
			`</td></tr>` +
			`<tr><td>Sticky Rolls</td> <td>` +
			serviceReportObject.StickyRolls +
			`</td></tr>` +
			`<tr><td>Updated the Modem Info</td> <td>` +
			serviceReportObject.DidYouUpdateModemCellCarrierInfo +
			`</td></tr>` +
			`<tr><td>Phone Number</td> <td>` +
			serviceReportObject.PhoneNumber +
			`</td></tr>` +
			`<tr><td>Carrier</td> <td>` +
			null +
			`</td></tr>` +
			`<tr><td>Updated the Page Counts</td> <td>` +
			serviceReportObject.PageCounts +
			`</td></tr>` +
			`<tr><td>Documents Left With(If Applicable)</td> <td>` +
			serviceReportObject.DocsLeftWith +
			`</td></tr>` +
			`<tr><td>Notes</td> <td>` +
			serviceReportObject.Notes +
			`</td></tr>` +
			`</tbody>` +
			`</table >` +
			`</div >`;

		$.get(
			'/send',
			{
				to: to,
				subject: 'Service Log - ' + serviceReportObject.KioskID,
				text: text,
			},
			(res) => {
				if (res.includes('Mail sent successfully')) {
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: 'Service report has been sent.',
						severity: 'success',
					}));

					setTimeout(() => {
						CloseSendEmail();
					}, 750);
				} else {
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: 'ERROR: There was an issue sending this email.',
						severity: 'error',
					}));
				}
			}
		);
	};

	function RenderTableButtons() {
		return userState.user.role ? (
			<div style={{ display: 'flex' }}>
				<CSVLink
					style={{ background: 'none', border: 'none' }}
					target='_blank'
					className='hidden'
					id='original_download'
					data={OriginalReport}
					filename={'SROriginalList.csv'}
				></CSVLink>
				<Button
					className='rti-blue-round'
					variant='contained'
					startIcon={<FaDownload />}
					onClick={GenerateOriginalReport}
				>
					Service Reports
				</Button>
				<CSVLink
					id='qb-download'
					style={{ background: 'none', border: 'none' }}
					target='_blank'
					className='hidden'
					data={QBReport}
					filename={'QB_Import.csv'}
				></CSVLink>
				<Button className='rti-blue-round' variant='contained' startIcon={<FaDownload />} onClick={generateQBReport}>
					QB Import
				</Button>
			</div>
		) : undefined;
	}

	const handleLimitDate = (after, before) => {
		setLoading(true);
		socket.emit('selectServiceReportsForTM', after, before, (result) => {
			setServiceReportDB(result);
			setLoading(false);
		});
	};

	const getTableData = () => {
		return ServiceReportDB.map((report) => ({
			key: report.ServiceReportID,
			cells: [
				{ data: FormatNA(FormatServiceDate(report.ServiceDate)) },
				{ data: report.ServiceTech },
				{ data: report.JobID },
				{ data: report.KioskIssue },
				{ data: report.WorkTime },
				{ data: report.DriveTime },
				{ data: report.KioskID },
				{ data: report.Notes },
			],
		}))
			.sort((a, b) => new Date(b.cells[0].data).getTime() - new Date(a.cells[0].data).getTime())
			.filter((row) =>
				searchVal && searchParam
					? row.cells[SearchOptions[searchParam - 1].pidx].data &&
					  row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchVal.toUpperCase())
					: row
			);
	};

	const handleCloseSnack = () => {
		setSnackbar((prevState) => ({
			...prevState,
			visible: false,
		}));
	};

	const handleModalClose = () => {
		setOpen(false);
	};

	const handleSaveChanges = () => {
		setUpdating(true);
		const dt = document.getElementById('drive-time').value;
		const wt = document.getElementById('work-time').value;

		const sr_obj = {
			work_time: wt,
			drive_time: dt,
		};

		socket.emit('updateServiceReport', ReportData.ServiceReportID, sr_obj, (res) => {
			if (!res) {
				console.error(`ServiceReportTable.updateServiceReport: There was an issue calling this method`);
				setUpdating(false);
			} else {
				setUpdating(false);
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: 'Success! Service report has been updated.',
					severity: 'success',
				}));
				RefreshData();
			}
		});
	};

	const search = (param, searchVal) => {
		setSearchVal(searchVal);
		setSearchParam(param);
	};

	const clearSearch = () => {
		setSearchVal('');
	};

	return (
		<div style={{ height: '95%' }}>
			<div className='ViewedContentContainer' id='OpenContainer'>
				{open ? (
					<Modal
						modal_id='import-modal'
						dimension={{ width: 500 }}
						modal_header={`${ReportData.ServiceTech} - ${ReportData.KioskID.substring(0, 6)}`}
						onClose={handleModalClose}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<div>
								<TextField
									disabled
									id='tech'
									className='table-date-input space-below-medium'
									label='Technician'
									type='text'
									defaultValue={ReportData.ServiceTech}
								/>
								<TextField
									disabled
									id='job-id'
									className='table-date-input space-below-medium'
									label='Job#'
									type='text'
									defaultValue={ReportData.JobID}
								/>
								<TextField
									disabled
									id='kiosk'
									className='table-date-input space-below-medium'
									label='Kiosk'
									type='text'
									defaultValue={ReportData.KioskID.substring(0, 6)}
								/>
								<TextField
									disabled
									id='date-serviced'
									className='table-date-input space-below-medium'
									label='Service Date'
									type='date'
									defaultValue={ReportData.ServiceDate.split('T')[0]}
								/>
								<TextField
									disabled
									id='issue'
									className='table-date-input space-below-medium'
									label='Issue'
									type='text'
									defaultValue={ReportData.KioskIssue}
								/>
							</div>
							<div style={{ display: 'flex', width: '100%' }}>
								<div className='input-block' style={{ marginRight: 16, marginLeft: 10 }}>
									<label className='input-label'>Work Time</label>
									<div className='search-by'>
										<select style={{ width: 134 }} id='work-time' defaultValue={ReportData.WorkTime}>
											<option key='0' value=''></option>
											<option key='1' value='15 minutes'>
												15 minutes
											</option>
											<option key='2' value='30 minutes'>
												30 minutes
											</option>
											<option key='3' value='45 minutes'>
												45 minutes
											</option>
											<option key='4' value='1 hour'>
												1 hour
											</option>
											<option key='5' value='1 hour 15 minutes'>
												1 hour 15 minutes
											</option>
											<option key='6' value='1 hour 30 minutes'>
												1 hour 30 minutes
											</option>
											<option key='7' value='1 hour 45 minutes'>
												1 hour 45 minutes
											</option>
											<option key='8' value='2 hour'>
												2 hour
											</option>
											<option key='9' value='2 hour 15 minutes'>
												2 hour 15 minutes
											</option>
											<option key='10' value='2 hour 30 minutes'>
												2 hour 30 minutes
											</option>
											<option key='11' value='2 hour 45 minutes'>
												2 hour 45 minutes
											</option>
											<option key='12' value='3 hour'>
												3 hour
											</option>
											<option key='13' value='3+ hour(Fill out Notes section)'>
												3+ hour(Fill out Notes section)
											</option>
										</select>
									</div>
								</div>
								<div className='input-block' style={{ marginRight: 16 }}>
									<label className='input-label'>Drive Time</label>
									<div className='search-by'>
										<select style={{ width: 134 }} id='drive-time' defaultValue={ReportData.DriveTime}>
											<option key='0' value=''></option>
											<option key='1' value='10 Minutes (5 minutes One-Way)'>
												10 Minutes (5 minutes One-Way)
											</option>
											<option key='2' value='20 Minutes (10 minutes One-Way)'>
												20 Minutes (10 minutes One-Way)
											</option>
											<option key='3' value='30 Minutes (15 minutes One-Way)'>
												30 Minutes (15 minutes One-Way)
											</option>
											<option key='4' value='40 Minutes (20 minutes One-Way)'>
												40 Minutes (20 minutes One-Way)
											</option>
											<option key='5' value='50 Minutes (25 minutes One-Way)'>
												50 Minutes (25 minutes One-Way)
											</option>
											<option key='6' value='60 Minutes (30 minutes One-Way)'>
												60 Minutes (30 minutes One-Way)
											</option>
											<option key='7' value='70 Minutes (35 minutes One-Way)'>
												70 Minutes (35 minutes One-Way)
											</option>
											<option key='8' value='80 Minutes (40 minutes One-Way)'>
												80 Minutes (40 minutes One-Way)
											</option>
											<option key='9' value='90 Minutes (45 minutes One-Way)'>
												90 Minutes (45 minutes One-Way)
											</option>
											<option key='10' value='100 Minutes (50 minutes One-Way)'>
												100 Minutes (50 minutes One-Way)
											</option>
											<option key='11' value='110 Minutes (55 minutes One-Way)'>
												110 Minutes (55 minutes One-Way)
											</option>
											<option key='12' value='120 Minutes (60 minutes One-Way)'>
												120 Minutes (60 minutes One-Way)
											</option>
											<option key='13' value='120+ Minutes (Fill out Notes section)'>
												120+ Minutes (Fill out Notes section)
											</option>
											<option key='14' value='Multi-Trip'>
												Multi-Trip
											</option>
										</select>
									</div>
								</div>
							</div>
							{!updating ? (
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'flex-end',
										marginTop: 30,
									}}
								>
									<Button className='primary' onClick={ResendSREmail}>
										Resend Log
									</Button>
									<Divider orientation='vertical' flexItem />
									{userState.user.role ? (
										<Button className='primary' onClick={handleSaveChanges}>
											Save Changes
										</Button>
									) : null}
								</div>
							) : (
								<div style={{ width: '100%', marginTop: 30, textAlign: 'center' }}>
									<label>Saving...</label>
									<br></br>
									<LinearProgress />
								</div>
							)}
							<div
								className='circle-loader'
								id='circle-loader'
								style={{ marginBottom: '1.5em', left: '42%', display: 'none' }}
							>
								<div className='checkmark draw'></div>
							</div>
						</div>
					</Modal>
				) : null}

				<div className='TitleBarContainer'>
					<h1>Service Reports</h1>
				</div>
				<AlertBar
					visible={snackbar.visible}
					onClose={handleCloseSnack}
					message={snackbar.message}
					severity={snackbar.severity}
				/>
				<CustomTable
					paginate
					searchable
					search_options={SearchOptions}
					search={search}
					clear_search={clearSearch}
					dates={{ after: afterDate, before: beforeDate }}
					headers={TableHeaders}
					rows={getTableData()}
					loading_data={Loading}
					onLimitDate={handleLimitDate}
					onClick={RowClicked}
					table_buttons={RenderTableButtons()}
				/>
			</div>
		</div>
	);
};

export default ServiceReportTable;
