import env from '../../utilities/env-loader.cjs';
import { DateDiff } from "../../utilities/util.js";
import { GetLastDayOfMonth, DateToMDY } from "../API/TextFormatingFunctions.js";

const TEST_PRINTS = env.TEST_PRINTS;
const DEFAULT_RUNOUT = 999;
const STICKER_START = 10;
const STICKER_END = 4;
const DUE_DATE = 20;
import url from 'url';
import path from 'path';
import fileUrl from 'file-url';

const __filename = fileUrl(import.meta.url);
// const __filename = url.fileURLToPath(import.meta.url);
const _file = path.basename(__filename);

const last_day_of_month = GetLastDayOfMonth(1); // returns the last day of the current month
const ldnm = GetLastDayOfMonth(2); // returns the last day of the month, 30 days from current month
const ldnmp = GetLastDayOfMonth(3); // returns the last day of the month, 60 days from current month
const cur_month = new Date().getMonth();
const cur_date = new Date().getDate();
const cur_year = new Date().getFullYear();

export const getPaperProjections = (paper_trends = {}, paper_jobs = []) => {
	try {
		const prev_month = new Date().getMonth() - 1;
		const next_month = new Date().getMonth() + 1;
		const next_month_plus = new Date();
		next_month_plus.setMonth(next_month_plus.getMonth() + 2);
		const paper_ship_date = new Date();
		paper_ship_date.setMonth(paper_ship_date.getMonth() + 1);
		paper_ship_date.setDate(Math.round(GetLastDayOfMonth(2) / 2));
		const date = (prev_month + cur_date / last_day_of_month).toFixed(2);
		const due_date = getDueDate();
		const ddp = new Date(due_date.getTime());
		ddp.setMonth(ddp.getMonth() + 1);

		return Object.entries(paper_trends)
			.map(([key, value]) => {
				const priority_sticker = cur_year + 1 == value.dm1_type ? value.dm1_type : value.dm2_type;
				const non_priority_sticker = cur_year + 1 != value.dm1_type ? value.dm1_type : value.dm2_type;
				const current_sticker = cur_year == value.dm1_type ? value.dm1_type : value.dm2_type;

				// returns value from current month as the element in the trends array
				const cur_total = value.trends[cur_month];

				// returns value from the upcoming month as the element in the trends array
				const next_total = value.trends[next_month];

				// use equation of a line to get trendline
				// y = mx + b
				const m = cur_total;
				const b = cur_total - m * cur_month;
				const projected_daily = Math.round(m * cur_date + b); // gets projected for current date

				// get current transaction count and add remaining daily projections to get current trend
				const { current } = getDailyWeights();

        // TODO :: ORIGINAL VALUE THAT WE ARE TESTING
        const newProjected =
          current
            .slice(cur_date > 0 ? cur_date - 1 : 0)
            .map((daily) => (daily / 100) * cur_total)
            .reduce((total, amount) => total + amount, 0) + value.current_total;
        const non_priority_eol = eolProjection(
          non_priority_sticker,
          value,
          newProjected,
          key
        );
        const priority_eol = eolProjection(
          priority_sticker,
          value,
          newProjected,
          key
        );
        const trailing = [value.trends[0], value.trends[1], value.trends[2]];
        const star_eol = [...value.trends, ...trailing];

				const dailyProjections = getDailyProjections(
					priority_eol.projections,
					non_priority_eol.projections,
					star_eol,
					key
				);
				const priorityRunouts = getStickerRunout(
					dailyProjections.priority,
					priority_sticker == value.dm1_type ? value.dm1_count : value.dm2_count,
					key
				);
				const nonPriorityRunouts = getStickerRunout(
					dailyProjections.non_priority,
					non_priority_sticker == value.dm1_type ? value.dm1_count : value.dm2_count,
					key
				);
				const starRunouts = getStickerRunout(dailyProjections.star, value.star_count); // returns a date
				const revisedRunoutStar = calcRunoutByDate(dailyProjections.star, new Date(), due_date, value.star_count, key);
				const revisedRunoutDM = calcRunoutByDate(
					dailyProjections.priority,
					new Date(),
					due_date,
					priority_sticker == value.dm1_type ? value.dm1_count : value.dm2_count,
					key
				);
				const earliest_runout = getEarliestRunout(
					{
						dm1_runouts: value.dm1_type == priority_sticker ? priorityRunouts : nonPriorityRunouts,
						dm2_runouts: value.dm2_type == priority_sticker ? priorityRunouts : nonPriorityRunouts,
						star_runouts: starRunouts,
					},
					value.dm1_type
				);

				const average_txn = cur_total / last_day_of_month;

				const dayEl = new Date().getDate();
				const star_txn_avg = value.star_count / dayEl;

				const assigned_paper = getOpenPaperJobs(paper_jobs, key);
				const ship_date = earliest_runout.date ? new Date(earliest_runout.date) : null;
				ship_date ? ship_date.setDate(ship_date.getDate() - 7) : null;

				const star_runout = getPaperRunout(average_txn, next_total, 'Star', value.star_count);
				const dm1_runout = getPaperRunout(average_txn, next_total, value.dm1_type, value.dm1_count);
				const dm2_runout = getPaperRunout(average_txn, next_total, value.dm2_type, value.dm2_count);
				const priority_count = priority_sticker == value.dm1_type ? value.dm1_count : value.dm2_count;

				const star_projected =
					value.star_count && starRunouts[`under_${env.STICKER_THRESH[0]}`]
						? starRunouts[`under_${env.STICKER_THRESH[0]}`]
						: value.star_count && starRunouts[`under_${env.STICKER_THRESH[1]}`]
						? starRunouts[`under_${env.STICKER_THRESH[1]}`]
						: value.star_count && starRunouts[`under_${env.STICKER_THRESH[2]}`]
						? starRunouts[`under_${env.STICKER_THRESH[2]}`]
						: '';

				const dm1_projected =
					value.dm1_type == priority_sticker && value.dm1_count && priorityRunouts[`under_${env.STICKER_THRESH[0]}`]
						? priorityRunouts[`under_${env.STICKER_THRESH[0]}`]
						: value.dm1_type == priority_sticker && value.dm1_count && priorityRunouts[`under_${env.STICKER_THRESH[1]}`]
						? priorityRunouts[`under_${env.STICKER_THRESH[1]}`]
						: value.dm1_type == priority_sticker && value.dm1_count && priorityRunouts[`under_${env.STICKER_THRESH[2]}`]
						? priorityRunouts[`under_${env.STICKER_THRESH[2]}`]
						: '' ||
						  (value.dm1_type == non_priority_sticker &&
								value.dm1_count &&
								nonPriorityRunouts[`under_${env.STICKER_THRESH[0]}`])
						? nonPriorityRunouts[`under_${env.STICKER_THRESH[0]}`]
						: value.dm1_type == non_priority_sticker &&
						  value.dm1_count &&
						  nonPriorityRunouts[`under_${env.STICKER_THRESH[1]}`]
						? nonPriorityRunouts[`under_${env.STICKER_THRESH[1]}`]
						: value.dm1_type == non_priority_sticker &&
						  value.dm1_count &&
						  nonPriorityRunouts[`under_${env.STICKER_THRESH[2]}`]
						? nonPriorityRunouts[`under_${env.STICKER_THRESH[2]}`]
						: '';

				const dm2_projected =
					value.dm2_type == priority_sticker && value.dm2_count && priorityRunouts[`under_${env.STICKER_THRESH[0]}`]
						? priorityRunouts[`under_${env.STICKER_THRESH[0]}`]
						: value.dm2_type == priority_sticker && value.dm2_count && priorityRunouts[`under_${env.STICKER_THRESH[1]}`]
						? priorityRunouts[`under_${env.STICKER_THRESH[1]}`]
						: value.dm2_type == priority_sticker && value.dm2_count && priorityRunouts[`under_${env.STICKER_THRESH[2]}`]
						? priorityRunouts[`under_${env.STICKER_THRESH[2]}`]
						: '' ||
						  (value.dm2_type == non_priority_sticker &&
								value.dm2_count &&
								nonPriorityRunouts[`under_${env.STICKER_THRESH[0]}`])
						? nonPriorityRunouts[`under_${env.STICKER_THRESH[0]}`]
						: value.dm2_type == non_priority_sticker &&
						  value.dm2_count &&
						  nonPriorityRunouts[`under_${env.STICKER_THRESH[1]}`]
						? nonPriorityRunouts[`under_${env.STICKER_THRESH[1]}`]
						: value.dm2_type == non_priority_sticker &&
						  value.dm2_count &&
						  nonPriorityRunouts[`under_${env.STICKER_THRESH[2]}`]
						? nonPriorityRunouts[`under_${env.STICKER_THRESH[2]}`]
						: '';

				const lowest_sticker_count = value.star_count < priority_count ? value.star_count : priority_count;
				const extra_prio =
					priority_sticker == value.dm1_type && value.dm1_count >= 100 && value.dm1_count < +0
						? parseInt(value.dm1_count - priority_eol.eol)
						: priority_sticker == value.dm2_type && value.dm2_count >= 100 && value.dm2_count < +0
						? parseInt(value.dm2_count - priority_eol.eol)
						: '';
				const extra_cur =
					current_sticker == value.dm1_type && value.dm1_count > non_priority_eol.eol
						? value.dm1_count - non_priority_eol.eol
						: current_sticker == value.dm2_type && value.dm2_count > non_priority_eol.eol
						? value.dm2_count - non_priority_eol.eol
						: '';
				const dm1_diff = value.dm1_count - TEST_PRINTS > 0 ? value.dm1_count - TEST_PRINTS : 0; // this might be adding additional stickers
				const dm2_diff = value.dm2_count - TEST_PRINTS > 0 ? value.dm2_count - TEST_PRINTS : 0;
				const dm1_final =
					value.dm1_type == priority_sticker ? priority_eol.eol - dm1_diff : non_priority_eol.eol - dm1_diff;
				const dm2_final =
					value.dm2_type == priority_sticker ? priority_eol.eol - dm2_diff : non_priority_eol.eol - dm2_diff;
				const proposedStar = revisedRunoutStar.total <= env.STICKER_THRESH[0] ? true : false; // return boolean
				const proposedPriority = revisedRunoutDM.total <= env.STICKER_THRESH[0] ? true : false; // returns a boolean
				const dm1_to_send =
					value.dm1_type == priority_sticker
						? priority_eol.eol - TEST_PRINTS > value.dm1_count
						: non_priority_eol.eol - TEST_PRINTS > value.dm1_count;
				const dm2_to_send =
					value.dm2_type == priority_sticker
						? priority_eol.eol - TEST_PRINTS > value.dm2_count
						: non_priority_eol.eol - TEST_PRINTS > value.dm2_count;
				let dm1_extra = 0;
				let dm2_extra = 0;

				if (dm1_projected) {
					const t0 = new Date(star_projected); // star_projected
					const t1 = new Date(dm1_projected); // datamax1_projected

					if (t0.getTime() < t1.getTime()) {
						// need to add additional counts to stickers since they will be changed early
						const sticker_diff = DateDiff(t0, t1, 'day');
						const _additional = Math.round(average_txn * sticker_diff);
						dm1_extra = _additional;
					}
				} else if (dm2_projected) {
					const t0 = new Date(star_projected); // star projected
					const t1 = new Date(dm2_projected); // datamax 2 projected

					if (t0.getTime() < t1.getTime()) {
						// need to add additional counts to stickers since they will be changed early
						const sticker_diff = DateDiff(t0, t1, 'day');
						const _additional = Math.round(average_txn * sticker_diff);
						dm2_extra = _additional;
					}
				}

				const proposed_final_dm = proposedPriority ? priority_eol.eol - revisedRunoutDM.trans : '';
				const proposed_final_star = proposedStar
					? priority_eol.eol + non_priority_eol.eol - revisedRunoutStar.trans
					: '';

				return {
					Kiosk: key,
					Server: value.server_id,
					'Tech ID': value.tech_id,
					'Paper Changer': assigned_paper ? assigned_paper.tech : value.paper_changer,
					'Daily Avg Transactions': average_txn.toFixed(2),
					'Job Assigned': assigned_paper ? assigned_paper.paper : '',
					'Current Transactions': value.current_total,
					'Projected Transactions (End of Month)': cur_total,
					'Extra Star Rolls': value.extra_rolls,
					'Star Count': value.star_count,
					'Star Install Date':
						value.star_count > 0
							? getInstallDate(starRunouts[`under_${env.STICKER_THRESH[0]}`], average_txn)
							: 'RAN OUT',
					[`Star < ${env.STICKER_THRESH[2]}`]:
						value.star_count > 0 ? starRunouts[`under_${env.STICKER_THRESH[2]}`] : 'RAN OUT',
					[`Star < ${env.STICKER_THRESH[1]}`]:
						value.star_count > 0 ? starRunouts[`under_${env.STICKER_THRESH[1]}`] : 'RAN OUT',
					[`Star < ${env.STICKER_THRESH[0]}`]:
						value.star_count > 0 ? starRunouts[`under_${env.STICKER_THRESH[0]}`] : 'RAN OUT',
					'Star Runout (days)': star_runout === DEFAULT_RUNOUT ? '' : star_runout,
					' ': '',
					[`${value.dm1_type} Count`]: value.dm1_count,
					[`${value.dm1_type} Install Date`]:
						value.dm1_count > 0
							? getInstallDate(
									value.dm1_type == priority_sticker
										? priorityRunouts[`under_${env.STICKER_THRESH[0]}`]
										: nonPriorityRunouts[`under_${env.STICKER_THRESH[0]}`],
									average_txn
							  )
							: 'RAN OUT',
					[`${value.dm1_type} < ${env.STICKER_THRESH[2]}`]:
						value.dm1_count > 0 && value.dm1_type == priority_sticker
							? priorityRunouts[`under_${env.STICKER_THRESH[2]}`]
							: value.dm1_count > 0 && value.dm1_type != priority_sticker
							? nonPriorityRunouts[`under_${env.STICKER_THRESH[2]}`]
							: 'RAN OUT',
					[`${value.dm1_type} < ${env.STICKER_THRESH[1]}`]:
						value.dm1_count > 0 && value.dm1_type == priority_sticker
							? priorityRunouts[`under_${env.STICKER_THRESH[1]}`]
							: value.dm1_count > 0 && value.dm1_type != priority_sticker
							? nonPriorityRunouts[`under_${env.STICKER_THRESH[1]}`]
							: 'RAN OUT',
					[`${value.dm1_type} < ${env.STICKER_THRESH[0]}`]:
						value.dm1_count > 0 && value.dm1_type == priority_sticker
							? priorityRunouts[`under_${env.STICKER_THRESH[0]}`]
							: value.dm1_count > 0 && value.dm1_type != priority_sticker
							? nonPriorityRunouts[`under_${env.STICKER_THRESH[0]}`]
							: 'RAN OUT',
					[`${value.dm1_type} Runout (days)`]: dm1_runout === DEFAULT_RUNOUT ? '' : dm1_runout,
					[`${value.dm1_type} End of Life`]:
						value.dm1_type == priority_sticker ? priority_eol.eol - TEST_PRINTS : non_priority_eol.eol - TEST_PRINTS,
					[`${value.dm1_type} End of Life + ${TEST_PRINTS} Test Prints`]:
						value.dm1_type == priority_sticker ? priority_eol.eol : non_priority_eol.eol,
					[`${value.dm1_type} DM1 Count - Change at ${TEST_PRINTS}`]: dm1_diff,
					[`${value.dm1_type} Stickers to Send (Y - Z)`]: !dm1_to_send
						? ''
						: value.dm1_type == priority_sticker && extra_prio
						? ''
						: value.dm1_type != priority_sticker && extra_cur
						? ''
						: dm1_final,
					[`${value.dm1_type} Extra Stickers`]: value.dm1_type == priority_sticker ? extra_prio : extra_cur,
					'  ': '',
					[`${value.dm2_type} Count`]: value.dm2_count,
					[`${value.dm2_type} Install Date`]:
						value.dm2_count > 0
							? getInstallDate(
									value.dm2_type == priority_sticker
										? priorityRunouts[`under_${env.STICKER_THRESH[0]}`]
										: nonPriorityRunouts[`under_${env.STICKER_THRESH[0]}`],
									average_txn
							  )
							: 'RAN OUT',
					[`${value.dm2_type} < ${env.STICKER_THRESH[2]}`]:
						value.dm2_count > 0 && value.dm2_type == priority_sticker
							? priorityRunouts[`under_${env.STICKER_THRESH[2]}`]
							: value.dm2_count > 0 && value.dm2_type != priority_sticker
							? nonPriorityRunouts[`under_${env.STICKER_THRESH[2]}`]
							: 'RAN OUT',
					[`${value.dm2_type} < ${env.STICKER_THRESH[1]}`]:
						value.dm2_count > 0 && value.dm2_type == priority_sticker
							? priorityRunouts[`under_${env.STICKER_THRESH[1]}`]
							: value.dm2_count > 0 && value.dm2_type != priority_sticker
							? nonPriorityRunouts[`under_${env.STICKER_THRESH[1]}`]
							: 'RAN OUT',
					[`${value.dm2_type} < ${env.STICKER_THRESH[0]}`]:
						value.dm2_count > 0 && value.dm2_type == priority_sticker
							? priorityRunouts[`under_${env.STICKER_THRESH[0]}`]
							: value.dm2_count > 0 && value.dm2_type != priority_sticker
							? nonPriorityRunouts[`under_${env.STICKER_THRESH[0]}`]
							: 'RAN OUT',
					[`${value.dm2_type} Runout (days)`]: dm2_runout === DEFAULT_RUNOUT ? '' : dm2_runout,
					[`${value.dm2_type} End of Life`]:
						value.dm2_type == priority_sticker ? priority_eol.eol - TEST_PRINTS : non_priority_eol.eol - TEST_PRINTS,
					[`${value.dm2_type} End of Life + ${TEST_PRINTS} Test Prints`]:
						value.dm2_type == priority_sticker ? priority_eol.eol : non_priority_eol.eol,
					[`${value.dm2_type} DM2 Count - Change at ${TEST_PRINTS}`]: dm2_diff,
					[`${value.dm2_type} Stickers to Send (AK - AL)`]: !dm2_to_send
						? ''
						: value.dm2_type == priority_sticker && extra_prio
						? ''
						: value.dm2_type != priority_sticker && extra_cur
						? ''
						: dm2_final,
					[`${value.dm2_type} Extra Stickers`]: value.dm2_type == priority_sticker ? extra_prio : extra_cur,
					' ': ' ',
					'Current Trend':
						projected_daily - value.current_total < 0
							? 'UP'
							: projected_daily - value.current_total > 0
							? 'DOWN'
							: 'FLAT',
					[`Earliest Count Below ${env.STICKER_THRESH[0]}`]: earliest_runout.date,
					star_runout: star_runout,
					dm1_runout: dm1_runout,
					dm2_runout: dm2_runout,
					printer1_year: value.dm1_type,
					printer2_year: value.dm2_type,
					star_projected: star_projected, // date based off of
					dm1_projected: dm1_projected, // date based off of
					dm2_projected: dm2_projected, // date based off of
					priority: priority_sticker,
					address: value.address.replace(/_/g, "'"),
					city: value.city.replace(/_/g, "'"),
					state: value.state,
					zip: value.zip,
					Latitude: value.latitude,
					Longitude: value.longitude,
					due_date: DateToMDY(due_date),
					ddp: DateToMDY(ddp),
					proposed: proposed_final_dm, // returns a number
					proposed_star: proposed_final_star,
					dm_remainder: revisedRunoutDM.remainder, // returns
					lowest_count: lowest_sticker_count,
				};
			})
			.filter(
				(paper) =>
					paper[`${paper.printer1_year} < ${env.STICKER_THRESH[2]}`] || // filter Datamax 1 < 60
					paper[`${paper.printer1_year} < ${env.STICKER_THRESH[1]}`] || // filter projected runout date Datamax 1 < 40
					paper[`${paper.printer2_year} < ${env.STICKER_THRESH[2]}`] || // filter Datamax 2 < 60
					paper[`${paper.printer2_year} < ${env.STICKER_THRESH[1]}`] || // filter Datamax 2 < 40
					paper[`Star < ${env.STICKER_THRESH[2]}`] || // filter Star Paper < 60
					paper[`Star < ${env.STICKER_THRESH[1]}`] || // filter Star Paper < 40
					paper[`Star < ${env.STICKER_THRESH[0]}`] || // filter Star Paper < 30
					paper[`${paper.printer1_year} Count`] <= env.STICKER_THRESH[1] || // filter Datamax 1 <= 60
					paper[`${paper.printer2_year} Count`] <= env.STICKER_THRESH[1] || // filter Datamax 2 <= 60
					paper['Star Count'] <= env.STICKER_THRESH[1]
			) // filter Star Paper <= 60
			.sort(
				(a, b) =>
					a.lowest_count - b.lowest_count ||
					(a[`Earliest Count Below ${env.STICKER_THRESH[0]}`]
						? new Date(a[`Earliest Count Below ${env.STICKER_THRESH[0]}`]).getTime()
						: Infinity) -
						(b[`Earliest Count Below ${env.STICKER_THRESH[0]}`]
							? new Date(b[`Earliest Count Below ${env.STICKER_THRESH[0]}`]).getTime()
							: Infinity) ||
					b['Daily Avg Transactions'] - a['Daily Avg Transactions']
			);
	} catch (error) {
		console.error(_file, getPaperProjections.name, error, ` on ${new Date()}`);
		return [];
	}
};


export const getDueDate = () => {
	const due_date = new Date();
	cur_date >= DUE_DATE ? due_date.setMonth(due_date.getMonth() + 1) : due_date.setMonth(due_date.getMonth());

	if (cur_date == last_day_of_month) due_date.setDate(0);
	due_date.setDate(DUE_DATE);

	return due_date;
};

export const getDailyProjections = (
	_priorityProjections = [],
	_nonPriorityProjections = [],
	_starProjections = [],
	key
) => {
	// check last day of month and get corresponding daily percentages
	const { current, nextMonth, nextPlus } = getDailyWeights();
	// get remaining projections for this sticker year
	const priorityMonthly = _priorityProjections.slice(cur_month);
	// get remaining projections for this sticker year
	const nonPriorityMonthly = _nonPriorityProjections.slice(cur_month);
	const starMonthly = _starProjections.slice(cur_month);
	// priority sticker
	const curDailyPrio = current
		.slice(cur_date > 0 ? cur_date - 1 : 0)
		.map((daily) => (daily / 100) * priorityMonthly[0]);
	const nextDailyPrio = nextMonth.map((daily) => (daily / 100) * priorityMonthly[1]);
	// ndapPrio =  Next Daily Average Plus Priority
	const ndapPrio = nextPlus.map((daily) => (daily / 100) * priorityMonthly[2]);
	// non priority sticker
	const curDailyNonPrio = current
		.slice(cur_date > 0 ? cur_date - 1 : 0)
		.map((daily) => (daily / 100) * nonPriorityMonthly[0]);
	const nextDailyNonPrio = nextMonth.map((daily) => (daily / 100) * nonPriorityMonthly[1]);
	// ndapNonPrio = Next Daily Average Plus Non-Priority
	const ndapNonPrio = nextPlus.map((daily) => (daily / 100) * nonPriorityMonthly[2]);
	// star
	const curStar = current.slice(cur_date > 0 ? cur_date - 1 : 0).map((daily) => (daily / 100) * starMonthly[0]);
	const nextStar = nextMonth.map((daily) => (daily / 100) * starMonthly[1]);
	// ndapStar = Next Daily Average Plus Star
	const ndapStar = nextPlus.map((daily) => (daily / 100) * starMonthly[2]);
	return {
		priority: [...curDailyPrio, ...nextDailyPrio, ...ndapPrio],
		non_priority: [...curDailyNonPrio, ...nextDailyNonPrio, ...ndapNonPrio],
		star: [...curStar, ...nextStar, ...ndapStar],
	};
};

const getDailyWeights = () => {
	// LAST DAY OF CURRENT MONTH
	const cur_dailys =
		last_day_of_month === 30
			? env.DAILY_PAPER_AVG_30
			: last_day_of_month === 29
			? env.DAILY_PAPER_AVG_29
			: last_day_of_month === 28
			? env.DAILY_PAPER_AVG_28
			: env.DAILY_PAPER_AVG_31;

	// LDNM = LAST DAY OF NEXT MONTH 30 DAYS OUT
	const next_dailys =
		ldnm === 30
			? env.DAILY_PAPER_AVG_30
			: ldnm === 29
			? env.DAILY_PAPER_AVG_29
			: ldnm === 28
			? env.DAILY_PAPER_AVG_28
			: env.DAILY_PAPER_AVG_31;

	// NDAP = NEXT DAILY AVERAGE PAPER 60 DAYS OUT
	const ndap =
		ldnmp === 30
			? env.DAILY_PAPER_AVG_30
			: ldnmp === 29
			? env.DAILY_PAPER_AVG_29
			: ldnmp === 28
			? env.DAILY_PAPER_AVG_28
			: env.DAILY_PAPER_AVG_31;

	return {
		current: cur_dailys,
		nextMonth: next_dailys,
		nextPlus: ndap,
	};
};

/**
 * Method that returns projected dates when a sticker year is under 60, 40, and 30 count respectively
 * @param {array} _dailyTransactions - array of projected daily transactions for this kiosk
 * @param {number} _curPrinterCount - current number of stickers left in a printer for this kiosk
 * @param {string} key - kiosk id string
 * @returns {object} object of strings indicating the dates in which this sticker year is under 60, 40, and 30 count
 */
const getStickerRunout = (_dailyTransactions = [], _curPrinterCount = 0, key = '') => {
	try {
		// remove daily projections up to today
		const remainingProjections = _dailyTransactions;
		const tempDate = new Date();
		let remainingCount = _curPrinterCount;
		let runouts = {
			[`under_${env.STICKER_THRESH[0]}`]: '',
			[`under_${env.STICKER_THRESH[1]}`]: '',
			[`under_${env.STICKER_THRESH[2]}`]: '',
		};

		for (let i = 0; i < remainingProjections.length; i++) {
			remainingCount -= remainingProjections[i];
			if (remainingCount <= env.STICKER_THRESH[2] && remainingCount > env.STICKER_THRESH[1]) {
				runouts[`under_${env.STICKER_THRESH[2]}`] = DateToMDY(tempDate);
			} else if (remainingCount <= env.STICKER_THRESH[1] && remainingCount > env.STICKER_THRESH[0]) {
				runouts[`under_${env.STICKER_THRESH[1]}`] = DateToMDY(tempDate);
			} else if (remainingCount <= env.STICKER_THRESH[0]) {
				runouts[`under_${env.STICKER_THRESH[0]}`] = DateToMDY(tempDate);
				break; // break out of for loop, no need to continue
			}
			// increment day
			tempDate.setDate(tempDate.getDate() + 1);
		}
		return runouts;
	} catch (error) {
		console.error(_file, getStickerRunout.name, error, ` on ${new Date()}`);

		return {
			[`under_${env.STICKER_THRESH[0]}`]: '',
			[`under_${env.STICKER_THRESH[1]}`]: '',
			[`under_${env.STICKER_THRESH[2]}`]: '',
		};
	}
};

export const averageKioskTxn = (trends = []) => {
	// get a sum of all transactions across each month for all kiosks
	const temp_average = trends.reduce((arr, kiosk, index) => {
		if (!index) {
			// push first set of values
			return [...kiosk.trends];
		}
		return kiosk.trends.reduce((total, txn, idx) => {
			total[idx] = arr[idx] + txn;
			return total;
		}, []);
	}, []);
	// get average transactions accross all kiosks to use for newer kiosks that have not developed a trend yet at their current location.
	return temp_average.map((sum) => Math.round(sum / trends.length) * 0.75);
};

/**
 * Method that finds open paper jobs and the paper types that are to be installed by kiosk.
 * @param {array} paper_jobs - array of open paper jobs
 * @param {string} kiosk - kiosk to search open paper jobs for
 * @returns {object} if there is an open paper job for this kiosk it will return the paper types that were assigned otherwise it will return NULL
 */
export const getOpenPaperJobs = (paper_jobs = [], kiosk = '') => {
	try {
		const open_paper_job = paper_jobs.filter((kiosks) => kiosks.includes(kiosk));
		if (open_paper_job.length > 1) {
			// there is more than one job that matches this kiosk
			let assigned_paper = null;
			let index_found = 0;
			open_paper_job.forEach((job, index) => {
				const open_job = JSON.parse(job.open_jobs).find(({ KioskID }) => KioskID === kiosk);
				assigned_paper =
					open_job && open_job.KioskStatus == 'Open'
						? open_job.Type.map((paper) => (!paper.Completed ? `${paper.PaperType}` : '')).join(' | ')
						: null;
				index_found = assigned_paper ? index : 0;
			});
			return assigned_paper
				? {
						paper: assigned_paper,
						tech: open_paper_job[index_found].paper_changer,
				  }
				: assigned_paper;
		} else {
			const open_jobs = open_paper_job.length
				? JSON.parse(open_paper_job[0].open_jobs).find(({ KioskID }) => KioskID === kiosk)
				: null;
			const assigned_paper =
				open_jobs && open_jobs.KioskStatus == 'Open'
					? open_jobs.Type.map((paper) => (!paper.Completed ? `${paper.PaperType}` : '')).join(' | ')
					: null;
			return assigned_paper ? { paper: assigned_paper, tech: open_paper_job[0].paper_changer } : assigned_paper;
		}
	} catch (error) {
		return null;
	}
};

/**
 * Method to calculate the number of days left until a sticker year runs out.
 * @param {number} avg_txn - average transactions per day
 * @param {number} next_projection - next months total projected transactions
 * @param {string} paper_type - sticker year type
 * @param {number} sticker_count - current sticker year count in printer
 * @returns {number} number of days until runout.  Will return empty string if days are greater than last day of next month
 */

const getPaperRunout = (avg_txn = 0, next_projection = 0, paper_type = '', sticker_count = 0) => {
	try {
		const cur_year = new Date().getFullYear();
		const days_left = GetLastDayOfMonth(1) - new Date().getDate();
		const txn_left = Math.round(days_left * avg_txn);
		const days_next_month = GetLastDayOfMonth(2);
		const next_avg_txn = next_projection / days_next_month;

		if (cur_year == paper_type) {
			// not priority sticker
			return DEFAULT_RUNOUT;
		} else if (txn_left < sticker_count) {
			const stickers_left = sticker_count - txn_left;
			const days_until_out = Math.round(stickers_left / next_avg_txn);
			return days_until_out > days_next_month ? DEFAULT_RUNOUT : days_until_out + days_left;
		} else if (txn_left >= sticker_count) {
			return Math.round(sticker_count / avg_txn);
		}
	} catch (error) {
		console.error(`ERROR: PaperUtil.getPaperRunout: ${error} on ${new Date()}`);
		return DEFAULT_RUNOUT;
	}
};

/**
 * Method that gets the earliest sticker runout date between the two priority stickers.
 * Priority stickers are determined by which stickers are the newest along with star paper.
 * @param {object} runout_dates - object of runout dates between all sticker years in a kiosk.
 * @param {string} dm1_type - sticker year that is in Datamax 1
 * @returns {object} object with the earliest timestamp and runout date between sticker years in the form YYYY-MM-DD
 */
const getEarliestRunout = (runout_dates = {}, dm1_type = '') => {
	try {
		const cur_year = new Date().getFullYear();

		const non_priority_sticker = dm1_type == cur_year + 1 || dm1_type == cur_year ? 'dm1' : 'dm2'; // set old sticker year as non-priority

		return Object.entries(runout_dates).reduce(
			(earliest, obj) => {
				const key = obj[0].split('_')[0]; // returns dm1 or dm2

				const value = obj[1]; // returns under 30, 40, 60 date projection

				if (value[`under_${env.STICKER_THRESH[0]}`] && key != non_priority_sticker) {
					// determined based on sticker year priority
					return new Date(value[`under_${env.STICKER_THRESH[0]}`]).getTime() < earliest.val
						? {
								val: new Date(value[`under_${env.STICKER_THRESH[0]}`]).getTime(),
								date: value[`under_${env.STICKER_THRESH[0]}`],
						  }
						: { ...earliest };
				}
				return earliest;
			},
			{ val: Infinity, date: '' }
		);
	} catch (error) {
		console.error(`ERROR: PaperUtil.getEarliestRunout: ${error} on ${new Date()}`);
		return { val: '', date: '' };
	}
};

/**
 * Method that gets the monthly transaction percentage for a sticker year.
 * @param {string} paper_type - sticker year or star
 * @param {date} current_date - todays date
 * @returns {object} object that contains the current and next months transaction percentage
 */
export const getStickerMultiplier = (paper_type = '', current_date = new Date()) => {
	try {
		const _months = cur_year % 2 === 0 ? env.PRIMARY_EVEN_STICKER_YEAR : env.PRIMARY_ODD_STICKER_YEAR;
		const cur_year = current_date.getFullYear() + 1;
		const cur_month = current_date.getMonth();

		const next_month = new Date(current_date.toString());
		next_month.setMonth(next_month.getMonth() + 1);

		const next_month_plus = new Date(current_date.toString());
		next_month_plus.setMonth(next_month_plus.getMonth() + 2);

		const cur_motor_year_end = env.RUNOUT_EVEN_STICKER_YEAR[cur_month] / 100;
		const next_motor_year_end = env.RUNOUT_EVEN_STICKER_YEAR[next_month.getMonth()] / 100;

		// NEXT MOTOR YEAR PLUS what is this/what does it return???
		const nmyp = env.RUNOUT_EVEN_STICKER_YEAR[next_month_plus.getMonth()] / 100;

		const cur_non_motor_year_end = env.RUNOUT_ODD_STICKER_YEAR[cur_month] / 100;
		const next_non_motor_year_end = env.RUNOUT_ODD_STICKER_YEAR[next_month.getMonth()] / 100;

		// NEXT NON MOTOR YEAR END PLUS what is this/what does it return???
		const nnmyep = env.RUNOUT_ODD_STICKER_YEAR[next_month_plus.getMonth()] / 100;
		const cur_motor_paper_avg = env.EARLY_EVEN_STICKER_YEAR[cur_month] / 100;
		const next_motor_paper_avg = env.EARLY_EVEN_STICKER_YEAR[next_month.getMonth()] / 100;

		// NON MOTOR PAPER AVERAGE PLUS what is this/what does it return???
		const nmpap = env.EARLY_EVEN_STICKER_YEAR[next_month_plus.getMonth()] / 100;
		const motor_year_start = env.EARLY_ODD_STICKER_YEAR[cur_month] / 100;
		const next_motor_year_start = env.EARLY_ODD_STICKER_YEAR[next_month] / 100;

		// NEXT MOTOR YEAR START PLUS what is this/what does it return???
		const nmysp = env.EARLY_ODD_STICKER_YEAR[next_month_plus] / 100;
		const cur_paper_avg = _months[cur_month] / 100;
		const next_paper_avg = _months[next_month.getMonth()] / 100;

		// NEXT PAPER AVERAGE PLUS what is this/what does it return???
		const npap = _months[next_month_plus.getMonth()] / 100;
		let multipliers = { current: 0, next: 0, next_plus: 0 };

		if (cur_year % 2 === 0) {
			// motorcycle year
			if (cur_year == paper_type) {
				// old stickers
				multipliers.current = cur_motor_year_end;
				multipliers.next = next_month.getFullYear() == cur_year ? next_motor_year_end : 0;
				multipliers.next_plus = next_month_plus.getFullYear() == cur_year ? nmyp : 0;
			} else if (cur_year + 2 == paper_type) {
				// 2 year stickers
				multipliers.current = cur_motor_paper_avg;
				multipliers.next = next_month.getFullYear() == cur_year ? next_motor_paper_avg : next_paper_avg;
				multipliers.next_plus = next_month_plus.getFullYear() == cur_year ? nmpap : npap;
			} else {
				// new stickers
				multipliers.current = cur_paper_avg;
				multipliers.next = next_month.getFullYear() == cur_year ? next_paper_avg : next_non_motor_year_end;
				multipliers.next_plus = next_month_plus.getFullYear() == cur_year ? npap : nnmyep;
			}
		} else {
			// non-motorcycle year
			if (cur_year == paper_type) {
				// old stickers
				multipliers.current = cur_non_motor_year_end;
				multipliers.next = next_month.getFullYear() == cur_year ? next_non_motor_year_end : 0;
				multipliers.next_plus = next_month_plus.getFullYear() == cur_year ? nnmyep : 0;
			} else if (cur_year + 2 == paper_type) {
				// 2 year stickers
				multipliers.current = motor_year_start;
				multipliers.next = next_month.getFullYear() == cur_year ? next_motor_year_start : next_paper_avg;
				multipliers.next_plus = next_month_plus.getFullYear() == cur_year ? nmysp : npap;
			} else {
				// new stickers
				multipliers.current = cur_paper_avg;
				multipliers.next = next_month.getFullYear() == cur_year ? next_paper_avg : next_motor_year_end;
				multipliers.next_plus = next_month_plus.getFullYear() == cur_year ? npap : nmyp;
			}
		}
		return multipliers;
	} catch (error) {
		console.error(`ERROR: PaperUtil.getStickerMultiplier: ${error} on ${new Date()}`);
		return { current: 0, next: 0, next_plus: 0 };
	}
};

export const calcRunoutByDate = (
	_dailys = [],
	cur_date = new Date(),
	due_date = new Date(),
	cur_total = 0,
	key = ''
) => {
	try {
		let temp_date = new Date(cur_date);
		let _dueDate = new Date(due_date);
		_dueDate.setMonth(_dueDate.getMonth() + 1);

		let temp_total = 0;
		let temp_remain = cur_total;
		let transactions = 0;

		for (let i = 0; i < _dailys.length; i++) {
			if (temp_date.getTime() > _dueDate.getTime() || temp_remain < 0) {
				break;
			} else {
				temp_total += _dailys[i];
				temp_remain -= _dailys[i];

				if (DateToMDY(temp_date) == DateToMDY(due_date)) {
					// save the count on proposed install date
					transactions = temp_total;
				}
				temp_date.setDate(temp_date.getDate() + 1); // increase date
			}
		}

		return {
			total: Math.round(temp_remain), // remaining counts in kiosk 2 months out
			trans: Math.round(transactions), // transactions through proposed due_date
			remainder: Math.round(cur_total - transactions), // remaining counts in printer through proposed due_date
			date: DateToMDY(temp_date), // projections cutoff date
			complete_trans: Math.round(temp_total), // total number of transactions between now and 2 months out OR count === 0 (whichever comes first)
		};
	} catch (error) {
		console.error(_file, calcRunoutByDate.name, error, ` on ${new Date()}`);
		return {
			total: null,
			trans: null,
			remainder: null,
			date: null,
			complete_trans: null,
		};
	}
};

/**
 * Method that is used to get the install date of a paper change.
 * @param {string} date - date when paper is below 20 count. Must be in the form YYYY-MM-DD
 * @param {number} daily_avg - average daily transactions for the kiosk
 * @returns {string} paper install date in the form of MM/DD/YYYY
 */
export const getInstallDate = (date = '', daily_avg = 0) => {
	try {
		if (date) {
			const cur_month = new Date().getMonth() + 1;
			const [y, m, d] = date.split('-');
			const new_date = new Date(`${m}/${d}/${y}`);
			const last_day = new_date.getMonth() + 1 === cur_month ? GetLastDayOfMonth(1) : GetLastDayOfMonth(2);

			if (new_date.getDate() < last_day && new_date.getDate() > 2) {
				// is date before the day prior to the last day of the month
				return installDates(new_date, -1);
			} else if (new_date.getDate() === 1) {
				if (daily_avg > 7) {
					return installDates(new_date, -3);
				} else {
					return installDates(new_date, 2);
				}
			} else if (new_date.getDate() === 2) {
				if (daily_avg > 7) {
					return installDates(new_date, -4);
				} else {
					return installDates(new_date, 2);
				}
			} else if (new_date.getDate() === last_day) {
				return installDates(new_date, -2);
			}
		}

		return '';
	} catch (error) {
		console.error(`ERROR: PaperUtil.getInstallDate: ${error} on ${new Date()}`);
		return '';
	}
};


const installDates = (date = new Date(), before_sub = 0) => {
	try {
		date.setDate(date.getDate() + before_sub);
		const before = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
		date.setDate(date.getDate() - 1);
		const after = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
		return `${after}`;
	} catch (error) {
		console.error(`ERROR: PaperUtil.installDates: ${error} on ${new Date()}`);
		return '';
	}
};

/**
 * The Primary "sticker" year is explained below
 * (EVEN CALENDAR YEAR = MOTORCYCLE TREND | ODD CALENDAR YEAR = STANDARD TREND)
 * @param {date} _stickerYear - 4 DIGIT YEAR DATE FOR DATAMAX 1 & DATAMAX 2
 * @param {object} _projections - CONTAINS PROJECTION DATA FROM PAPER.JS projectionFormula METHOD
 * @param {number} _newProjection - returns a percentage
 * @param {object} key - KIOSK ASSOCIATED WITH PROJECTION
 * @returns {object} - WEIGHTED PROJECTIONS FOR UPCOMING YEAR
 */
export const eolProjection = (_stickerYear, _projections, _newProjection, key) => {
	try {
		// end_weights: REMAINING WEIGHTS FOR THE CURRENT "STICKER" YEARS LIFE MINUS THE FIRST 2 ELEMENTS/MONTHS
		const end_weights =
			_stickerYear % 2 === 0 ? env.RUNOUT_EVEN_STICKER_YEAR.slice(2) : env.RUNOUT_ODD_STICKER_YEAR.slice(2);

		// cur_weights; returns sticker year array associated with its trend from the env file
		const cur_weights = calcWeights(_stickerYear);

		// eoy_weights: WEIGHTS FOR THE CURRENT "STICKER" YEAR AT THE END OF ITS LIFE
		const eoy_weights = _stickerYear % 2 === 0 ? env.RUNOUT_EVEN_STICKER_YEAR : env.RUNOUT_ODD_STICKER_YEAR;

		const cur_month = new Date();

		// checks the current "calendar" year to check the "primary sticker" year
		const dm_count = _stickerYear == _projections.dm1_type ? _projections.dm1_count : _projections.dm2_count;
		let newProjection = 0;
		// iterate through each month projections
		/**
		 * @param {proj} proj - ??? projection based on kiosk transactions historical data (24 months)
		 * @param {number} idx - months of the year
		 * @return {number} weightedProjections based on newProjections data
		 */

		const curProjections = _projections.trends.map((proj, idx) => {
			// if current transaction count is more than projected, recalculate current projection
			const weightedProjection = Math.round(proj * (cur_weights[idx] / 100));

			if (cur_month.getDate() >= 20) {
				if (idx == cur_month.getMonth() && _newProjection >= weightedProjection) {
					newProjection = Math.round(_newProjection); // get new projection based on current kiosk performance
					return newProjection;
				} else if (newProjection && idx > cur_month.getMonth()) {
					// raise the projections of future months
					newProjection = Math.round((newProjection + weightedProjection) / 2);
					return newProjection;
				}
			}

			return weightedProjection;
		}); // gets weighted projections for current sticker year

		/** These values display the information for the Jan - Mar YE(Year End) values */
		const mp1 = Math.round(_projections.trends[0] * (eoy_weights[0] / 100));
		const mp2 = Math.round(_projections.trends[1] * (eoy_weights[1] / 100));

		/** TODO :: define the unfamiliar values
		 * @param {number} total - ... might reprsent kiosk tansaction totals???
		 * @param {number} proj - returns a number
		 * @param {number} idx - returns a number
		 */
		const mp3 = Math.round(
			_projections.trends.slice(2).reduce((total, proj, idx) => {
				return total + proj * (end_weights[idx] / 100);
			}, 0)
		);

		curProjections.push(mp1); // returns
		curProjections.push(mp2); // returns
		curProjections.push(mp3); // returns

		const eol =
			curProjections.slice(cur_month.getMonth()).reduce((total, el) => total + el, 0) +
			TEST_PRINTS -
			_projections.current_total;

		return {
			final: eol - dm_count,
			projections: curProjections,
			eol,
			mp1,
			mp2,
			mp3,
		};
	} catch (error) {
		console.error(_file, eolProjection.name, error, ` on ${new Date()}`);
		return {
			final: 0,
			projections: [],
			eol: 0,
			mp1: 0,
			mp2: 0,
			mp3: 0,
		};
	}
};

export const calcWeights = (_stickerYear) => {
	const curYear = new Date().getFullYear();
	const diff = _stickerYear - curYear;

	return curYear % 2 == 0 && diff == 0
		? env.RUNOUT_EVEN_STICKER_YEAR
		: curYear % 2 == 0 && diff == 1
		? env.PRIMARY_EVEN_STICKER_YEAR
		: curYear % 2 == 0 && diff == 2
		? env.EARLY_EVEN_STICKER_YEAR
		: curYear % 2 != 0 && diff == 0
		? env.RUNOUT_ODD_STICKER_YEAR
		: curYear % 2 != 0 && diff == 1
		? env.PRIMARY_ODD_STICKER_YEAR
		: env.EARLY_ODD_STICKER_YEAR;
};

export const getStickerEOL = (sticker_year) => {
	return new Promise((resolve, reject) => {
		try {
			let today = new Date();

			socket.emit('kioskPaperTrends', ([trends, paper]) => {
				if (!trends) {
					console.error(`util.paper-util.getStickerEndOfLife: There was an issue calling this method`);
					resolve([]);
				} else {
					const eol = Object.entries(trends).map(([key, value]) => {
						let start = `${STICKER_START}/01/${new Date().getFullYear()}`;

						if (sticker_year == today.getFullYear() + 1 || sticker_year == today.getFullYear()) {
							start = `${(today.getMonth() + 1).toString().padStart(2, '0')}/01/${today.getFullYear()}`;
						} else if (sticker_year > today.getFullYear() + 1) {
							if (today.getMonth() + 1 > 10 && parseInt(sticker_year) - today.getFullYear() == 2) {
								start = `${today.getMonth() + 1}/01/${today.getFullYear()}`;
							} else {
								start = `${STICKER_START}/01/${parseInt(sticker_year) - 2}`; // 10/01/2021 as of 2023
							}
						}

						const end_date = new Date(`${STICKER_END}/01/${sticker_year}`);
						const total_transactions = EOLTransactions(start, end_date, value, sticker_year);
						const sticker_counts = sticker_year == value.dm1_type ? value.dm1_count : value.dm2_count;

						const padding = sticker_counts - TEST_PRINTS > 0 ? sticker_counts - TEST_PRINTS : 0;
						const leftover = Math.round(total_transactions) + TEST_PRINTS - padding;

						return {
							Kiosk: key,
							Server: value.server_id,
							'New Kiosk': value.new_kiosk ? 'TRUE' : '',
							'Current Printer Count': sticker_counts,
							'Projected End of Life': Math.round(total_transactions),
							'End of Life + 30 Test Prints': Math.round(total_transactions) + TEST_PRINTS,
							'Current Printer Count - Change at 30': padding,
							'Stickers to Send (F - G)': leftover > 0 ? leftover : '',
							'Extra Stickers': leftover < 0 ? Math.abs(leftover) : '',
						};
					});
					resolve(eol);
				}
			});
		} catch (error) {
			console.error(`ERROR: PaperUtil.getStickerEOL: ${error} on ${new Date()}`);
			resolve([]);
		}
	});
};

export const EOLTransactions = (start_date = '', end_date = new Date(), kiosk, sticker_year, txn_override) => {
	try {
		let today = new Date();
		let increase = sticker_year == today.getFullYear() + 1 || sticker_year == today.getFullYear() ? 1 : 1.08;
		let total_transactions = 0;

		if (txn_override) {
			start_date = `${STICKER_START}/01/${new Date().getFullYear()}`;

			if (sticker_year == today.getFullYear() + 1 || sticker_year == today.getFullYear()) {
				start_date = `${(today.getMonth() + 1).toString().padStart(2, '0')}/01/${today.getFullYear()}`;
			} else if (sticker_year > today.getFullYear() + 1) {
				// for 2023 and 2024
				if (today.getMonth() + 1 > 10 && parseInt(sticker_year) - today.getFullYear() == 2) {
					start_date = `${today.getMonth() + 1}/01/${today.getFullYear()}`;
				} else {
					start_date = `${STICKER_START}/01/${parseInt(sticker_year) - 2}`;
				}
			}

			end_date = new Date(`${STICKER_END}/01/${sticker_year}`); // Result is 4/01/2023
		}

		let current_date = new Date(start_date);

		if (current_date.getTime() < end_date.getTime() && current_date.getTime() != end_date.getTime()) {
			while (current_date.getTime() != end_date.getTime()) {
				const date = new Date(current_date.toString());
				const multiplier = getStickerMultiplier(sticker_year, date);
				const avg_multi = txn_override
					? txn_override * multiplier.current
					: kiosk.trends[current_date.getMonth()] * multiplier.current;
				const month_average = avg_multi;

				total_transactions += month_average;
				current_date.setMonth(current_date.getMonth() + 1);
			}

			total_transactions = total_transactions * increase;
		}

		return total_transactions + TEST_PRINTS;
	} catch (error) {
		console.error(`ERROR: PaperUtil.EOLTransactions: ${error} on ${new Date()}`);
		return 0;
	}
};
