import pool from './pool.js';
import path from 'path';
import axios from 'axios'; // Add axios import

const currentUrl = new URL(import.meta.url);
const _file = path.basename(currentUrl.pathname);

export const TechnicianLoginTrackerAPI = async (techName, timestampID, kioskID) => {
	try {
		const action = `CALL TechnicianLoginTrackerERD.TechnicianLoginTrackerAPI(?, ?, ?);`;
		const params = [techName, timestampID, kioskID];

		const response = await pool.query(action, params);
		return response[0];
	} catch (error) {
		console.error(`tableManagementTB.TechnicianLoginTrackerAPI: ${error.message} - ${new Date()}`);
		throw error;
	}
};

/**ALERTS**/
//Gets a List of information about each Alert
/**
 * Selects data from the alerts table for table management.
 * @returns - {Promise<any>} A promise that resolves with the response from the database query.
 * @throws {Error} If there is an error executing the database query.
 *
 * @method
 */
export const selectFromAlertsForTM = async () => {
	try {
		const readPendingAlerts = `CALL selectFromAlertsForTM();`;
		const response = await pool.query(readPendingAlerts);
		return response;
	} catch (error) {
		console.error(`tableManagementTB.selectFromAlertsForTM: ${error.message} - ${new Date()}`);
		throw error;
	}
};

/**
 * Deletes an alert for table management.
 * @param {number} AlertID - The ID of the alert to be deleted.
 * @returns - {Promise} A promise that resolves with the response from the database.
 * @throws {Error} If an error occurs while deleting the alert.
 *
 * @method
 */
export const deleteAlertForTM = async (AlertID) => {
	try {
		const statement = `CALL deleteAlertForTM(?)`;
		const response = await pool.query(statement, [AlertID]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.deleteAlertForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getUserPTO = async (_uid) => {
	try {
		const action = `CALL dispatch_console.getUserPTO(${_uid});`;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getUserPTO: ${error} - ${new Date()}`);
		throw error;
	}
};

export const updatePTOForAllUsers = async (_data) => {
	try {
		const action = `CALL updatePTOForAll(?);`;
		const response = await pool.query(action, [_data]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updatePTOForAllUsers: ${error} - ${new Date()}`);
		throw error;
	}
};

export const markKioskClean = async (_kiosk) => {
	try {
		const action = `CALL markKioskClean(?);`;
		const response = await pool.query(action, [_kiosk]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.markKioskClean: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectFromDispatchersForTM = async () => {
	try {
		const readPendingUsers = `CALL selectFromDispatchersForTM();`;

		const response = await pool.query(readPendingUsers);
		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectFromDispatchersForTM: ${error} - ${new Date()}`);
		throw new Error('Could not get dispatcher information');
	}
};
export const updateDispatcherForTM = async (dispatcherObject) => {
	try {
		const updateSolutionData = `
	  CALL updateDispatcherForTM(
	  ?,
	  ?,
	  ?,
	  ?,
	  ?,
	  ?,
	  ?,
	  ?,
	  ?,
	  ?,
	  ?,
	  ?
	  );`;
		const response = await pool.query(updateSolutionData, [
			dispatcherObject.UserID,
			dispatcherObject.Username,
			dispatcherObject.UserType,
			dispatcherObject.FirstName,
			dispatcherObject.LastName,
			dispatcherObject.FullName,
			dispatcherObject.PhoneNumber,
			dispatcherObject.Email,
			dispatcherObject.Birthday,
			dispatcherObject.AccrualType,
			dispatcherObject.PTO,
			dispatcherObject.AccrualRate,
		]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateDispatcherForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const deleteDispatcherForTM = async (UserID) => {
	try {
		const statement = `CALL RemoveDispatcherFromLists(?)`;
		const response = await pool.query(statement, [UserID]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.deleteDispatcherForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectFromKiosksIssuesForTM = async () => {
	try {
		const readPendingKiosksIssues = `SELECT * FROM kiosk_issues WHERE 1 = ?;`;
		const response = await pool.query(readPendingKiosksIssues, [1]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectFromKiosksIssuesForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const insertIssueForTM = async (obj) => {
	try {
		const statement = `
			INSERT INTO dispatch_console.kiosk_issues
			(ErrorCode, Description, ToDoNext)
			 VALUES(?, ?, ?);
		`;
		const response = await pool.query(statement, [obj.ErrorCode, obj.Description, JSON.stringify(obj.ToDoNext)]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.insertIssueForTM: ${error} - ${new Date()}`);
		throw error;
	}
};
export const updateIssueForTM = async (issueObject) => {
	try {
		const updateSolutionData = `UPDATE kiosk_issues
				SET
				KioskIssueID = ?,
				ErrorCode = ?,
				Description = ?,
				ToDoNext = ?
				WHERE KioskIssueID = ?
			`;
		const response = await pool.query(updateSolutionData, [
			issueObject.KioskIssueID,
			issueObject.ErrorCode,
			issueObject.Description,
			JSON.stringify(issueObject.ToDoNext),
			issueObject.KioskIssueID,
		]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateIssueForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const deleteKioskIssueForTM = async (KioskIssueID) => {
	try {
		const statement = `DELETE FROM dispatch_console.kiosk_issues WHERE KioskIssueID = ?`;
		const response = await pool.query(statement, [KioskIssueID]);
		return response;
	} catch (error) {
		console.error(`ERROR tableManagementTB.deleteKioskIssueForTM: ${error} - ${new Date()}`);
		throw error;
	}
};
export const selectFromJobsForTM = async () => {
	try {
		const readPendingJobs = `SELECT
			dispatch_console.jobs.JobID,
			dispatch_console.jobs.KioskID,
			dispatch_console.jobs.AlertID,
			dispatch_console.jobs.KioskIssueID,
			dispatch_console.jobs.CreatedDate,
			dispatch_console.jobs.AssignedDate,
			dispatch_console.jobs.CompletedDate,
			dispatch_console.jobs.Dispatcher,
			dispatch_console.jobs.EstimateTimeArrival,
			dispatch_console.jobs.JobStatus,
			dispatch_console.jobs.TechnicianID,
			dispatch_console.technicians.FirstName,
			dispatch_console.technicians.LastName,
			dispatch_console.kiosk_issues.Description,
			dispatch_console.kiosks.StoreCity,
			dispatch_console.kiosks.StoreName
		FROM jobs
		INNER JOIN dispatch_console.technicians
		ON dispatch_console.jobs.TechnicianID = dispatch_console.technicians.TechnicianID
		INNER JOIN dispatch_console.kiosk_issues
		ON dispatch_console.jobs.KioskIssueID = dispatch_console.kiosk_issues.KioskIssueID
		INNER JOIN dispatch_console.kiosks
		ON dispatch_console.jobs.KioskID = dispatch_console.kiosks.KioskID
		ORDER BY dispatch_console.jobs.CreatedDate DESC;`;
		const response = await pool.query(readPendingJobs);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectFromJobsForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectFromKiosksForTM = async () => {
	try {
		const readPendingKiosks = `SELECT * FROM kiosks`;
		const response = await pool.query(readPendingKiosks);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectFromKiosksForTM: ${error.message} - ${new Date()}`);
		throw new Error('Error: tableManagementTB.selectFromKiosksForTM');
	}
};

export const selectFromArchiveKiosksForTM = async () => {
	try {
		const selectArchives = `SELECT * FROM dispatch_console.kiosk_archive`;
		const response = await pool.query(selectArchives);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectFromArchiveKiosksForTM: ${error.message} - ${new Date()}`);
		throw new Error('Error: tableManagementTB.selectFromArchiveKiosksForTM');
	}
};
export const getKiosksWithAddressChange = async () => {
	try {
		const selectArchives = `CALL dispatch_console.getKiosksWithAddressChange();`;
		const response = await pool.query(selectArchives);
		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getKiosksWithAddressChange: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getJobsByTechID = async (_tid) => {
	try {
		const action = `CALL dispatch_console.getJobsByTechID(${_tid});`;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getJobsByTechID: ${error} - ${new Date()}`);
		throw error;
	}
};
export const getJobByJobID = async (_tid, _jid) => {
	try {
		const action = `CALL dispatch_console.getJobByJobID(${_tid},'${_jid}');`;
		const response = await pool.query(action);
		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getJobByJobID: ${error} - ${new Date()}`);
		throw error;
	}
};
export const updateEmailList = async (_list) => {
	try {
		const action = `CALL dispatch_console.updateEmailList(?);`;
		const response = await pool.query(action, JSON.stringify(_list));
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateEmailList: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getTechSolutions = async (_query) => {
	try {
		const action = `CALL dispatch_console.getTechSolutions('${_query}');`;
		const response = await pool.query(action);
		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getTechSolutions: ${error} - ${new Date()}`);
		throw error;
	}
};

export const addTechSolution = async (_data) => {
	try {
		const action = `CALL dispatch_console.addTechSolution(?,?);`;
		const response = await pool.query(action, [JSON.stringify(_data.title), JSON.stringify(_data.solution)]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.addTechSolution: ${error} - ${new Date()}`);
		throw error;
	}
};

export const editTechSolution = async (_data) => {
	try {
		const action = `UPDATE dispatch_console.tech_help set title = ?, solution = ? where id = ?;`;
		const response = await pool.query(action, [JSON.stringify(_data.title), JSON.stringify(_data.solution), _data.id]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.editTechSolution: ${error} - ${new Date()}`);
		throw error;
	}
};

export const removeTechSolution = async (_sid) => {
	try {
		const action = `DELETE FROM dispatch_console.tech_help WHERE id = ?;`;
		const response = await pool.query(action, [_sid]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.removeTechSolution: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getRTISolutions = async (_query) => {
	try {
		const action = `CALL dispatch_console.getRTISolutions('${_query}');`;
		const response = await pool.query(action);
		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getRTISolutions: ${error} - ${new Date()}`);
		throw error;
	}
};

export const addRTISolution = async (_data) => {
	try {
		const action = `CALL dispatch_console.addRTISolution(?,?);`;
		const response = await pool.query(action, [JSON.stringify(_data.title), JSON.stringify(_data.solution)]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.addRTISolution: ${error} - ${new Date()}`);
		throw error;
	}
};

export const editRTISolution = async (_data) => {
	try {
		const action = `update dispatch_console.rti_solutions set title = ?, solution = ? where id = ?;`;
		const response = await pool.query(action, [JSON.stringify(_data.title), JSON.stringify(_data.solution), _data.id]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.editRTISolution: ${error} - ${new Date()}`);
		throw error;
	}
};

export const removeRTISolution = async (_sid) => {
	try {
		const action = `delete from dispatch_console.rti_solutions where id = ?;`;
		const response = await pool.query(action, [_sid]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.removeRTISolution: ${error} - ${new Date()}`);
		throw error;
	}
};

export const updateKioskForTM = async (objt) => {
	try {
		const statement = `UPDATE kiosks 
            SET
            KioskID = ?,
            KioskID_Loc = ?,
            KioskInstallDate = ?,
            StoreName = ?,
            StoreHours = ?,
            StorePhone = ?,
			Latitude = ?,
			Longitude = ?,
            StoreAddress = ?,
            StoreCity = ?,
            StoreState = ?,
            StoreZip = ?,
            StoreCounty = ?,
            ServerID = ?,
            ModemType = ?,
            Carrier = ?,
            CarrierNumber = ?,
            PrimaryTechs = ?,
            BackupTechs = ?,
            PaperChanger = ?,
            Notes = ?,
            ActiveOrInactive = ?,
            has_switch = ?,
            paper_bridge = ?,
            cleaned = ?,
            star_firmware = ?,
            ski_slope = ?,
            os_installed = ?,
            NumberOfStarRolls = ?
            WHERE KioskID = ?;
            `;

		const params = [
			objt.KioskID,
			objt.KioskID_Loc,
			objt.KioskInstallDate,
			objt.StoreName,
			JSON.stringify(objt.StoreHours),
			objt.StorePhone,
			objt.Latitude,
			objt.Longitude,
			objt.StoreAddress,
			objt.StoreCity,
			objt.StoreState,
			objt.StoreZip,
			objt.StoreCounty,
			objt.ServerID,
			objt.ModemType,
			objt.Carrier,
			objt.CarrierNumber,
			JSON.stringify(objt.PrimaryTechs),
			JSON.stringify(objt.BackupTechs),
			objt.PaperChanger,
			objt.Notes,
			objt.ActiveOrInactive,
			objt.has_switch,
			objt.paper_bridge,
			objt.cleaned,
			objt.star_firmware,
			objt.ski_slope,
			objt.os_installed,
			objt.NumberOfStarRolls,
			objt.KioskID,
		];
		const response = await pool.query(statement, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateKioskForTM: ${error.message} - ${new Date()}`);
		throw error;
	}
};

export const getMonthlyTxnAvg = async () => {
	try {
		const action = `call dispatch_console.getMonthlyTxnAvg();`;
		const response = await pool.query(action);

		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getMonthlyTxnAvg: ${error} - ${new Date()}`);
		throw new Error('Error: tableManagementTB.getMonthlyTxnAvg');
	}
};

export const updateKioskMapping = async (list) => {
	try {
		for (let i = 0; i < list.length; i++) {
			const writeSolutionData = `UPDATE KioskMapping SET
                Customer = ?,
                Company = ?,
                MainPhone = ?,
                AltPhone = ?,
                Bill1 = ?,
                Bill2 = ?,
                Bill3 = ?,
                Ship1 = ?,
                Ship2 = ?,
                Ship3 = ?
                WHERE ID = ?;`;
			const response = await pool.query(writeSolutionData, [
				list[i].Customer.replace(/'/g, '_'),
				list[i].Company.replace(/'/g, '_'),
				list[i].MainPhone,
				list[i].AltPhone,
				list[i].Bill1.replace(/'/g, '_'),
				list[i].Bill2.replace(/'/g, '_'),
				list[i].Bill3.replace(/'/g, '_'),
				list[i].Ship1.replace(/'/g, '_'),
				list[i].Ship2.replace(/'/g, '_'),
				list[i].Ship3.replace(/'/g, '_'),
				list[i].ID,
			]);
			return response;
		}
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateKioskMapping: ${error} - ${new Date()}`);
		throw error;
	}
};

export const insertNewMapping = async (itemList) => {
	try {
		for (let i = 0; i < itemList.length; i++) {
			const writeSolutionData = `INSERT INTO dispatch_console.KioskMapping (Customer, Company, MainPhone, AltPhone, Bill1, Bill2, Bill3, Ship1, Ship2, Ship3) VALUES
                (?, ?, ?, ?, ?, ?, ?, ?, ?, ?);`;
			const response = await pool.query(writeSolutionData, [
				itemList[i].Customer.replace(/'/g, '_'),
				itemList[i].Company.replace(/'/g, '_'),
				itemList[i].MainPhone,
				itemList[i].AltPhone,
				itemList[i].Bill1.replace(/'/g, '_'),
				itemList[i].Bill2.replace(/'/g, '_'),
				itemList[i].Bill3.replace(/'/g, '_'),
				itemList[i].Ship1.replace(/'/g, '_'),
				itemList[i].Ship2.replace(/'/g, '_'),
				itemList[i].Ship3.replace(/'/g, '_'),
			]);
			return response;
		}
	} catch (error) {
		console.error(`ERROR: tableManagementTB.insertNewMapping: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectAllFromKioskMapping = async () => {
	try {
		const readPendingKiosksIssues = `SELECT * FROM KioskMapping;`;
		const response = await pool.query(readPendingKiosksIssues);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectAllFromKioskMapping: ${error} - ${new Date()}`);
		throw error;
	}
};

export const insertKioskForTM = async (obj) => {
	try {
		const statement = `INSERT INTO dispatch_console.kiosks
            (KioskID,
                KioskInstallDate,
                StoreName,
                StoreHours,
                StorePhone,
                StoreAddress,
                StoreCity,
                StoreState,
                StoreZip,
                StoreCounty,
                ServerID,
                ModemType,
                Carrier,
                CarrierNumber,
                PrimaryTechs,
                BackupTechs,
                PaperChanger,
                Notes,
                has_switch,
                paper_bridge,
                cleaned,
                star_firmware,
                ski_slope,
				os_installed,
                ActiveOrInactive,
                NumberOfStarRolls,
                Latitude,
                Longitude,
                KioskID_Loc)
            VALUES(?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?);`;

		const params = [
			obj.KioskID,
			obj.KioskInstallDate,
			obj.StoreName,
			JSON.stringify(obj.StoreHours),
			obj.StorePhone,
			obj.StoreAddress,
			obj.StoreCity,
			obj.StoreState,
			obj.StoreZip,
			obj.StoreCounty,
			obj.ServerID,
			obj.ModemType,
			obj.Carrier,
			obj.CarrierNumber,
			JSON.stringify(obj.PrimaryTechs),
			JSON.stringify(obj.BackupTechs),
			obj.PaperChanger,
			obj.Notes,
			obj.has_switch,
			obj.paper_bridge,
			obj.cleaned,
			obj.star_firmware,
			obj.ski_slope,
			obj.os_installed,
			obj.ActiveOrInactive,
			obj.NumberOfStarRolls,
			obj.Latitude,
			obj.Longitude,
			obj.KioskID_Loc,
		];
		const response = await pool.query(statement, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.insertKioskForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getEmailLists = async () => {
	try {
		const action = `call dispatch_console.getAllEmailLists();`;
		const response = await pool.query(action);
		return response[0];
	} catch (error) {
		console.error(`${_file},${getEmailLists.name},${error} - ${new Date()}`);
		throw error;
	}
};

export const createJobsBatch = async (data) => {
	try {
		const action = `CALL dispatch_console.createJobsBatch(?);`;
		const response = await pool.query(action, data);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.createJobsBatch: ${error} - ${new Date()}`);
		throw error;
	}
};

export const insertTechDriveTimes = async (list) => {
	try {
		const values = list
		.filter((item) => item !== undefined && item !== null && item.TechName) // Filter out undefined or null items
		.map((item) => {
			return `('${item.TechID}', '${item.TechName}', '${item.TechAddress}', '${item.KioskID}', '${item.DriveTime}', '${item.KioskID_Loc}')`;
		})
		.join(',');
		
		const driveTimes = list.map((item) => item.DriveTime).join(',');

		const writeSolutionData = `
		INSERT INTO dispatch_console.mapDriveTime
            ( TechID, TechName, TechAddress, KioskID, DriveTime, KioskID_Loc)
            VALUES ${values}
            ON DUPLICATE KEY UPDATE
                TechName = VALUES(TechName),
                TechAddress = VALUES(TechAddress),
                DriveTime = VALUES(DriveTime);
        `;

		const response = await pool.query(writeSolutionData);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.insertTechDriveTimes: ${error.message} - ${new Date()}`);
		throw error;
	}
};

export const updateTechDriveTimes = async (list) => {
	try {
		// using a promise.all to update all the drive times in parallel
		const responses = await Promise.all(
			list.map((item) => {
				const action = `UPDATE dispatch_console.mapDriveTime SET DriveTime = ? WHERE TechName = ? AND KioskID = ?;`;
				const params = [item.DriveTime, item.TechName, item.KioskID];
				return pool.query(action, params);
			})
		);

		return responses;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateTechDriveTimes: ${error.message} - ${new Date()}`);
		throw error;
	}
};

export const updateKioskTechs = async (primaryTechs, backupTechs, paperChanger, kioskId) => {
	try {
		const primaryTechsStr = JSON.stringify(primaryTechs.map((tech) => tech.replace(/['"]+/g, '')));
		const backupTechsStr = JSON.stringify(backupTechs.map((tech) => tech.replace(/['"]+/g, '')));
		const paperChangerTechStr = paperChanger.replace(/['"]+/g, '');
		const updateQuery = `
            UPDATE dispatch_console.kiosks
            SET PrimaryTechs = ?, BackupTechs = ?, PaperChanger = ?
            WHERE KioskID = ?;
        `;

		const response = await pool.query(updateQuery, [primaryTechsStr, backupTechsStr, paperChangerTechStr, kioskId]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateKioskTechs: ${error.message} - ${new Date()}`);
		throw error;
	}
};

export const updateKioskTechsBatch = async (kiosks) => {
	try {
		const action = `call dispatch_console.updateKioskTechsBatch(?);`;

		const params = [JSON.stringify(kiosks)];
		const response = await pool.query(action, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateKioskTechsBatch: ${error.message} - ${new Date()}`);
		throw error;
	}
};

export const updatePaperJobStatus = async (paper_jobs, job_id) => {
	try {
		const action = `UPDATE dispatch_console.paper_jobs
                SET Kiosks = '${paper_jobs.Jobs}',
                CompletedDate = if(${paper_jobs.Complete} = true, now(), NULL),
                JobStatus = if(${paper_jobs.Complete} = true,'Completed','Pending')
                WHERE PaperJobID = '${job_id}';
                `;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updatePaperJobStatus: ${error} - ${new Date()}`);
		throw error;
	}
};

export const archiveKioskForTM = async (obj) => {
	try {
		const statement = `INSERT INTO dispatch_console.kiosk_archive(
			KioskID,
			KioskInstallDate,
			StoreName,
			StoreHours,
			StorePhone,
			Latitude,
			Longitude,
			StoreAddress,
			StoreCity,
			StoreState,
			StoreZip,
			StoreCounty,
			ServerID,
			ModemType,
			Carrier,
			CarrierNumber,
			PrimaryTechs,
			BackupTechs,
			PaperChanger,
			Notes,
			has_switch,
			paper_bridge,
			cleaned,
			star_firmware,
			ski_slope,
			os_installed,
			ActiveOrInactive,
			NumberOfStarRolls,
			DateArchived,
			KioskID_Loc)
			VALUES(?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,NOW(),?);`;

		const params = [
			obj.KioskID,
			obj.KioskInstallDate,
			obj.StoreName,
			JSON.stringify(obj.StoreHours),
			obj.StorePhone,
			obj.Latitude,
			obj.Longitude,
			obj.StoreAddress,
			obj.StoreCity,
			obj.StoreState,
			obj.StoreZip,
			obj.StoreCounty,
			obj.ServerID,
			obj.ModemType,
			obj.Carrier,
			obj.CarrierNumber,
			JSON.stringify(obj.PrimaryTechs),
			JSON.stringify(obj.BackupTechs),
			obj.PaperChanger,
			obj.Notes,
			obj.has_switch,
			obj.paper_bridge,
			obj.cleaned,
			obj.star_firmware,
			obj.ski_slope,
			obj.os_installed,
			obj.ActiveOrInactive,
			obj.NumberOfStarRolls,
			obj.KioskID_Loc,
		];

		const response = await pool.query(statement, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.archiveKioskForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const insertDailyTransactions = async (kiosks) => {
	try {
		for (let i = 0; i < kiosks.length; i++) {
			const action = `INSERT INTO dispatch_console.transaction_history (kiosk_id,total_transactions,sticker_year,date)
                VALUES(
                    '${kiosks[i].kiosk_id}',
                    '${kiosks[i].count}',
                    '${kiosks[i].sticker_year}',
                    NOW()
                );`;
			const response = await pool.query(action, JSON.stringify(kiosks[i].transactions));
			return response;
		}
	} catch (error) {
		console.error(`${_file}, ${insertDailyTransactions.name}, ${error} - ${new Date()}`);
		throw error;
	}
};

export const getPaperTrendByKiosk = async (args) => {
	try {
		let year_list = `"${args.map((e) => `${e}`).join(',')}"`;
		const action = `call dispatch_console.GetPaperTrendByKiosk(${year_list});`;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getPaperTrendByKiosk: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getDTWithin = async (_tech, _distance) => {
	try {
		const action = `SELECT
                TechID AS tech_id,
                TechName AS tech_name,
                KioskID as kiosk_id,
                calcMapDriveTime(DriveTime) as dt,
                DriveTime as dt_orig
                FROM dispatch_console.mapDriveTime
                WHERE TechName = '${_tech}' having dt <= ${_distance};
            `;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getDTWithin: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getStickerEndOfLife = async (years, cur_proj) => {
	try {
		let year_list = `"${years.map((e) => `${e}`).join(',')}"`;
		const action = `call dispatch_console.getStickerEndOfLife(${year_list},${cur_proj});`;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getStickerEndOfLife: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getTechsWithinKiosk = async (coordinates, kiosk_id) => {
	try {
		const action = `call dispatch_console.GetTechsWithinKioskCoords(?, ?, ?);`;
		const params = [coordinates.latitude, coordinates.longitude, kiosk_id];

		const response = await pool.query(action, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getTechsWithinKiosk: ${error.message} - ${new Date()}`);
		throw new Error('Error retrieving technicians within kiosk');
	}
};

export const getSupportAfterHours = async (args) => {
	try {
		const action = `call dispatch_console.getAfterHourSupportLogs('${args.user}','${args.start_date}','${args.end_date}');`;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getSupportAfterHours: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getKiosksWithinTech = async (coordinates) => {
	try {
		const action = `call dispatch_console.GetKiosksWithinTechCoords(?, ?);`;

		const params = [coordinates.latitude, coordinates.longitude];
		const response = await pool.query(action, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getKiosksWithinTech: ${error.message} - ${new Date()}`);
		throw error;
	}
};

export const deleteKioskForTM = async (KioskID) => {
	try {
		const statement = `call dispatch_console.removeKiosk(?);`;
		const params = [KioskID];
		const response = await pool.query(statement, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.deleteKioskForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getKioskInfo = async (kiosk_id) => {
	try {
		const action = `SELECT * from dispatch_console.kiosks WHERE KioskID = '${kiosk_id}';`;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getKioskInfo: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectMobileEmailLists = async () => {
	try {
		const statement = `CALL SelectMobileEmailLists()`;
		const response = await pool.query(statement);

		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectMobileEmailLists: ${error.message} - ${new Date()}`);
		throw new Error('Error retrieving mobile and email lists');
	}
};

export const getMsgList = async () => {
	try {
		const action = `call dispatch_console.getMsgList();`;
		const response = await pool.query(action);
		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getMsgList: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getKiosksForTech = async (technician) => {
	try {
		const statement = `CALL GetKiosksForTech('${technician}');`;
		const response = await pool.query(statement);
		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getKiosksForTech: ${error} - ${new Date()}`);
		throw error;
	}
};

export const updateMobileEmailLists = async (obj) => {
	try {
		for (const [key, report] of obj) {
			const statement = `update dispatch_console.MobileEmailLists set MsgToList = ?, EmailToList = ? where ListName = '${key}';`;
			const response = await pool.query(statement, [
				JSON.stringify(report.MsgToList),
				JSON.stringify(report.EmailToList),
			]);
			return response.affectedRows;
		}
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateMobileEmailLists: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectTechSupportScheduleForTM = async () => {
	try {
		const statement = `SELECT * FROM dispatch_console.tech_support_schedule;`;
		const response = await pool.query(statement);

		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectTechSupportScheduleForTM: ${error} - ${new Date()}`);
		throw new Error('Error retrieving tech support schedule');
	}
};

export const getTechSupportByDay = async (day, current_time) => {
	try {
		let time = current_time;
		const [hours] = current_time.split(':');
		if (hours < 8) {
			time = '08:00:00';
		}
		const statement = `SELECT PhoneNumber
		FROM dispatch_console.users
		WHERE UserID = (SELECT ${
			day == 0
				? 'Sunday'
				: day == 1
				? 'Monday'
				: day == 2
				? 'Tuesday'
				: day == 3
				? 'Wednesday'
				: day == 4
				? 'Thursday'
				: day == 5
				? 'Friday'
				: 'Saturday'
		} AS user_id
		FROM dispatch_console.tech_support_schedule
		WHERE Time <= '${time}'
		ORDER BY ScheduleID desc limit 1);`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getTechSupportByDay: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getKioskIssuesByInterval = async (interval) => {
	try {
		const statement = `CALL dispatch_console.GetKioskIssuesByInterval(${interval});`;
		const response = await pool.query(statement);
		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getKioskIssuesByInterval: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectAlertsBetweenDates = async (after, before) => {
	try {
		const statement = `SELECT *
			FROM dispatch_console.kiosk_alerts
			WHERE OccuranceDateTimeStamp BETWEEN '${after}' AND '${before}' AND KioskStatus = 'Fixed';`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectAlertsBetweenDates: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectJobsBetweenDates = async (after, before) => {
	try {
		const statement = `SELECT *
            FROM dispatch_console.jobs
            WHERE CreatedDate BETWEEN '${after}' AND '${before}' AND AlertID != 'NULL';`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectJobsBetweenDates: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectReportsBetweenDates = async (after, before) => {
	try {
		const statement = `SELECT *
            FROM dispatch_console.service_report
            WHERE ServiceDate BETWEEN '${after}' AND '${before}' AND JobID NOT LIKE 'P%';`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectReportsBetweenDates: ${error} - ${new Date()}`);
		throw error;
	}
};

export const updateScheduleForTM = async (obj) => {
	try {
		const statement = `CALL UpdateSchedule('${JSON.stringify(obj)}');`;
		const response = await pool.query(statement);
		return response[0][0].successful;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateScheduleForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectTechHelpSolutionsForTM = async () => {
	try {
		const statement = `SELECT * FROM tech_help_solutions;`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectTechHelpSolutionsForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getDailyAlertsByKiosk = async (_fullReport) => {
	try {
		const statement = `call dispatch_console.GetAlertIssuesByKiosk(3,${_fullReport});`;
		const response = await pool.query(statement);
		return !_fullReport ? response[0] : response;
	} catch (error) {
		console.error(`ERROR: TableManagementTB.getDailyAlertsByKiosk: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectFromTechniciansForTM = async () => {
	try {
		const statement = `SELECT * FROM technicians WHERE Active = 1 ORDER BY FirstName;`;
		const response = await pool.query(statement);

		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectFromTechniciansForTM: ${error.message} - ${new Date()}`);
		throw new Error('Error retrieving technicians');
	}
};

export const updateTechnicianForTM = async (obj) => {
	try {
		const statement = `UPDATE technicians
                SET
                TechnicianID = "${obj.TechnicianID}",
                Notes = "${obj.Notes}",
                FirstName = "${obj.FirstName}",
                LastName = '${obj.LastName}',
                StartDate = "${obj.StartDate}",
                MobileNumber = "${obj.MobileNumber}",
                CompanyName = "${obj.CompanyName}",
                Address = "${obj.Address}",
                City = "${obj.City}",
                State = "${obj.State}",
                Zip = "${obj.Zip}",
                BirthDate = "${obj.BirthDate}",
                PersonalEmail = '${obj.PersonalEmail}',
                PaperChanger = '${obj.PaperChanger ? 1 : 0}',
                EmailReminder = '${obj.EmailReminder ? 1 : 0}',
                Shipping = '${obj.Shipping}'
                WHERE TechnicianID = "${obj.TechnicianID}"
                `;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateTechnicianForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const insertTechnicianForTM = async (obj) => {
	try {
		const statement = `INSERT INTO dispatch_console.technicians (Notes,FirstName,LastName,StartDate,MobileNumber,CompanyName,Address,City,State,Zip,BirthDate,PersonalEmail,IsAvaliable,PaperChanger,Shipping,EmailReminder)
            VALUES("${obj.Notes}", '
            ${obj.FirstName}',
            '${obj.LastName}',
            '${obj.StartDate}',
            '${obj.MobileNumber}',
            '${obj.CompanyName}',
            '${obj.Address}',
            '${obj.City}',
            '${obj.State}',
            '${obj.Zip}',
            '${obj.BirthDate}',
            '${obj.PersonalEmail}',
            '${obj.IsAvaliable}',
            '${obj.PaperChanger ? 1 : 0}',
            '${obj.EmailReminder ? 1 : 0}',
            '${obj.Shipping}');`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.insertTechnicianForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const deleteTechnicianForTM = async (TechnicianID) => {
	try {
		const statement = `CALL RemoveTechFromAllKiosks(${TechnicianID})`; //remove tech from all kiosks
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.deleteTechnicianForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectFromTimeEntryForTM = async () => {
	try {
		const statement = `SELECT * FROM dispatch_console.TimeEntry;`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectFromTimeEntryForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectFromTechniciansNameForTM = async () => {
	try {
		const statement = `SELECT CONCAT(FirstName, ' ', LastName) AS names
            FROM technicians ORDER BY names;`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectFromTechniciansNameForTM: ${error} - ${new Date()}`);
		throw error;
	}
};

export const insertLatLong = async (passedLatLongObject) => {
	try {
		const statement = `CALL InsertLatLong(?,?,?,?)`;

		const params = [
			passedLatLongObject.ForTable,
			passedLatLongObject.ID,
			passedLatLongObject.Latitude,
			passedLatLongObject.Longitude,
		];
		const response = await pool.query(statement, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.insertLatLong: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectAllAlerts = async () => {
	try {
		const statement = `SELECT * FROM dispatch_console.kiosk_alerts
            WHERE KioskID IN(SELECT KioskID FROM dispatch_console.kiosk_alerts
                WHERE KioskStatus = 'Pending'
                AND left(KioskID,2) = 'WI'
                AND right(KioskID,1) > 0)
            AND date(OccuranceDateTimeStamp) BETWEEN curdate() - interval 1 year
            AND curdate()
            AND KioskIssueID in('73103', '73104', '73105', '73106', '73107', '73121', '73122')
            ORDER BY AlertID desc;`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectAllAlerts: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getAlertsWithDesc = async (days) => {
	try {
		const statement = `SELECT a.*,
                j.JobID,
                i.Description,
                i.ToDoNext,
                JSON_EXTRACT(i.ToDoNext,concat("$.",a.StepAlertIsAt)) as last_step
            FROM dispatch_console.kiosk_alerts a
            JOIN dispatch_console.kiosk_issues i
            ON (a.KioskIssueID = i.KioskIssueID)
            LEFT JOIN dispatch_console.jobs j
            ON (a.AlertID = j.AlertID)
            WHERE left(a.KioskID,2) = 'WI'
            and DATE(a.OccuranceDateTimeStamp) BETWEEN CURDATE() - INTERVAL '${days}' DAY
            AND CURDATE()
            AND a.KioskIssueID IN ('73103' , '73104','73105','73106','73107','73121','73122')
            ORDER BY a.AlertID DESC;`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getAlertsWithDesc: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectAllCompletedJobs = async (days) => {
	try {
		const action = `CALL dispatch_console.getAllCompletedJobs(${days});`;
		const response = await pool.query(action);
		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectAllCompletedJobs: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectAllStarPaperCounts = async () => {
	try {
		const statement = `SELECT * FROM dispatch_console.star_paper_holder_table;`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectAllStarPaper: ${error} - ${new Date()}`);
		throw error;
	}
};

export const insertStarPaper = async (paperObject) => {
	try {
		const statment = `CALL insertStarCounts('${paperObject}');`;
		const response = await pool.query(statment);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.insertStarPaper: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectAllMapDriveTimesForTech = async (tech) => {
	try {
		const statement = `SELECT * FROM dispatch_console.mapDriveTime where TechID = '${tech}';`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectAllMapDriveTimes: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectAllMapDriveTimes = async () => {
	try {
		const statement = `WITH RankedEntries AS (
								SELECT
								DriveTimeID,
								KioskID,
								TechID,
								TechName,
								TechAddress,
								DriveTime,
								ROW_NUMBER() OVER (PARTITION BY KioskID, TechName ORDER BY DriveTimeID desc) as rn
								FROM mapDriveTime m

								LEFT JOIN technicians t ON t.FullName = m.TechName
								WHERE t.Active = 1
								)
								SELECT
								* from RankedEntries

								WHERE rn = 1
								GROUP BY KioskID, TechName
								;`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectAllMapDriveTimes: ${error} - ${new Date()}`);
		throw error;
	}
};

export const fetchCallLogs = async (log_type, date) => {
	try {
		const statement = `CALL fetchCallLogs('${log_type}', '${date}');`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.fetchCallLogs: ${error} - ${new Date()}`);
		throw error;
	}
};

export const insertNewStarPaper = async (StarPaperObject) => {
	try {
		const statement = `INSERT INTO dispatch_console.star_paper_holder_table (KioskID, StarPaperCount, DateUpdated, KioskID_Loc) VALUES (?, ?, ?, ?);`;

		const params = [
			StarPaperObject.KioskID,
			StarPaperObject.StarPaperCount,
			StarPaperObject.TodaysDate,
			StarPaperObject.KioskID_Loc,
		];
		const response = await pool.query(statement, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.insertStarPaperKiosk: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectAllPaperJobs = async () => {
	try {
		const statement = `SELECT * FROM dispatch_console.paper_jobs;`;
		const response = await pool.query(statement);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectAllPaperJobs: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getMapAnalytics = async (days) => {
	try {
		const action = `call dispatch_console.getMapAnalytics(?);`;
		const params = [days];
		const response = await pool.query(action, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getMapAnalytics: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getMapMarkerInfo = async (id, type) => {
	try {
		const action = `call dispatch_console.getMapMarkerInfo('${id}','${type}');`;
		const params = [id, type];
		const response = await pool.query(action, params);
		return response[0];
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getMapMarkerInfo: ${error} - ${new Date()}`);
		throw new Error('Unable to retrieve map marker information');
	}
};

export const getHeatMapData = async (days, data_type) => {
	try {
		const action = `call dispatch_console.getHeatMapData(${days},'${data_type}');`;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getHeatMapData: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectAllPaperForROC = async () => {
	try {
		const action = `SELECT dispatch_console.paper_jobs.ID,
                dispatch_console.paper_jobs.PaperJobID,
                dispatch_console.paper_jobs.KiosksString,
                dispatch_console.paper_jobs.Kiosks,
                dispatch_console.paper_jobs.JobStatus,
                dispatch_console.paper_jobs.CreatedDate,
                dispatch_console.paper_jobs.TempTechnician
            FROM dispatch_console.paper_jobs;
            `;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectAllPaperJobs: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectPaperJob = async (jobID) => {
	try {
		const action = `SELECT * FROM dispatch_console.paper_jobs WHERE PaperJobID = '${jobID}';`;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectPaperJob: ${error} - ${new Date()}`);
		throw error;
	}
};

export const updatePendingPaperJob = async (_kiosks, _jobs, _id) => {
	try {
		const statement = `UPDATE dispatch_console.paper_jobs SET KiosksString = ?, Kiosks = ? WHERE ID = ?`;
		const response = await pool.query(statement, [_kiosks, _jobs, _id]);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updatePendingPaperJob: ${error} - ${new Date()}`);
		throw error;
	}
};

export const updateRepurposeCount = async (ID, count) => {
	try {
		const action = `UPDATE dispatch_console.inventory_bins
            SET qty = ?
            WHERE id = ?;`;
		const params = [count, ID];
		const response = await pool.query(action, params);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.updateRepurposeCount: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getRepurposeCount = async () => {
	try {
		const currentYear = new Date().getFullYear();
		const nextYear = currentYear + 1;
		const futureYear = currentYear + 2;
		const action = `SELECT
            DISTINCT (SELECT qty FROM dispatch_console.inventory_bins WHERE name = '${currentYear} Repurposed Stickers' ) AS '${currentYear} Repurposed Stickers',
            (SELECT qty FROM dispatch_console.inventory_bins WHERE name = '${nextYear} Repurposed Stickers') AS '${nextYear} Repurposed Stickers',
            (SELECT qty FROM dispatch_console.inventory_bins WHERE name = '${futureYear} Repurposed Stickers')  AS '${futureYear} Repurposed Stickers',
            (SELECT id FROM dispatch_console.inventory_bins WHERE name = '${currentYear} Repurposed Stickers' ) AS '${currentYear} ID',
            (SELECT id FROM dispatch_console.inventory_bins WHERE name = '${nextYear} Repurposed Stickers') AS '${nextYear} ID',
            (SELECT id FROM dispatch_console.inventory_bins WHERE name = '${futureYear} Repurposed Stickers')  AS '${futureYear} ID'
            FROM dispatch_console.inventory_bins;`;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getRepurposeCount ${error} - ${new Date()}`);
		throw error;
	}
};

export const saveToDatabase = async () => {
	try {
		const response = await axios.post('/api/database_error_logging');
		return response;
	} catch (error) {
		console.error('Error', error);
		throw error;
	}
};

export const downloadPaperUploadReport = async () => {
	try {
		const action = `CALL downloadPaperUploadReport();`;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.downloadPaperUploadReport: ${error} - ${new Date()}`);
		throw error;
	}
};

export const getKmlReport = async () => {
	try {
		const action = `select * from kmlReport;`;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.getKmlReport: ${error} - ${new Date()}`);
		throw error;
	}
};

export const selectCallLogOptions = async () => {
	try {
		const action = `CALL selectCallLogOptions();`;
		const response = await pool.query(action);
		return response;
	} catch (error) {
		console.error(`ERROR: tableManagementTB.selectCallLogOptions: ${error} - ${new Date()}`);
		throw error;
	}
};

export const logError = async (
	fileNameID,
	functionNameID,
	timestampID,
	errorMessage,
	stackTrace,
	Priority,
	Severity
) => {
	try {
		const response = await pool.query('INSERT INTO productionErrorLog SET ?', [
			{ fileNameID, functionNameID, timestampID, errorMessage, stackTrace, Priority, Severity },
		]);
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
