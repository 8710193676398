import React, { useEffect, useState, useCallback } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import CalendarHeader from './CalendarHeader';
import CalendarBody from './CalendarBody';
import RequestModal from './RequestModal';
import { useCustomContext } from '../../hoc/Context.js';
import '../../StyleSheets/Calendar.css';
import AlertBar from '../../components/AlertBar.js';
dayjs.extend(isBetween);

const TableHeaders = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const COLORS = [
	'rgb(221,160,221)',
	'rgb(176,196,222)',
	'rgb(152,251,152)',
	'rgb(219,112,147)',
	'rgb(64,224,208)',
	'rgb(255,255,0)',
	'rgb(175,238,238)',
	'rgb(176,224,230)',
	'rgb(255,192,203)',
	'rgb(250,128,114)',
	'rgb(255,218,185)',
];

const EmployeeCalendar = () => {
	const { userState } = useCustomContext();
	const today = dayjs().startOf('month');
	const [loading, setLoading] = useState(true);
	const [pto, setPto] = useState(0);
	const [calendarMonth, setCalendarMonth] = useState([]);
	const [, setRequestData] = useState([]);
	const [requestModal, setRequestModal] = useState({
		visible: false,
		data: {
			start: dayjs().hour(8).minute(0).second(0).format('YYYY-MM-DDTHH:mm'), // Default to 8AM
			end: dayjs().hour(8).minute(0).second(0).format('YYYY-MM-DDTHH:mm'), // Default to 8AM
		},
		updating: false,
		remove: { visible: false, type: 'delete' },
		isApproval: false,
	});

	const [snackbar, setSnackbar] = useState({ visible: false, message: '', severity: 'success' });
	const [curDate, setCurDate] = useState({
		month_num: today.month(),
		month: today.format('MMMM'),
		year: today.year(),
		startIndex: today.day(),
		date: today,
	});

	const handleOpenSnack = useCallback(({ message = '', severity = 'success' }) => {
		setSnackbar({ visible: true, message, severity });
	}, []);

	const fetchData = useCallback(async () => {
		setLoading(true);
		setRequestData([]);
		setCalendarMonth([]); // Reset calendar month to avoid showing incorrect data
		try {
			const startDate = dayjs(new Date(curDate.year, curDate.month_num, 1)).format('YYYY-MM-DD');
			const endDate = dayjs(new Date(curDate.year, curDate.month_num + 1, 0)).format('YYYY-MM-DD');

			socket.emit('selectEmpCalendar', startDate, endDate, (res) => {
				if (!res) {
					setCalendarMonth(generateCalendarDays(curDate.date, [])); // Show blank days if no data
					throw new Error('Failed to fetch calendar data');
				}

				const reqs = res.map((req) => ({
					...req,
					start: dayjs(req.StartDate).format('YYYY-MM-DDTHH:mm'),
					end: dayjs(req.ReturnDate).format('YYYY-MM-DDTHH:mm'),
					days: dayjs(req.ReturnDate).diff(dayjs(req.StartDate), 'day') + 1,
					title: req.FullName,
					notes: req.Notes,
					status: req.Status,
					email: req.Email,
					responsenotes: req.ResponseNotes,
					timeoffID: req.TimeOffID,
					uid: req.uid,
				}));

				const colors = reqs.reduce((requests, req) => {
					if (!requests[req.title]) {
						requests[req.title] = COLORS[Object.keys(requests).length % COLORS.length];
					}
					return requests;
				}, {});

				reqs.forEach(req => {
					req.color = colors[req.title];
				});

				socket.emit('employeeBirthdays', (birthdays) => {
					if (!birthdays) {
						setCalendarMonth(generateCalendarDays(curDate.date, reqs)); // Show only requests if no birthdays
						throw new Error('Failed to fetch birthdays');
					}

					const bdays = birthdays.map((user) => ({
						id: user.UserID,
						uid: user.UserID,
						name: 'EVENT',
						start: `${dayjs(user.Birthday).year(curDate.year).format('YYYY-MM-DD')}` || curDate.date,
						end: `${dayjs(user.Birthday).year(curDate.year).format('YYYY-MM-DD')}` || curDate.date,
						snippet: `Birthday! ${user.FullName}`,
						notes: `Birthday! ${user.FullName}`,
						days: 1,
					}));
					const allReqs = [...reqs, ...bdays];
					const pto_placeholder = 0;
					setRequestData(allReqs);
					setPto(pto_placeholder);
					setCalendarMonth(generateCalendarDays(curDate.date, allReqs));
				});
			});
		} catch (error) {
			console.error(error);
			handleOpenSnack({ message: 'Error loading calendar data', severity: 'error' });
		} finally {
			setLoading(false);
		}
	}, [curDate, userState.user.uid]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const generateCalendarDays = (currentDate, requests) => {
		const startOfMonth = currentDate.startOf('month');
		const endOfMonth = currentDate.endOf('month');
		const startDay = startOfMonth.day();
		const calendarDays = [];
		let week = [];

		for (let i = 0; i < 42; i++) {
			const dayOfWeek = i % 7;
			const date = startOfMonth.add(i - startDay, 'day');
			const isToday = date.isSame(dayjs(), 'day');
			const type = date.isBefore(startOfMonth, 'day')
				? 'prior'
				: date.isAfter(endOfMonth, 'day')
				? 'future'
				: 'current';
			const requestsForDay = requests.filter((req) => date.isBetween(req.start, req.end, 'day', '[]'));

			// Assign slot property to each request to handle stacking
			requestsForDay.forEach((req, idx) => {
				req.slot = idx;
			});

			week.push({ day: date.date(), type, requests: requestsForDay, today: isToday });

			if (dayOfWeek === 6) {
				calendarDays.push(week);
				week = [];
			}
		}

		return calendarDays;
	};

	const handleMonthChange = (direction) => {
		const newDate = curDate.date.add(direction, 'month');
		setCurDate({
			date: newDate,
			month_num: newDate.month(),
			month: newDate.format('MMMM'),
			year: newDate.year(),
			startIndex: newDate.startOf('month').day(),
		});
		setLoading(true);
		fetchData();
	};

	const handleYearChange = (year) => {
		const newDate = curDate.date.year(year);
		setCurDate({
			date: newDate,
			year: newDate.year(),
			month_num: newDate.month(),
			month: newDate.format('MMMM'),
			startIndex: newDate.startOf('month').day(),
		});
		setLoading(true);
		fetchData();
	};

	const handleOpenRequest = (day, request = null) => {
		const selectedDate = dayjs(`${curDate.year}-${curDate.month_num + 1}-${day}`).hour(8).minute(0).second(0).format('YYYY-MM-DDTHH:mm'); // Default to 8AM
		setRequestModal((prev) => ({
			...prev,
			visible: true,
			data: request ? request : { start: selectedDate, end: selectedDate },
			isApproval: request && userState.user.permissions === 'master', // Set isApproval based on user role
		}));
	};

	const handleCalendarClick = (day, request) => {
		if (request && userState.user.permissions !== 'master' && request.uid !== userState.user.uid) {
			// Prevent non-master users from opening other users' requests
			return;
		}
		handleOpenRequest(day, request);
	};

	const sendEmail = async (email_chain, req_obj) => {
		const to = email_chain.join(',');
		const subject = `RTI Time Off Request - ${req_obj.FullName}`;
		let text =
			`<div width="100%" style="text-align:center;">` +
			`<h1 style="text-align:center;">Time Off Request ${req_obj.Status}</h1>` +
			`<table style="background-color:black; width: 400px;">` +
			`<tbody>` +
			`<tr><td style="background-color:bisque; width: 100px;">Employee</td><td style="background-color:white;">${req_obj.FullName}</td></tr>` +
			`<tr><td style="background-color:bisque;">Start Date</td><td style="background-color:white;">${req_obj.StartDate}</td></tr>` +
			`<tr><td style="background-color:bisque;">Return Date</td><td style="background-color:white;">${req_obj.ReturnDate}</td></tr>` +
			`<tr><td style="background-color:bisque;">Notes</td><td style="background-color:white;">${req_obj.Notes}</td></tr>`;

		if (req_obj.ApprovalNotes) {
			text += `<tr><td style="background-color:bisque;">Approval Notes</td><td style="background-color:white;">${req_obj.ApprovalNotes}</td></tr>`;
		}

		text +=
			`<tr><td style="background-color:bisque;">Status</td><td style="background-color:white;">${req_obj.Status}</td></tr>` +
			`</tbody>` +
			`</table>` +
			`<p><b>Please go to the console to review this request</b></p>` +
			`</div>`;

		const response = await fetch(
			`/send?to=${encodeURIComponent(to)}&subject=${encodeURIComponent(subject)}&text=${encodeURIComponent(text)}`
		);
		const result = await response.text();
		return result.includes('Mail sent successfully') ? 200 : result;
	};

	const handleRequestSubmission = (formData) => {
		const request = {
			FullName: userState.user.fullName,
			StartDate: dayjs(formData.start).format('YYYY-MM-DD HH:mm:ss'),
			ReturnDate: dayjs(formData.end).format('YYYY-MM-DD HH:mm:ss'),
			Notes: formData.notes || "", // Ensure Notes is a string
			PTO: true,
			Status: 'Pending',
		};

		socket.emit('insertEmpTimeOffRequest', request, async (res) => {
			if (!res) {
				handleOpenSnack({
					message: 'Error submitting request. Please try again.',
					severity: 'error',
				});
			} else {
				const staticEmails = [
					request.email,
					'Brian@registration-technology.com',
					'Accounting@registration-technology.com',
				];
				const status = await sendEmail(staticEmails, request);
				if (status === 200) {
					handleOpenSnack({
						message: 'Success! Request submitted successfully.',
						severity: 'success',
					});
					setRequestModal((prev) => ({ ...prev, visible: false }));
					fetchData();
				} else {
					handleOpenSnack({
						message: 'Error notifying admins about this request. Please try again.',
						severity: 'error',
					});
				}
			}
		});
	};

	const handleSaveRequest = (formData) => {
		const request = {
			StartDate: formData.start,
			ReturnDate: formData.end,
			Notes: formData.notes,
			Status: formData.status,
			ApprovalNotes: formData.approvalNotes || '',
			TimeOffID: formData.timeoffID || null,
			FullName: formData.name, // Ensure FullName is included
			email: formData.email, // Ensure email is included
		};
		socket.emit('updateEmpTimeOffRequest', request, async (res) => {
			if (!res) {
				handleOpenSnack({
					message: 'Error saving request. Please try again.',
					severity: 'error',
				});
			} else {
				const status = await sendEmail(
					[request.email, 'Brian@registration-technology.com', 'Accounting@registration-technology.com'],
					request,
					request.Status
				);

				if (status === 200) {
					handleOpenSnack({
						message: 'Request saved successfully and email sent.',
						severity: 'success',
					});
				} else {
					handleOpenSnack({
						message: 'Request saved successfully but failed to send email.',
						severity: 'warning',
					});
				}
				setRequestModal((prev) => ({ ...prev, visible: false }));
				fetchData();
			}
		});
	};

	const handleSubmitRequest = (formData) => {
		if (requestModal.isApproval) {
			handleSaveRequest(formData);
		} else {
			handleRequestSubmission(formData);
		}
	};

	const handleCloseRequest = () => {
		setRequestModal((prev) => ({ ...prev, visible: false }));
	};

	return (
		<div className='employee-calendar'>
			<CalendarHeader curDate={curDate} onMonthChange={handleMonthChange} onYearChange={handleYearChange} />
			<CalendarBody
				TableHeaders={TableHeaders}
				calendarMonth={calendarMonth}
				loading={loading}
				handleCalendarClick={handleCalendarClick}
				userState={userState}
			/>
			{requestModal.visible && (
				<RequestModal
					requestModal={requestModal}
					pto={pto}
					handleSaveRequest={handleSubmitRequest}
					onCloseRequest={handleCloseRequest}
					isApproval={requestModal.isApproval}
				/>
			)}
			<AlertBar
				message={snackbar.message}
				visible={snackbar.visible}
				severity={snackbar.severity}
				onClose={() => setSnackbar({ ...snackbar, visible: false })}
			/>
		</div>
	);
};

export default EmployeeCalendar;
