import React, { useState, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { FormatDate, FormatPhoneNumber, FormatNA, DateToYMD } from '../../imports/API/TextFormatingFunctions.js';
import Modal from '../../components/Modal.js';
import CustomTable from '../../components/Table.js';
import Button from '@mui/material/Button';
import { FaPlusCircle } from 'react-icons/fa';
import { FiDownloadCloud } from 'react-icons/fi';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import CheckBox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FaCameraRetro } from 'react-icons/fa';
import { useCustomContext } from '../../hoc/Context.js';
import axios from "axios"
import $ from 'jquery';

const TableHeaders = [
	{ id: 'tech_id', name: 'Tech #' },
	{ id: 'name', name: 'Name', sortable: true, numeric: false },
	{ id: 'phone', name: 'Phone #' },
	{ id: 'email', name: 'Email' },
	{ id: 'start-date', name: 'Start Date' },
	{ id: 'birth-date', name: 'Birth Date' },
	{ id: 'address', name: 'Address' },
];

const SearchOptions = [{ id: 'name', name: 'Name', pidx: 1 }];

const ShippingOptions = ['Any', 'UPS', 'USPS', 'FedEx', 'DHL'];

const image_base = 'https://s3.us-west-2.amazonaws.com/rti.images/Tech-Images/';

//Formats the TechnicianTable Information for each row
const TechnicianTable = () => {
	const [, setRowClicked] = useState('');
	const [SearchVal, setSearchVal] = useState('');
	const [searchParam, setSearchParam] = useState(0);
	const [TechniciansDB, setTechniciansDB] = useState([]);
	const [Loading, setLoading] = useState(true);
	const [selected, setSelected] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const { userState } = useCustomContext();
	const selectedRef = useRef();
	selectedRef.current = selected;

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			socket.emit('selectFromTechniciansForTM', (result) => {
				setTechniciansDB(result);
				setLoading(false);
			});
		}

		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		$('#tech-modal').toggleClass('flex-box');
	}, [modalOpen]);

	const RefreshTableData = () => {
		socket.emit('selectFromTechniciansForTM', (result) => {
			setTechniciansDB(result);
		});
	};

	const RowClick = (e) => {
		const id = e.target.id;
		const tech = TechniciansDB.find((el) => el.TechnicianID == id);
		const profile_image = `${image_base}${tech.TechnicianID}.jpg`;

		setSelected({ ...tech, profile_image });
		setModalOpen(true);
		setRowClicked(id);
	};

	const AddNewItemBtn = () => {
		if (userState.user.role) {
			setModalOpen(true);
		}
	};

	function ClearSearch() {
		setSearchVal('');
	}

	function Search(param, searchVal) {
		setSearchVal(searchVal);
		setSearchParam(param);
	}

	const handleAdd = () => {
		const address = document.getElementById('address').value;
		const city = document.getElementById('city').value;
		const state = document.getElementById('state').value;
		const zip = document.getElementById('zip').value;
		const fname = document.getElementById('fname').value;
		const lname = document.getElementById('lname').value;

		const tech_info = {
			Notes: document.getElementById('notes').value,
			FirstName: fname,
			LastName: lname,
			StartDate: document.getElementById('start-date').value,
			MobileNumber: document.getElementById('phone').value,
			CompanyName: document.getElementById('company').value,
			Address: address,
			City: city,
			State: state,
			Zip: zip,
			BirthDate: document.getElementById('birth-date').value,
			PersonalEmail: document.getElementById('email').value,
			PaperChanger: selected.PaperChanger ? true : false,
			EmailReminder: selected.EmailReminder ? true : false,
			IsAvaliable: 'Yes',
			Shipping: document.getElementById('shipping').value,
			FullName: `${fname} ${lname}`,
			RawAddress: `${address} ${city} ${state} ${zip}`,
		};

		if (validate(tech_info)) {
			socket.emit('insertTechnicianForTM', tech_info, (boolean) => {
				if (boolean) {
					//Close CreateJobModal
					UpdateTechsAndCoordinates(tech_info, 'insert');

					alert(
						`Technician has been created.  Please allow up to 5 minutes for this technician's drive times to be created.`
					);
					RefreshTableData();
				} else {
					//Notify user of Failure
					alert('You did not fill the form out correctly - insert issue/TechnicianTable'); // TODO: testing purposes
				}
			});
		} else {
			alert('Please make sure that all required fields are filled out, and try again.');
		}
	};

	const validate = (data) => {
		if (!data.FirstName | !data.LastName | !data.MobileNumber | !data.Address | !data.City | !data.State | !data.Zip) {
			return false;
		}

		return true;
	};

	const handleTechDownload = () => {
		document.getElementById('tech-download').click();
	};

	function RenderTableButtons() {
		return userState.user.role ? (
			<span style={{ display: 'flex' }}>
				<div>
					<Button
						className='rti-blue-round'
						variant='contained'
						startIcon={<FiDownloadCloud />}
						onClick={handleTechDownload}
					>
						Techs
					</Button>
				</div>
				<div>
					<CSVLink
						id='tech-download'
						style={{ background: 'none', color: 'none', border: 'none' }}
						target='_blank'
						className='hidden'
						data={TechniciansDB}
						filename={'Technicians.csv'}
					></CSVLink>
				</div>
				<div style={{ float: 'right' }}>
					<Button className='rti-blue-round' variant='contained' startIcon={<FaPlusCircle />} onClick={AddNewItemBtn}>
						Tech
					</Button>
				</div>
			</span>
		) : undefined;
	}

	const getTableData = () => {
		return TechniciansDB.map((tech) => ({
			key: tech.TechnicianID,
			cells: [
				{ data: tech.TechnicianID },
				{ data: `${tech.FirstName} ${tech.LastName}` },
				{ data: FormatNA(FormatPhoneNumber(tech.MobileNumber)) },
				{ data: FormatNA(tech.PersonalEmail) },
				{ data: FormatDate(tech.StartDate) },
				{ data: FormatDate(tech.BirthDate) },
				{
					data: `${tech.CompanyName}\n${tech.Address}\n${tech.City}, ${tech.State} ${tech.Zip}`,
				},
			],
		})).filter((row) =>
			SearchVal && searchParam
				? row.cells[SearchOptions[searchParam - 1].pidx].data.toLowerCase().includes(SearchVal.toLowerCase())
				: row
		);
	};

	const handleCheckPaper = (e) => {
		const checked = e.target.checked;
		setSelected((prevState) => ({
			...prevState,
			PaperChanger: checked,
		}));
	};

	const handleEmailReminder = (e) => {
		const checked = e.target.checked;
		setSelected((prevState) => ({
			...prevState,
			EmailReminder: checked,
		}));
	};

	const handleModalClose = () => {
		setSelected({});
		setModalOpen(false);
	};

	async function UpdateTechsAndCoordinates(info, type) {
		try {
			// Await the axios.post call to get the resolved value
			const coordinatesResponse = await axios.post(`/api/geocode_address`, {
				address: info.RawAddress,
				city: info.City,
				state: info.State,
			});
			const technician_coordinates = coordinatesResponse.data;
			if (!technician_coordinates) {
				console.error(`TechnicianTable.geocode_address: Could not geocode address. Please check the address and try again.`);

				alert('Could not geocode address. Please check the address and try again.');

				return;
			}
			// TODO: Add in the technicians
			const latLong = {
				ForTable: 'technicians',
				ID: info.TechnicianID,
				Latitude: technician_coordinates.latitude,
				Longitude: technician_coordinates.longitude,
			};

			await new Promise((resolve) => {
				socket.emit('insertLatLong', latLong, resolve);
			});

			return await axios.post(`/api/get_tech_drive_times`, {
        		tech: info,
				coordinates:technician_coordinates,
        		address: info.RawAddress,
				type: type,
			});
		} catch (error) {
			console.error(`ERROR: TechnicianTable.UpdateTechsAndCoordinates: ${error.message} - ${new Date()}`);
			alert('An error occured while updating the kiosk. Please try again.');
			throw error;
		}
	}

	const handleSave = () => {
		const address = document.getElementById('address').value;
		const city = document.getElementById('city').value;
		const state = document.getElementById('state').value;
		const zip = document.getElementById('zip').value;

		const tech_info = {
			TechnicianID: selected.TechnicianID,
			Notes: document.getElementById('notes').value,
			FirstName: document.getElementById('fname').value,
			LastName: document.getElementById('lname').value,
			StartDate: document.getElementById('start-date').value,
			MobileNumber: document.getElementById('phone').value,
			CompanyName: document.getElementById('company').value,
			Address: address,
			City: city,
			State: state,
			Zip: zip,
			BirthDate: document.getElementById('birth-date').value,
			PersonalEmail: document.getElementById('email').value,
			PaperChanger: selected.PaperChanger,
			EmailReminder: selected.EmailReminder,
			Shipping: document.getElementById('shipping').value,
			FullName: `${document.getElementById('fname').value} ${document.getElementById('lname').value}`,
			RawAddress: `${address} ${city} ${state} ${zip}`,
		};

		const old_address = `${selected.Address} ${selected.City} ${selected.State} ${selected.Zip}`;

		socket.emit('updateTechnicianForTM', tech_info, (boolean) => {
			if (boolean) {
				//Close CreateJobModal
				if (old_address.toUpperCase() !== tech_info.RawAddress.toUpperCase()) {
					// if address has changed, update drive times and coordinates for this tech
					UpdateTechsAndCoordinates(tech_info, 'update');

					alert(
						`Your changes have been saved.  Please allow up to 5 minutes for this technician's drive times to be updated.`
					);
					RefreshTableData();
				} else {
					alert(`Your changes have been saved.`);
					RefreshTableData();
				}
			} else {
				//Notify user of Failure
				alert('You did not fill the form out correctly');
			}
		});
	};

	const handleDelete = () => {
		const deleteJobConfirm = prompt("Please enter 'DELETE' to confirm deletion of this Technician.", '');
		if (deleteJobConfirm) {
			if (deleteJobConfirm.toLowerCase() == 'delete') {
				const TechnicianID = selected.TechnicianID;
				socket.emit('deleteTechnicianForTM', TechnicianID, (boolean) => {
					if (boolean) {
						//Close CreateJobModal
						RefreshTableData();
						setModalOpen(false);
					} else {
						//Notify user of Failure
						alert('Technician was not deleted.');
					}
				});
			}
		}
	};

	const handleChangeImage = () => {};

	return (
		<div className='ViewedContentContainer' id='OpenContainer'>
			<div style={{ height: '100%' }}>
				<div className='TitleBarContainer'>
					<h1 title="View, Add, Edit, or delete a technician's data from this page. If you delete a technician, it will remove them from all kiosks they are assigned to.">
						Technicians
					</h1>
				</div>

				{modalOpen ? (
					<Modal modal_id='tech-modal' dimension={{ width: 850 }} onClose={handleModalClose}>
						<div id='inner-modal'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									marginBottom: 40,
								}}
							>
								<Avatar
									style={{ width: 80, height: 80, fontSize: '2.0rem' }}
									src={selectedRef.current.profile_image ? selectedRef.current.profile_image : null}
								>
									{selectedRef.current.FirstName && selectedRef.current.LastName
										? `${selectedRef.current.FirstName[0]}${selectedRef.current.LastName[0]}`
										: 'NA'}
								</Avatar>
								<div className='avatar-overlay' id='avatar-overlay' onClick={handleChangeImage}>
									<FaCameraRetro style={{ color: 'whitesmoke', fontSize: 46 }} />
								</div>
								<TextField
									className='table-date-input'
									id='start-date'
									name='StartDate'
									label='Start Date'
									defaultValue={
										selectedRef.current.StartDate && selectedRef.current.StartDate !== '0000-00-00'
											? selectedRef.current.StartDate.split('T')[0]
											: DateToYMD(new Date())
									}
									type='date'
								/>
								<div style={{ width: '41%', marginLeft: 'auto' }}>
									<FormControlLabel
										control={
											<CheckBox
												name='PaperChanger'
												checked={selectedRef.current && selectedRef.current.PaperChanger == 1 ? true : false}
												id='paper-tech'
												color='primary'
												onChange={handleCheckPaper}
											/>
										}
										label='Paper Changer'
									/>
									<FormControlLabel
										control={
											<CheckBox
												name='EmailReminder'
												checked={selectedRef.current && selectedRef.current.EmailReminder == 1 ? true : false}
												id='email-reminder'
												color='primary'
												onChange={handleEmailReminder}
											/>
										}
										label='Email Reminders'
									/>
								</div>
							</div>
							<div style={{ margin: '24px 0px' }}>
								<div className='form-field-container'>
									<TextField
										name='FirstName'
										defaultValue={selectedRef.current.FirstName || ''}
										className='text-field'
										id='fname'
										label='First'
										required={selectedRef.current.TechnicianID ? false : true}
										type='text'
									/>
									<TextField
										name='LastName'
										defaultValue={selectedRef.current.LastName || ''}
										className='text-field'
										id='lname'
										label='Last'
										required={selectedRef.current.TechnicianID ? false : true}
										type='text'
									/>
									<TextField
										name='BirthDate'
										className='table-date-input'
										id='birth-date'
										label='Birth Date'
										defaultValue={
											selectedRef.current.BirthDate && selectedRef.current.BirthDate !== '0000-00-00'
												? selectedRef.current.BirthDate.split('T')[0]
												: DateToYMD(new Date())
										}
										type='date'
									/>
								</div>
								<div className='form-field-container'>
									<TextField
										name='MobileNumber'
										defaultValue={selectedRef.current.MobileNumber || ''}
										className='text-field'
										id='phone'
										label='Phone #'
										required={selectedRef.current.TechnicianID ? false : true}
										type='phone'
									/>
									<TextField
										style={{ width: 300 }}
										name='PersonalEmail'
										defaultValue={selectedRef.current.PersonalEmail || ''}
										className='text-field'
										id='email'
										label='Email'
										type='email'
									/>
								</div>
							</div>
							<div style={{ margin: '24px 0px' }}>
								<div className='form-field-container'>
									<TextField
										name='CompanyName'
										defaultValue={selectedRef.current.CompanyName || ''}
										style={{ width: 300 }}
										className='text-field'
										id='company'
										label='Company Name'
										type='text'
									/>
								</div>
								<div className='form-field-container'>
									<TextField
										name='Address'
										style={{ width: 300 }}
										defaultValue={selectedRef.current.Address || ''}
										className='text-field'
										id='address'
										label='Address'
										required={selectedRef.current.TechnicianID ? false : true}
										type='text'
									/>
									<TextField
										name='City'
										defaultValue={selectedRef.current.City || ''}
										className='text-field'
										id='city'
										label='City'
										required={selectedRef.current.TechnicianID ? false : true}
										type='text'
									/>
								</div>
								<div className='form-field-container' style={{ display: 'flex' }}>
									<TextField
										name='State'
										style={{ width: 100 }}
										defaultValue={selectedRef.current.State || ''}
										className='text-field'
										id='state'
										label='State'
										required={selectedRef.current.TechnicianID ? false : true}
										type='text'
									/>
									<TextField
										name='Zip'
										style={{ width: 100 }}
										defaultValue={selectedRef.current.Zip || ''}
										className='text-field'
										id='zip'
										label='Zip'
										required={selectedRef.current.TechnicianID ? false : true}
										type='text'
									/>
									<div style={{ marginLeft: 80 }}>
										<label
											style={{
												color: 'rgba(0, 0, 0, 0.54)',
												fontSize: 9,
												position: 'absolute',
											}}
										>
											Shipping Method
										</label>
										<div className='search-by' style={{ width: 88 }}>
											<select
												id='shipping'
												defaultValue={selectedRef.current.Shipping || 'Any'}
												style={{ marginTop: 14 }}
											>
												{ShippingOptions.map((opt, index) => (
													<option key={index} value={opt}>
														{opt}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							</div>

							<div className='form-field-container'>
								<TextField
									name='Notes'
									defaultValue={selectedRef.current.Notes || ''}
									fullWidth
									className='notes-field'
									multiline
									id='notes'
									label='Notes'
									variant='outlined'
								/>
							</div>
							{userState.user.role && selected.TechnicianID ? (
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Button className='secondary' onClick={handleDelete}>
										Delete
									</Button>
									<Divider orientation='vertical' flexItem />
									<Button className='primary' onClick={handleSave}>
										Save
									</Button>
								</div>
							) : userState.user.role && !selected.TechnicianID ? (
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Divider orientation='vertical' flexItem />
									<Button className='primary' onClick={handleAdd}>
										Create
									</Button>
								</div>
							) : null}
						</div>
					</Modal>
				) : null}

				<CustomTable
					searchable
					paginate
					loading_data={Loading}
					search={Search}
					clear_search={ClearSearch}
					search_options={SearchOptions}
					headers={TableHeaders}
					rows={getTableData()}
					table_buttons={RenderTableButtons()}
					onClick={RowClick}
				/>
			</div>
		</div>
	);
};

export default TechnicianTable;
