//Commonly Used Formatting Functions
import dayjs from 'dayjs';

const API_KEY = process.env.MAPS_API_KEY;

const BOX_SIZES = [
	{ rolls: 1, size: '6x6x5' },
	{ rolls: 2, size: '12x6x5' },
	{ rolls: 3, size: '18x6x6' },
	{ rolls: 4, size: '12x12x5' },
	{ rolls: 6, size: '18x12x6' },
	{ rolls: 8, size: '12x12x12' },
];

export const formatDriveTime = (minutes) => {
	if (minutes === 'Not Found') {
		return 'Not Found';
	}

	const hours = Math.floor(minutes / 60);
	const remainingMinutes = Math.floor(minutes % 60);

	if (hours === 0) {
		return `${remainingMinutes} minutes`;
	}

	return `${hours} hour ${remainingMinutes} minutes`;
};

/**
 *   @author John Anthony
 *   @param {String} dateIn expects string date like 2021-01-01
 *   @returns - {String} returns string date 2021-12-31
 */
export const formatDateToYMD = (dateIn) => {
	try {
		return dayjs(dateIn, 'MM/DD/YYYY').format('YYYY-MM-DD');
	} catch (error) {
		console.error(`ERROR: TextFormattingFunctions.formatDateToYMD: ${error} - ${dayjs().format()}`);
	}
};

/**
 * Method that converts a javascript date object into a date string in the form MM/DD/YYYY HH:mm A.M.
 * @author John Anthony
 * (DateToMDY(date)) // returns date in MM/DD/YYYY HH:mm A.M.
 * @param {date} dateIn - javascript date object
 * @returns - {string} Date in the form of MM/DD/YYYY HH:mm A.M.
 */
export const DateToLocale = (dateIn) => {
	try {
		const formattedDate = dayjs(dateIn).format('YYYY-MM-DD h:mm A');
		return formattedDate;
	} catch (error) {
		console.error(`TextFormattingFunctions.DateToLocale: ${error.message} - ${new Date()}`);
		throw error;
	}
};

//Formats date to Thursday Jan. 25, 2018
export const FormatFullDate = (passedDate) => {
	const weekDay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	return weekDay[passedDate.getDay()] + ' ' + FormatDate(passedDate);
};

// Formats date to Thur. Feb. 16
export const FormatFullShortDate = (passedDate) => {
	const weekDay = ['Sun.', 'Mon.', 'Tues.', 'Wed.', 'Thur.', 'Fri.', 'Sat.'];
	return weekDay[passedDate.getDay()] + ' ' + FormatShortDate(passedDate);
};

//Seperates a JSON Object to a multi line String and removes any [ " or , characters]
export const FormatMultiLineText = (stringJSONMultiLine) => {
	if (stringJSONMultiLine === '[]') {
		return '';
	}

	const backToJson = JSON.parse(stringJSONMultiLine);

	if (backToJson) {
		const outputHoursString = [];
		for (let k = 0; k < backToJson.length; k++) {
			outputHoursString.push(JSON.stringify(backToJson[k]).replace(/"/g, ''));
		}
		return outputHoursString.join('\n');
	}
	return '';
};

//Formats number to (123) 456-7890
export const FormatPhoneNumber = (phoneNumber) => {
	if (!phoneNumber || phoneNumber === 'null') {
		return '';
	}

	phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
	return phoneNumber;
};

export const RenderPaperJobsForPDF = (jobs) => {
	return jobs
		.filter((e) => e.KioskStatus == 'Open')
		.map((job) => {
			return {
				kiosk: `${job.KioskID} ${job.ServerID}`,
				store_name: job.StoreName,
				store_address: job.StoreAddress,
				city: job.City,
				new_year: job.NewYear ? job.NewYear : false,
				paper_type: RenderListOfPaperTypes(job.Type),
				star_rolls: parseInt(job.StarRolls),
				star_install: job.StarInstall ? true : false,
				service_dates: { after: job.AfterDate, before: job.BeforeDate },
			};
		});
}; // <-- Add this closing brace

export const ParsePaperForAllLabels = (jobs, tech, job_id, total_paper) => {
	let count = 0;
	return jobs
		.filter((e) => e.KioskStatus == 'Open')
		.map((job) => {
			// iterate through each kiosk in paper job
			const paper = RenderListOfPaperTypes(job.Type).split(',');
			const has_star = paper.includes('Star') ? true : false;
			let star_added = false;
			return paper.reduce((all_labels, type) => {
				const [sticker, PaperQty] = type.trim().split(' ');
				if (sticker.trim() !== 'Star') {
					// create sticker label data
					const star_rolls = Math.abs(parseInt(job.StarRolls));
					count++;

					all_labels.push({
						kiosk: `${job.KioskID} ${job.ServerID}`,
						store_name: job.StoreName,
						store_address: job.StoreAddress,
						city: job.City,
						new_year: job.NewYear ? job.NewYear : false,
						paper_type: sticker,
						qty: parseInt(PaperQty.trim().replace(/[()]/g, '')),
						// qty: parseInt(PaperQty),
						service_dates: { after: job.AfterDate, before: job.BeforeDate },
						technician: tech,
						job_id: job_id,
						roll_index: `${count}/${total_paper}`,
					});
					if (star_rolls && !has_star && !star_added) {
						//('adding star rolls for stickers')
						star_added = true;
						for (let i = 0; i < star_rolls; i++) {
							count++;
							all_labels.push({
								kiosk: `${job.KioskID} ${job.ServerID}`,
								store_name: job.StoreName,
								store_address: job.StoreAddress,
								city: job.City,
								new_year: job.NewYear ? job.NewYear : false,
								paper_type: 'Star',
								qty: 690,
								service_dates: { after: job.AfterDate, before: job.BeforeDate },
								technician: tech,
								job_id: job_id,
								roll_index: `${count}/${total_paper}`,
							});
						}
					}
				} else {
					// separate star roll label data
					if (!star_added) {
						const star_rolls = Math.abs(parseInt(job.StarRolls));
						//(`adding star rolls for star`)
						for (let i = 0; i < star_rolls; i++) {
							count++;
							all_labels.push({
								kiosk: `${job.KioskID} ${job.ServerID}`,
								store_name: job.StoreName,
								store_address: job.StoreAddress,
								city: job.City,
								new_year: job.NewYear ? job.NewYear : false,
								paper_type: sticker,
								qty: 690,
								service_dates: { after: job.AfterDate, before: job.BeforeDate },
								technician: tech,
								job_id: job_id,
								roll_index: `${count}/${total_paper}`,
							});
						}
					}
				}
				return all_labels;
			}, []);
		})
		.flatMap((label) => label);
};

export const parseForLabels = (_data, _rows, _cols) => {
	let _row = [];

	const _result = _data.reduce((_final, job) => {
		if (_row.length < _rows * _cols) {
			_row.push({
				kiosk: job.kiosk,
				type: job.paper,
				qty: job.qty,
			});
		} else {
			_final.push(_row);
			_row = [];
			_row.push({
				kiosk: job.kiosk,
				type: job.paper,
				qty: job.qty,
			});
		}

		return _final;
	}, []);

	if (_row.length) {
		_result.push(_row);
	}

	return _result;
};

export const SeparateJobs = (jobs) => {
	const containers = [];
	let paper = [];
	let total_paper = 0;
	let added_rolls = false;

	jobs.forEach((job) => {
		const types = job.paper_type.split(',');
		types.forEach((type) => {
			const [paper, PaperQty] = type.trim().split(' ');
			if (paper.trim() != 'Star') {
				// const quantity = parseInt(PaperQty);
				const quantity = parseInt(PaperQty.trim().replace(/[()]/g, ''));
				total_paper += quantity / 600;
				total_paper += !added_rolls ? Math.abs(job.star_rolls) : 0;
				added_rolls = true;
			} else if (types.length === 1 && paper.trim() == 'Star') {
				// if job only contains star rolls
				total_paper += Math.abs(job.star_rolls);
			}
		});

		paper.push(job);
		const sum = Math.ceil(total_paper);
		const PAPER_LETTERS = paper.length >= 8; // checks for paper letters to prints
		const OUT_LABELS = paper.length >= 12; // checks for out labels to print
		if (sum >= 8 || PAPER_LETTERS || OUT_LABELS) {
			// when paper letters fail to print, alter this information 4, 6, 8, 10, 12
			if (sum > 5 && sum < 8) {
				// must split into multiple boxes
				const sum2 = Number(Math.ceil((sum % 4).toString().split('.')));
				let box_size = BOX_SIZES.find((size) => size.rolls === sum2);
				box_size = box_size ? box_size.size : 'NA';
				containers.push([...paper, { box: `${BOX_SIZES[3].size} | ${box_size}` }]); // given we should be able to fit 4 into one box
			} else if (sum > 8) {
				const [whole, remainder] = (sum / 4).toString().split('.');
				const box_size = parseInt(whole) > 1 ? `${BOX_SIZES[3].size} | `.repeat(parseInt(whole)) : BOX_SIZES[3].size;
				containers.push([...paper, { box: remainder ? `${box_size}${BOX_SIZES[0].size}` : box_size }]); // given we should be able to fit 4 into one box
			} else {
				// can use a single box
				let box_size = BOX_SIZES.find((size) => size.rolls === sum);
				box_size = box_size ? box_size.size : 'NA';
				containers.push([...paper, { box: box_size }]);
			}

			paper = [];
			total_paper = 0;
		}

		added_rolls = false;
	});

	// check if total paper is between 4 and 8
	if (paper.length) {
		const sum = Math.ceil(total_paper);

		if (sum > 4 && sum < 8) {
			// must split into multiple boxes
			//   const sum2 = sum % 4;
			const sum2 = Number(Math.ceil((sum % 4).toString().split('.')));
			let box_size = BOX_SIZES.find((size) => size.rolls === sum2);
			box_size = box_size ? box_size.size : 'NA';
			containers.push([...paper, { box: `${BOX_SIZES[3].size} | ${box_size}` }]); // given we should be able to fit 4 into one box
		} else if (sum >= 8) {
			const [whole, remainder] = (sum / 4).toString().split('.');
			const box_size = parseInt(whole) > 1 ? `${BOX_SIZES[3].size} | `.repeat(parseInt(whole)) : BOX_SIZES[3].size;
			containers.push([...paper, { box: remainder ? `${box_size}${BOX_SIZES[0].size}` : box_size }]); // given we should be able to fit 4 into one box
		} else {
			// can use a single box
			let box_size = BOX_SIZES.find((size) => size.rolls === sum);
			box_size = box_size ? box_size.size : 'NA';
			containers.push([...paper, { box: box_size }]);
		}
	}

	paper = null;
	return containers;
};

export const RenderListOfPaperTypes = (paper) => {
	const paper_list = [];

	paper.forEach((type) => {
		if (type.PaperType && type.PaperType != 'Star') {
			if (type.PaperQty) {
				if (!type.Splice) {
					paper_list.push(
						!type.Pull ? `${type.PaperType} (${type.PaperQty})` : `${type.paperType} (Pull ${type.PaperQty})`
					);
				} else {
					paper_list.push(`${type.PaperType} (${type.PaperQty}. Splice to current roll.)`);
				}
			}
		} else {
			paper_list.push(`${type.PaperType}`);
		}
	});

	return paper_list.join(', ');
};

//Formats The Store Hours in Kiosk Alert
//Mon-Fri 7:00 AM - 10:00 PM
//Sat 8:00 AM - 8:00 PM
export const FormatHours = (stringJSONHours) => {
	if (!stringJSONHours || stringJSONHours === '[]') {
		return '';
	}
	const backToJson = JSON.parse(stringJSONHours);
	if (!backToJson || backToJson.length === 0) {
		return '';
	}
	let outputHoursString = JSON.stringify(backToJson[0]).replace(/"/g, '');
	for (let k = 1; k < backToJson.length; k++) {
		outputHoursString = outputHoursString.concat('\n' + JSON.stringify(backToJson[k]).replace(/"/g, ''));
	}
	return outputHoursString;
};

//Formats Date to Jan. 01, 1000
export const FormatBetweenDates = (stringDate) => {
	const afterDate = FormatDate(stringDate.substr(1, 10));
	const beforeDate = FormatDate(stringDate.substr(14, 10));

	return `${afterDate} - ${beforeDate}`;
};

//Formats Date to Jan. 01, 1000
export const FormatDate = (stringDate) => {
	if (stringDate && stringDate != '') {
		const date = new Date(stringDate); // This returns 0:00 GMT-0500

		if (date.getFullYear() < 2015) {
			return '';
		}

		const monthNames = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
		const dateToPass = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`; // changed from getMonth... etc

		if (dateToPass === 'Jan. 1, 1000') {
			return '';
		}
		return dateToPass;
	}
	return '';
};

//Formats Date to Jan. 01, 1000
export const FormatDate2 = (stringDate) => {
	if (stringDate && stringDate !== '') {
		let date = new Date(stringDate); // This returns 0:00 GMT-0500
		const templocal = `${
			date.getMonth() + 1
		}-${date.getDate()}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
		date = new Date(templocal + 'z');

		if (date.getFullYear() < 2015) {
			return '';
		}

		const monthNames = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
		const dateToPass = monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear(); // changed from getMonth... etc

		if (dateToPass === 'Jan. 1, 1000') {
			return '';
		}
		return dateToPass;
	}
	return '';
};

export const FormatLogDate = (stringDate) => {
	if (stringDate && stringDate !== '') {
		const date = new Date(stringDate); // This returns 0:00 GMT-0500

		if (date.getFullYear() < 2015) {
			return '';
		}

		const monthNames = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
		const dateToPass = `${monthNames[date.getUTCMonth()]} ${date.getUTCDate()}, ${date.getUTCFullYear()}`; // changed from getMonth... etc

		if (dateToPass === 'Jan. 1, 1000') {
			return '';
		}
		return dateToPass;
	}
	return '';
};

//Formats Date to Jan. 01
export const FormatShortDate = (stringDate) => {
	if (stringDate) {
		const date = new Date(stringDate);

		const monthNames = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
		const dateToPass = `${monthNames[date.getMonth()]} ${date.getDate()}`;

		if (dateToPass === 'Jan. 1') {
			return '';
		}
		return dateToPass;
	}
	return '';
};

/**
 * Method that converts a javascript date object into a date string in the form YYYY-MM-DD
 * @author Michael Miller
 * DateToYMD(date) // returns date in YYYY-MM-DD
 * @param {date} dateIn - javascript date object
 * @returns - {string} Date in the form of YYYY-MM-DD
 */
export const DateToYMD = (dateIn) => {
	try {
		const year = dateIn.getFullYear();
		let month = dateIn.getMonth() + 1;
		let date = dateIn.getDate();

		date = date.toString().length == 1 ? `0${date}` : date;
		month = month.toString().length == 1 ? `0${month}` : month;

		return `${year}-${month}-${date}`;
	} catch (error) {
		console.error(`ERROR: TextFormattingFunctions.DateToYMD: ${error} - ${new Date()}`);
	}
};

/**
 * Method that converts a time string into hours and minutes
 * @author Michael Miller
 * (FormatTimeIntoHM(time)) // returns '1 hour 2 minutes'
 * @param {string} time - time expressed as a string
 * @returns - {string} string representation of the time in hours and minutes
 */
export const FormatTimeIntoHM = (time = '00:00:00') => {
	try {
		const [h = 0, m = 0] = time.split(':');
		const hours = parseInt(h);
		const minutes = parseInt(m);

		return hours && minutes
			? `${hours} hour${hours == 1 ? '' : 's'} ${minutes} minute${minutes == 1 ? '' : 's'}`
			: !hours && minutes
			? `${minutes} minute${minutes == 1 ? '' : 's'}`
			: hours && !minutes
			? `${hours} hour${hours == 1 ? '' : 's'}`
			: 'Now';
	} catch (error) {
		console.error(`ERROR: TextFormattingFunctions.FormatTimeIntoHM: ${error} - ${new Date()}`);
		return time;
	}
};

/**
 * Function that returns the last day of the given month
 * @author Michael Miller
 * @function GetLastDayOfMonth
 * @param {number} month_diff - number of months from current
 * @returns - {number} returns the last day of the given month
 */
export const GetLastDayOfMonth = (month_diff = 0) => {
	try {
		const lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + month_diff, 0);
		return lastDay.getDate();
	} catch (error) {
		console.error(`TextFormattingFunctions.GetLastDayOfMonth: ${error.message} - ${new Date()}`);
		throw error;
	}
};

export const Format24ToLocale = (dateIn = '') => {
	try {
		const [date, time] = dateIn.split(' ');
		const [year, month, day] = date.trim().split('-');
		const [h, m] = time.trim().split(':');

		const meridian = parseInt(h) >= 12 && parseInt(h) <= 23 ? 'pm' : 'am';
		const hours = parseInt(h) == 0 ? '12' : parseInt(h) > 0 && parseInt(h) <= 12 ? parseInt(h) : parseInt(h) - 12;

		return `${month}/${day}/${year} ${hours}:${m} ${meridian}`;
	} catch (error) {
		console.error(`TextFormattingFunctions.Format24ToLocale: ${error} - ${new Date()}`);
		return `N/A`;
	}
};

/**
 * Method that converts a javascript date object into a date string in the form MM/DD/YYYY
 * @author Michael Miller
 * (DateToMDY(date)) // returns date in MM/DD/YYYY
 * @param {date} dateIn - javascript date object
 * @returns - {string} Date in the form of MM/DD/YYYY
 */
export const DateToMDY = (dateIn = new Date()) => {
	try {
		let month = dateIn.getMonth() + 1;
		let date = dateIn.getDate();
		date = date.toString().length == 1 ? `0${date}` : date;
		month = month.toString().length == 1 ? `0${month}` : month;
		return `${month}/${date}/${dateIn.getFullYear()}`;
	} catch (error) {
		console.error(`TextFormattingFunctions.DateToMDY: ${error.message} - ${new Date()}`);
		throw error;
	}
};

/**
 *   @author John Anthony
 *   @param {String} dateIn expects string date like 2021-01-01
 *   @returns - {String} returns string date 2021-12-31
 */
export const FormatDateToYMD = (dateIn) => {
	try {
		const [m, d, y] = dateIn.split('/');
		return `${y}-${m.toLocaleString('default', { month: '2-digit' }).padStart(2, '0')}-${d
			.toString()
			.padStart(2, '0')}`;
	} catch (error) {
		console.error(`TextFormattingFunctions.FormatDateToYMD: ${error.message} - ${new Date()}`);
		throw error;
	}
};

/**
 *   @author John Anthony
 *   @param {String} dateIn expects string date like 2021/01/01
 *   @returns - {String} returns string date 01/01/2021
 */
export const FormatDateToMDY = (dateIn) => {
	try {
		const [y, m, d] = dateIn.split('-');
		return `${m}/${d}/${y}`;
	} catch (error) {
		console.error(`TextFormattingFunctions.FormatDateToMDY: ${error.message} - ${new Date()}`);
		throw error;
	}
};

export const FormatDateToMD = (dateIn) => {
	try {
		const [, m, d] = dateIn.split('-');
		return `${m}/${d}`;
	} catch (error) {
		console.error(`ERROR: TextFormattingFunctions.FormatDateToMD: ${error} - ${new Date()}`);
		return `NA/NA`;
	}
};

export const GetTimeDiffInDays = (startDate = '', endDate = '') => {
	try {
		const d1 = new Date(startDate).getTime();
		const d2 = new Date(endDate).getTime();
		return (d2 - d1) / (1000 * 3600 * 24);
	} catch (error) {
		console.error(`ERROR: TextFormattingFunctions.GetTimeDiffInDays: ${error} - ${new Date()}`);
		return 0;
	}
};

export const FormatLocalDateToUTC = (local_in) => {
	try {
		const utc_time_day = local_in.getUTCDate().toString().padStart(2, '0');
		const utc_time_month = (local_in.getUTCMonth() + 1).toString().padStart(2, '0');
		const utc_time_hours = local_in.getUTCHours().toString().padStart(2, '0');
		const utc_time_minutes = local_in.getUTCMinutes().toString().padStart(2, '0');

		return `${local_in.getUTCFullYear()}-${utc_time_month}-${utc_time_day} ${utc_time_hours}:${utc_time_minutes}:00`;
	} catch (error) {
		console.error(`ERROR: TextFormattingFunctions.FormatLocalDateToUTC: ${error.message} - ${new Date()}`);
		throw error;
	}
};

export const FormatLocaleTo24 = (date_passed = '') => {
	try {
		const [dateIn, timeIn, meridian] = date_passed.split(' ');
		const [month, day, year] = dateIn.trim().split('/');
		const [hours, minutes] = timeIn.trim().split(':');

		return `${year}-${month}-${day} ${
			meridian == 'AM' || (meridian === 'PM' && hours === '12') ? hours : parseInt(hours) + 12
		}:${minutes}:00`;
	} catch (error) {
		console.error(`ERROR: TextFormattingFunctions.FormatLocaleTo24: ${error} - ${new Date()}`);
		return 'N/A';
	}
};

export const FormatEmailTemplateTime = (time_in) => {
	try {
		const [h, m] = time_in.split(':');
		const meridien = parseInt(h) >= 12 && parseInt(h) < 24 ? 'P.M.' : 'A.M.';
		const hours = meridien == 'P.M.' && parseInt(h) > 12 ? parseInt(h) - 12 : parseInt(h);
		return `${hours}:${m} ${meridien}`;
	} catch (error) {
		console.error(`TextFormattingFunctions.FormatEmailTemplateTime: ${error.message} - ${new Date()}`);
		throw error;
	}
};

//Formats Time to 11:00 AM
export const FormatTime = (stringDate) => {
	if (stringDate && stringDate !== '') {
		const date = new Date(stringDate);
		if (date.getFullYear() < 2015) {
			return '';
		}

		let hours = date.getHours();
		let minutes = date.getMinutes();
		const ampm = hours >= 12 ? 'PM' : 'AM';

		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? '0' + minutes : minutes;
		const strTime = `${hours}:${minutes} ${ampm}`;

		if (stringDate === null) {
			return '';
		}

		return strTime;
	}
	return '';
};

export const CheckJSON = (json) => {
	try {
		// check if incoming string is json or plain text
		return JSON.parse(json);
	} catch (error) {
		console.error(`ERROR: TextFormattingFunctions.CheckJSON: ${error} - ${new Date()}`);
		return json;
	}
};

// Test Function To FormatTime correctly
export const FormatTime2 = (stringDate) => {
	if (stringDate && stringDate !== '') {
		const date = new Date(stringDate);
		if (date.getFullYear() < 2015) {
			return '';
		}

		let hours = date.getUTCHours();
		let minutes = date.getUTCMinutes();
		const ampm = hours >= 12 ? 'PM' : 'AM';

		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? '0' + minutes : minutes;
		const strTime = `${hours}:${minutes} ${ampm}`;

		if (stringDate === null) {
			return '';
		}

		return strTime;
	}
	return '';
};

//12/1 at 4:04PM
export const FormatDateTimeWithoutYear = (stringDate) => {
	if (stringDate) {
		const date = new Date(stringDate);

		let hours = date.getHours();
		let minutes = date.getMinutes();
		const ampm = hours >= 12 ? 'PM' : 'AM';

		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? '0' + minutes : minutes;

		const dateToPass = `${date.getMonth() + 1}/${date.getDate()} at ${hours}:${minutes} ${ampm}`;

		return dateToPass;
	}
	return '';
};

//Format Date to MM/dd/yyyy
export const FormatLocalDateFromMonth = (stringDate) => {
	const date = new Date(stringDate);

	const dateToPass = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
	return dateToPass;
};

//Format Date to yyyy-MM-dd
export const FormatDateFromYear = (stringDate) => {
	const date = new Date(stringDate);
	let day = '';
	let month = '';

	if (date.getUTCMonth() < 9) {
		month = '0';
	}

	if (date.getUTCDate() < 10) {
		day = '0';
	}
	const dateToPass = `${date.getUTCFullYear()}-${month}${date.getUTCMonth() + 1}-${day}${date.getUTCDate()}`;

	return dateToPass;
};

//Format Date to yyyy-MM-dd
export const FormatDateFromYear2 = (stringDate) => {
	if (!stringDate.includes('T')) {
		return stringDate;
	}

	const date = new Date(stringDate);
	let month = '';
	let day = '';

	if (date.getMonth() < 9) {
		month = '0';
	}

	if (date.getDate() < 10) {
		day = '0';
	}

	const dateToPass = `${date.getFullYear()}-${month}${date.getMonth() + 1}-${day}${date.getDate()}`;

	return dateToPass;
};

//Format Time to hh:mm:ss
export const FormatLocalTimeTwentyFour = (stringDate) => {
	const date = new Date(stringDate);
	let hours = '';
	let minutes = '';
	let seconds = '';

	if (date.getHours() < 10) {
		hours = '0';
	}

	if (date.getMinutes() < 10) {
		minutes = '0';
	}

	if (date.getSeconds() < 10) {
		seconds = '0';
	}

	const timeToPass = `${hours}${date.getHours()}:${minutes}${date.getMinutes()}:${seconds}${date.getSeconds()}`;
	return timeToPass;
};

//Format Time to hh:mm
export const FormatLocalTimeTwentyFourNoSeconds = (stringDate) => {
	const date = new Date(stringDate);

	let hours = '';
	let minutes = '';

	if (date.getHours() < 10) {
		hours = '0';
	}

	if (date.getMinutes() < 10) {
		minutes = '0';
	}

	const timeToPass = `${hours}${date.getHours()}:${minutes}${date.getMinutes()}`;
	return timeToPass;
};

//Format Time to hh:mm:ss
export const FormatTimeTwentyFour = (stringDate) => {
	const date = new Date(stringDate);

	let hours = '';
	let minutes = '';
	let seconds = '';

	if (date.getUTCHours() < 10) {
		hours = '0';
	}

	if (date.getUTCMinutes() < 10) {
		minutes = '0';
	}

	if (date.getUTCSeconds() < 10) {
		seconds = '0';
	}

	const timeToPass = `${hours}${date.getUTCHours()}:${minutes}${date.getUTCMinutes()}:${seconds}${date.getUTCSeconds()}`;
	return timeToPass;
};

//Format DateTime to yyyy-mm-dd hh:mm:ss
export const FormatDateTimeFromYear = (stringDate) => {
	const dateToPass = FormatDateFromYear(stringDate);
	const timeToPass = FormatTimeTwentyFour(stringDate);

	return `${dateToPass} ${timeToPass}`;
};

//Format DateTime to mm/dd/yyyy hh:mm:ss
export const FormatLocalDateTimeFromMonth = (stringDate) => {
	if (stringDate) {
		const dateToPass = FormatLocalDateFromMonth(stringDate);
		const timeToPass = FormatTime(stringDate);

		return `${dateToPass} ${timeToPass}`;
	} else {
		return null;
	}
};

//Formats Date and Time to Jan. 01, 1000 12:00 PM
export const FormatDateTime = (stringDate) => {
	const date = new Date(stringDate);
	let hours = date.getHours();
	let minutes = date.getMinutes();
	const ampm = hours >= 12 ? 'PM' : 'AM';

	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	const strTime = `${hours}:${minutes} ${ampm}`;

	const monthNames = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
	const dateToPass = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

	if (stringDate === null) {
		return '';
	}
	return `${dateToPass} ${strTime}`;
};

export const FormatDateTimeLocal = (date_in) => {
	try {
		// 04/03/2021 2:07 PM
		if (date_in) {
			const [date, time, meridiem] = date_in.split(' ');
			let [month, day, year] = date.trim().split('/');
			let [hours, minutes] = time.trim().split(':');

			hours =
				meridiem.trim() === 'AM' || (meridiem.trim() === 'PM' && parseInt(hours) === 12) ? hours : parseInt(hours) + 12;
			hours = hours.toString().length === 1 ? `0${hours}` : hours;
			minutes = minutes.toString().length === 1 ? `0${minutes}` : minutes;
			month = month.toString().length === 1 ? `0${month}` : month;
			day = day.toString().length === 1 ? `0${day}` : day;

			return `${year}-${month}-${day}T${hours === '24' ? '00' : hours}:${minutes}`;
		} else {
			return null;
		}
	} catch (error) {
		console.error(`TextFormattingFunctions.FormatDateTimeLocal: ${error.message} - ${new Date()}`);
		throw error;
	}
};

//Formats Date and Time to Jan. 01, 1000 12:00 PM
export const FormatDateTime1 = (stringDate) => {
	const date = new Date(stringDate);
	let hours = date.getHours();
	let minutes = date.getMinutes();
	const ampm = hours >= 12 ? 'PM' : 'AM';

	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;

	const strTime = `${hours}:${minutes} ${ampm}`;
	const monthNames = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
	const dateToPass = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

	if (stringDate === null) {
		return '';
	}
	return `${dateToPass} ${strTime}`;
};

//Formats any Blank Information to N/A
export const FormatNA = (stringPassed) => {
	if (stringPassed === '' || stringPassed === '-' || !stringPassed) {
		return '';
	}

	return stringPassed;
};

//2018-01-01
export const GetTodaysDate = (date) => {
	let dateFormat = '';

	dateFormat += date.getFullYear();

	dateFormat += '-';
	if (date.getMonth() + 1 < 10) {
		dateFormat += '0';
	}

	dateFormat += date.getMonth() + 1;
	dateFormat += '-';

	if (date.getDate() < 10) {
		dateFormat += '0';
	}

	dateFormat += date.getDate();

	return dateFormat; // date formated to year-month-date
};

export const CreateKioskTechs = (drive_times) => {
	const primary = drive_times.filter((e) => e.DriveTime <= 40).map((obj) => `"${obj.TechName}"`);

	const backup = drive_times
		.filter((e) => e.DriveTime > 40 && e.DriveTime <= 60)
		.map((obj) => `"${obj.TechName}"`)
		.filter((tech) => !primary.includes(tech));

	const tier3 = drive_times
		.filter((e) => e.DriveTime > 60 && e.DriveTime <= 90)
		.map((obj) => `"${obj.TechName}"`)
		.filter((tech) => !primary.includes(tech) && !backup.includes(tech));

	const last_ditch = drive_times
		.filter((e) => e.DriveTime > 90 && e.DriveTime <= 120)
		.sort((a, b) => a.DriveTime - b.DriveTime)
		.map((obj) => `"${obj.TechName}"`)
		.filter((tech) => !primary.includes(tech) && !backup.includes(tech) && !tier3.includes(tech));

	const main_primary = primary.length ? primary : backup.length ? backup : tier3.length ? tier3 : last_ditch;
	let main_secondary = [];

	if (main_primary === primary) {
		main_secondary = backup.length ? backup : tier3.length ? tier3 : last_ditch;
	} else if (main_primary === backup) {
		main_secondary = tier3.length ? tier3 : last_ditch;
	} else if (main_primary === tier3) {
		main_secondary = last_ditch;
	}

	// Ensure primary and secondary are different
	const unique_secondary = main_secondary.filter((tech) => !main_primary.includes(tech));

	return { primary: main_primary, backup: unique_secondary, paper_changer: main_primary[0] };
};

export const UpdateKioskTechs = (drive_times, kiosks) => {
	const final = [];

	for (let i = 0; i < drive_times.length; i++) {
		const driveTimes = drive_times[i].DriveTime;
		const primaries = JSON.parse(kiosks[i].primaries).map((tech) => {
			return `"${tech.replace(/"/g, '')}"`;
		});
		const backups = JSON.parse(kiosks[i].backups).map((tech) => {
			return `"${tech.replace(/"/g, '')}"`;
		});
		let _type = null;

		if (driveTimes <= 35) {
			// set as primary
			primaries.push(`"${drive_times[i].TechName}"`);
			_type = 'primary';
		} else if (driveTimes > 35 && driveTimes <= 60) {
			backups.push(`"${drive_times[i].TechName}"`);
			_type = 'secondary';
		}

		final.push({
			kiosk: drive_times[i].KioskID,
			type: _type,
			primary: [...primaries],
			backup: [...backups],
		});
	}

	return final;
};

export const UpdateObjectCoordinates = async (forTable, passedObj) => {
	let kiosk_id = null;
	let address = null;
	let city = null;
	let state = null;
	let zip = null;

	if (forTable === 'kiosk') {
		kiosk_id = passedObj.KioskID;
		address = passedObj.Address;
		city = passedObj.City;
		state = passedObj.State;
		zip = passedObj.Zip;
	} else if (forTable === 'technicians') {
		kiosk_id = passedObj.StoreID;
		address = passedObj.Address;
		city = passedObj.City;
		state = passedObj.State;
		zip = passedObj.Zip;
	}

	// ensure we have all the necessary information
	if (kiosk_id && address && city && state && zip) {
		const formatAddress = `${encodeURIComponent(address)},+${encodeURIComponent(city)},+${encodeURIComponent(state)}`;
		const URL = `https://maps.googleapis.com/maps/api/geocode/json?address=${formatAddress}&key=${API_KEY}`;

		// the code in the try catch is not correct, this is a save point to check another branch.
		// delete the info in the try block
		try {
			const response = await fetch(URL);
			const data = await response.json();
			const coordinates = data.results[0].geometry.location;

			return {
				kiosk_id: kiosk_id,
				lat: coordinates.lat,
				lng: coordinates.lng,
			};
		} catch (error) {
			console.error(`ERROR: TextFormattingFunctions.UpdateObjectCoordinates: ${error} - ${new Date()}`);
			throw error;
		}
	}
};

export const GetFriday = (direction) => {
	const todaysDate = new Date();
	const todaysDay = todaysDate.getDay() % 7;
	if (direction === 'previous') {
		const subtractDays = todaysDay + 0;
		return FormatDateFromYear(todaysDate.setDate(todaysDate.getDate() - subtractDays));
	} else if (direction === 'next') {
		const addDays = 6 - todaysDay;
		return FormatDateFromYear(todaysDate.setDate(todaysDate.getDate() + addDays));
	} else if (direction === 'last') {
		const subtractDays = todaysDay + 0;
		return FormatShortDate(todaysDate.setDate(todaysDate.getDate() - subtractDays));
	}
};

// takes input such as 12:00am or 1:00pm and converts it to its 24hour
export const ConvertTwelveToTwentyFour = (time) => {
	if (time[time.length - 2] === 'p') {
		// if time is pm

		time = time.split('pm');
		time = time[0].split(':'); // get leading number

		if (time[0] === 12) {
			// 12pm == 12:00:00
			time = '12:00:00';
		} else {
			time = parseInt(time[0]) + 12;
			time = `${time}:00:00`;
		}
	} else {
		// if time is am

		time = time.split('am');
		time = time[0].split(':'); // get leading number

		if (time[0] === 12) {
			// 12am == 00:00:00
			time = '00:00:00';
		} else {
			time = parseInt(time[0]);

			if (time > 9) {
				// past 9am? don't add leading 0
				time = `${time}:00:00`;
			} else {
				time = `0${time}:00:00`;
			}
		}
	}
	return time;
};
