import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import '../../StyleSheets/Calendar.css';
import PropTypes from 'prop-types';

function renderEvent(request, index, startIndex) {
	const backgroundColor = request.color || 'gray';
	const textColor = 'white';
	const startDate = new Date(request.start);
	const endDate = new Date(request.end);
	const daysSpan = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)); // Calculate days span correctly
	const startDay = startDate.getDate();
	const startOffset = (startDay - startIndex) % 7;
	const width = Math.min(daysSpan, 7 - startOffset) * 100;
	const slot = request.slot || 0; // Add slot property to handle stacking

	return {
		width: daysSpan === 1 ? '100%' : `${width}%`, // Ensure single-day requests are contained within the day
		background: backgroundColor,
		color: textColor,
		top: `${slot * 25 + 20}px`, // Adjust top position based on slot with more spacing and margin for day number
		borderRadius: '4px',
		margin: '4px 0',
		padding: '2px 0px',
		cursor: 'pointer',
		position: 'absolute',
		border: '1px solid black',
		left: `${startOffset * 100}%`,
	};
}

const CalendarBody = ({ TableHeaders, calendarMonth, loading, handleCalendarClick, userState }) => (
	<TableContainer className='table-data-container'>
		<Table>
			<TableHead>
				<TableRow className='table-header-row'>
					{TableHeaders.map((header) => (
						<TableCell key={header} className='table-header-cell' align='center'>
							{header}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{!loading ? (
					calendarMonth.map((week, idx) => (
						<TableRow key={idx} style={{ position: 'relative' }}>
							{week.map((day, index) => (
								<TableCell
									id={day.day}
									onClick={
										day.type === 'current'
											? (e) => {
													e.stopPropagation();
													handleCalendarClick(day.day, null);
											  }
											: null
									}
									className={`calendar-cell ${day.today ? 'today' : ''} ${day.type !== 'current' ? 'faded-cell' : ''}`}
									key={`${day.day}-${index}`}
									align='center'
								>
									<div
										id={day.day}
										className={`calendar-block ${
											day.requests && day.requests.length && day.requests[0]?.title?.includes('EVENT')
												? 'block-event'
												: ''
										}`}
									>
										<div
											id={day.day}
											className={`inner-block-container ${
												day.requests && day.requests.length && day.requests[0]?.title?.includes('EVENT')
													? ''
													: 'align-start'
											}`}
										>
											<div id={day.day} className='calendar-date-container'>
												<label className={`calendar-date ${day.type !== 'current' ? 'faded' : ''}`}>{day.day}</label>
											</div>
											{day.requests && day.requests.length
												? day.requests
														.filter((req) => req && req.title)
														.map((req, req_idx) => (
															<Tooltip
																key={`${req.title}-${req_idx}`}
																title={
																	userState.user.permissions === 'master' || req.uid === userState.user.uid ? (
																		<p style={{ fontSize: 12 }}>{req.notes}</p>
																	) : (
																		''
																	)
																}
															>
																<div
																	id={req.id}
																	style={renderEvent(req, index, day.day)}
																	className={
																		req.type === 'start' ? 'block-start' : req.type === 'end' ? 'block-end' : null
																	}
																	onClick={(e) => {
																		e.stopPropagation();
																		handleCalendarClick(day.day, req);
																	}}
																>
																	<div id={req.id}>
																		<label id={req.id} className='event-label'>
																			{`${req.title && req.status != 'Approved' ? req.status.toUpperCase() : ''} ${
																				req.title
																			}`}
																		</label>
																	</div>
																</div>
															</Tooltip>
														))
												: null}
										</div>
									</div>
								</TableCell>
							))}
						</TableRow>
					))
				) : (
					<TableRow key={0}>
						<TableCell align='center' colSpan={TableHeaders.length}>
							<CircularProgress />
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	</TableContainer>
);

CalendarBody.propTypes = {
	TableHeaders: PropTypes.array.isRequired,
	calendarMonth: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	handleCalendarClick: PropTypes.func.isRequired,
	userState: PropTypes.object.isRequired,
	onOpenRequest: PropTypes.func.isRequired,
};

export default CalendarBody;
