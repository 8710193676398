import React, { useState, useEffect, useRef } from 'react';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { AiOutlineLeft, AiOutlineClose, AiOutlineSend } from 'react-icons/ai';
import { MdMoreHoriz } from 'react-icons/md';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
import ChatImg from '../../../public/Images/chat.png';
import CloseImg from '../../../public/Images/multiply-white.png';
import SearchImg from '../../../public/Images/search-black.png';
import ChatRecievedMsg from '../../../public/Sounds/ChatRecieved.mp3';
import JobResponse from '../../../public/Sounds/JobResponse.mp3';
import { CurrentTime } from '../API/Moment';
import '../../StyleSheets/Chat.css';
import '../../StyleSheets/SavedChats.scss';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import PropTypes from 'prop-types';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(LocalizedFormat);

const image_base = 'https://s3.us-west-2.amazonaws.com/rti.images/Tech-Images/';

/**
 * ChatModal component handles the chat interface, including displaying messages,
 * sending messages, and handling new incoming messages.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.allChatPerms - Permission to access all chat.
 * @param {Function} props.onAlert - Function to handle alert messages.
 */
const ChatModal = ({ allChatPerms, onAlert }) => {
	const [ModalOpen, setModalOpen] = useState(false);
	const [AllMessages, setAllMessages] = useState(true);
	const [Loading, setLoading] = useState(false);
	const [MessageSending, setMessageSending] = useState(false);
	const [, setAllChat] = useState(false);
	const [MessageList, setMessageList] = useState([]);
	const [User] = useState(localStorage.getItem('user'));
	const [FullName, setFullName] = useState('');
	const [Messages, setMessages] = useState([]);
	const [TechSelected, setTechSelected] = useState({ name: '', id: 0 });
	const [Unread, setUnread] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null);
	const [loadMore, setLoadMore] = useState(false);
	const [numPages, setNumPages] = useState(1);
	const messagesEndRef = useRef(null);
	const [state] = useState({
		ModalOpen: false,
		AllMessages: true,
		Loading: false,
		MessageSending: false,
		AllChat: false,
		MessageList: [],
		User: localStorage.getItem('user'),
		FullName: '',
		Messages: [],
		TechSelected: { name: '', id: 0 },
		Unread: 0,
		snackbar: { visible: false, message: '', severity: 'success' },
		anchorEl: null,
		loadMore: false,
		numPages: 1,
	});

	/**
	 * Scrolls the chat to the bottom.
	 */
	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		if (!Loading && ModalOpen && !loadMore && numPages === 1) {
			scrollToBottom();
		}
	}, [Messages, Loading, ModalOpen, loadMore, numPages]);

	useEffect(() => {
		const username = localStorage.getItem('user');
		getUserFullName(username);

		socket.on('newMessage', handleNewMessage);
		socket.on('newResponse', handleNewResponse);

		return () => {
			socket.off('newMessage', handleNewMessage);
			socket.off('newResponse', handleNewResponse);
		};
	}, [ModalOpen, TechSelected]);

	useEffect(() => {
		const username = localStorage.getItem('user');
		GetSavedChats(username, 'all-messages', null, null);
	}, []);

	/**
	 * Fetches the full name of the user.
	 *
	 * @param {string} username - The username of the user.
	 */
	const getUserFullName = (username) => {
		socket.emit('getUserFullName', username, (full_name) => {
			if (!full_name) {
				console.error('There was an issue getting this users full name');
			} else {
				setFullName(full_name[0].FullName);
			}
		});
	};

	/**
	 * Handles new incoming messages.
	 *
	 * @param {string} tech - The technician sending the message.
	 * @param {boolean} isIncoming - Whether the message is incoming.
	 * @param {string} snippet - A snippet of the message.
	 */
	const handleNewMessage = (tech, isIncoming, snippet) => {
		if (isIncoming) {
			const audio = document.getElementById('ChatRecievedMsg');
			document.getElementById('ChatRecievedMsg').muted = false;
			audio.play();

			const technician = MessageList.find((e) => e.name === tech);
			if (technician) {
				if (ModalOpen && technician.tech_id === TechSelected.id) {
					GetMessagesForTech(technician, Unread);
				} else if (!ModalOpen && technician.tech_id === TechSelected.id) {
					GetMessagesForTech(technician, Unread);
				} else if (
					(!ModalOpen && !AllMessages && technician.tech_id !== TechSelected.id) ||
					(ModalOpen && !AllMessages && technician.tech_id !== TechSelected.id)
				) {
					GetSavedChats(User, 'notify', technician, snippet);
				} else {
					GetSavedChats(User, 'all-messages', technician, snippet);
					socket.emit('updateUnreadCount', technician.tech_id, (results) => {
						if (results) {
							setUnread(results.unreadCount);
						}
					});
				}
			}
		}
	};

	/**
	 * Handles new job responses.
	 *
	 * @param {string} tech - The technician sending the response.
	 * @param {boolean} isJob - Whether the response is job-related.
	 * @param {string} snippet - A snippet of the response.
	 */
	const handleNewResponse = (tech, isJob, snippet) => {
		if (isJob) {
			const audio = document.getElementById('responseRecievedAudio');
			document.getElementById('responseRecievedAudio').muted = false;
			audio.play();

			const technician = MessageList.find((e) => e.name === tech);
			if (ModalOpen && technician.tech_id === TechSelected.id) {
				GetMessagesForTech(technician, Unread);
			} else if (!ModalOpen && technician.tech_id === TechSelected.id) {
				GetMessagesForTech(technician, Unread);
			} else if (
				(!ModalOpen && !AllMessages && technician.tech_id !== TechSelected.id) ||
				(ModalOpen && !AllMessages && technician.tech_id !== TechSelected.id)
			) {
				GetSavedChats(User, 'notify', technician, snippet);
			} else {
				GetSavedChats(User, 'all-messages', technician, snippet);
				socket.emit('updateUnreadCount', tech, (results) => {
					if (results) {
						setUnread(results.unreadCount);
					}
				});
			}
		}
	};

	/**
	 * Fetches saved chats for the user.
	 *
	 * @param {string} username - The username of the user.
	 * @param {string} type - The type of chat to fetch.
	 * @param {Object} tech - The technician object.
	 * @param {string} snippet - A snippet of the message.
	 */
	const GetSavedChats = (username, type, tech, snippet) => {
		socket.emit('getSavedChats', username, (messages, techs, snippets) => {
			if (!messages) {
				console.error('There was an issue getting saved chats');
				return;
			}

			const message_list = JSON.parse(messages);

			const updateSnippet = (msg, snippet) => {
				if (snippet) {
					msg.snippet = `${snippet.replace(/[\n\t\r]/g, '')}...`;
				}
			};

			const findSnippet = (msg) => {
				const _snippet = snippets.find((_tech) => _tech.from === msg.tech_id);
				if (_snippet) {
					msg.snippet = `${_snippet.snippet.replace(/[\n\t\r]/g, '')}...`;
				}
			};

			message_list.forEach((msg) => {
				if (tech && msg.tech_id === tech.tech_id) {
					updateSnippet(msg, snippet);
				} else {
					findSnippet(msg);
				}
			});

			const processMessages = (message_list, tech, snippet) => {
				let unread = 0;
				const temp = [];
				let found = false;

				message_list.forEach((message) => {
					if (tech.tech_id === message.tech_id) {
						found = true;
						unread += message.unreadCount + 1;
						message.unreadCount++;
						updateSnippet(message, snippet);
					} else {
						unread += message.unreadCount;
						findSnippet(message);
					}
				});

				if (!found) {
					unread++;
					temp.push({
						tech_id: tech.tech_id,
						name: tech.name,
						unreadCount: 1,
						snippet: `${snippet.replace(/[\n\t\r]/g, '')}...`,
					});
				}

				return { final_list: [...message_list, ...temp], unread };
			};

			const { final_list, unread } = tech
				? processMessages(message_list, tech, snippet)
				: {
						final_list: message_list,
						unread: message_list.reduce((total, message) => total + message.unreadCount, 0),
				  };

			final_list.sort((a, b) => b.unreadCount - a.unreadCount);
			SaveChats(final_list, username, type, unread, techs);
		});
	};
	/**
	 * Saves chats for the user.
	 *
	 * @param {Array} message_list - The list of messages.
	 * @param {string} user - The username of the user.
	 * @param {string} type - The type of chat to save.
	 * @param {number} unread - The number of unread messages.
	 * @param {Array} techs - The list of technicians.
	 */
	const SaveChats = (message_list = [], user = '', type = '', unread = 0, techs = []) => {
		socket.emit('saveChats', JSON.stringify(message_list), user, (response) => {
			if (!response) {
				console.error('There was an issue updating users saved chats');
			} else {
				if (type === 'notify') {
					setUnread(unread);
				} else if (type === 'all-messages') {
					setMessages(message_list);
					setUnread(unread);
					setMessageList(techs);
				} else if (type === 'back') {
					setMessages(message_list);
					setTechSelected({ name: '', id: 0 });
					setMessageList(techs);
					setAllMessages(true);
					setLoading(false);
					setNumPages(1);
				}
			}
		});
	};

	/**
	 * Toggles the chat modal visibility.
	 */
	const ModalHandler = () => {
		setModalOpen(!ModalOpen);
		document.querySelectorAll('#chat-modal').forEach((element) => {
			element.style.display = !ModalOpen ? 'block' : 'none';
		});
	};

	/**
	 * Handles the back button click event.
	 */
	const BackButton = () => {
		setLoading(true);
		setMessages([]);
		setNumPages(1);
		setLoadMore(false);
		GetSavedChats(User, 'back', null);
	};

	/**
	 * Renders the list of messages.
	 *
	 * @returns {JSX.Element} The rendered message list.
	 */
	const RenderMessageList = () => {
		return MessageList.map((tech) => <option key={tech.tech_id} id={tech.tech_id}>{`${tech.name}`}</option>);
	};

	/**
	 * Renders the message body.
	 *
	 * @returns {JSX.Element} The rendered message body.
	 */
	const RenderMessageBody = () => {
		if (AllMessages) {
			const all_messages = Messages.map((message) => (
				<tr key={message.tech_id} id={message.tech_id}>
					<td id={message.tech_id}>
						<div className='all-message-body-container' id={message.tech_id}>
							<div id={message.tech_id} className='flex-box'>
								<div className='flex-column flex-just-center' id={message.tech_id}>
									{message.unreadCount ? <span className='new-message-badge'>{message.unreadCount}</span> : null}
								</div>
								<div className='message-body2 message-body2-clickable' id={message.tech_id} onClick={GetTechMessages}>
									<div className='message-name' id={message.tech_id}>
										{message.name}
									</div>
									<label className='message-snippet' id={message.tech_id} onClick={GetTechMessages}>
										{message.snippet}
									</label>
								</div>
								<div id={message.tech_id} className='message-remove-button'>
									<Button id={message.tech_id} color='rti-close' onClick={(e) => UpdateSavedChats(e, 'remove')}>
										&times;
									</Button>
								</div>
							</div>
						</div>
					</td>
				</tr>
			));

			const all_chat = (
				<tr key={`all_chat_button`} id={`all_chat_button`}>
					<td id={`all_chat_button`}>
						<div className='all-message-body-container' id={`all_chat_button`}>
							<div className='message-body message-body-clickable' id={`all_chat_button`} onClick={GetTechMessages}>
								<div className='message-name' id={`all_chat_button`}>
									All Chat
								</div>
							</div>
						</div>
					</td>
				</tr>
			);

			if (allChatPerms) {
				return [all_chat, ...all_messages];
			} else {
				return all_messages;
			}
		} else {
			const _messages = Messages.map((message) => {
				const date = dayjs(new Date(message.date)).local().format('YYYY-MM-DD HH:mm:ss');
				if (TechSelected.name === 'All Chat') {
					const _all_chat = message.from === 'Auto Msg' || message.from === 'Mobile Mode';

					return (
						<tr key={message.chat_id}>
							<td>
								<div>
									{!_all_chat ? (
										<div className='from-message-signature'>
											{message.from} {`${date}`}
										</div>
									) : (
										<div className='to-message-signature'>{`${date}`}</div>
									)}
									<div className={_all_chat ? 'from-message-body-container' : 'to-message-body-container'}>
										<div className='message-body'>
											<div className='message-body-text'>{message.body}</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
					);
				} else {
					return (
						<tr key={message.chat_id}>
							<td>
								<div>
									{message.from !== state.TechSelected.name ? (
										<div className='from-message-signature'>
											{message.from} {`${date}`}
										</div>
									) : (
										<div className='to-message-signature'>{`${date}`}</div>
									)}
									<div
										className={
											TechSelected.name === 'All Chat' || message.from === TechSelected.name
												? 'from-message-body-container'
												: 'to-message-body-container'
										}
									>
										<div className='message-body'>
											<div className='message-body-text'>{message.body}</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
					);
				}
			});

			_messages.unshift(
				<tr key='load-more'>
					<td className='flex-just-even'>
						{!loadMore ? (
							<MuiButton className='rti-blue-round' variant='contained' onClick={onLoadMore}>
								load more
							</MuiButton>
						) : (
							<CircularProgress />
						)}
					</td>
				</tr>
			);

			return (
				<>
					{_messages}
					<tr ref={messagesEndRef}></tr>
				</>
			);
		}
	};

	/**
	 * Loads more messages.
	 */
	const onLoadMore = () => {
		const _pages = numPages + 1;
		setLoading(false);
		setLoadMore(true);
		setNumPages(_pages);

		if (TechSelected.id !== 'all') {
			const tech_unread = {
				tech_id: TechSelected.id,
				name: TechSelected.name,
			};
			GetMessagesForTech(tech_unread, Unread, Messages, false);
		} else {
			GetMessagesForTech('all', Unread, Messages, false);
		}
	};

	/**
	 * Fetches messages for a specific technician.
	 *
	 * @param {Event} e - The event object.
	 */
	const GetTechMessages = (e) => {
		const tech = e.target.id;
		const messages = Messages;
		if (tech !== 'all_chat_button') {
			const techId = Number(tech); // Convert tech to an integer
			const tech_unread = messages.filter((e) => {
				return e.tech_id === techId;
			})[0];
			const unread = Unread - tech_unread.unreadCount;
			setLoading(true);
			setMessages([]);
			GetMessagesForTech(tech_unread, unread, messages, true);
		} else {
			setLoading(true);
			setMessages([]);
			GetMessagesForTech('all', Unread, messages, false);
		}
		setTimeout(() => {
			document.querySelectorAll('#scrollable-table').forEach((element) => {
				element.scrollTop = document.getElementById('scroll-body').scrollHeight;
			});
		}, 100);
	};

	/**
	 * Fetches messages for a specific technician.
	 *
	 * @param {Object|string} tech - The technician object or 'all' for all messages.
	 * @param {number} unread - The number of unread messages.
	 * @param {Array} saved_messages - The list of saved messages.
	 * @param {boolean} message_read - Whether the message is read.
	 */
	const GetMessagesForTech = (tech, unread, saved_messages, message_read) => {
		socket.emit('getMessagesForTech', tech === 'all' ? 'all' : tech.tech_id, message_read, numPages, (messages) => {
			if (!messages) {
				console.error('There was an issue getting messages from this tech.');
				setLoading(false);
				setMessages([]);
			} else {
				if (message_read) {
					const list_to_update = saved_messages
						.map((message) =>
							message.tech_id === tech.tech_id && message.saved
								? { ...message, unreadCount: 0 }
								: message.tech_id === tech.tech_id && !message.saved
								? null
								: message
						)
						.filter((message) => message !== null);

					socket.emit('saveChats', JSON.stringify(list_to_update), User, (response) => {
						if (!response) {
							console.error('There was an issue updating users saved chats');
						} else {
							setLoading(false);
							setLoadMore(false);
							setAllMessages(false);
							setUnread(unread);
							setTechSelected((prev) => ({ ...prev, name: tech.name, id: tech.tech_id }));
							setMessages(messages);
							if (numPages === 1) {
								document.querySelectorAll('#scrollable-table').forEach((element) => {
									element.scrollTop = document.getElementById('scroll-body').scrollHeight;
								});
							}
						}
					});
				} else {
					if (tech === 'all') {
						setLoading(false);
						setLoadMore(false);
						setAllMessages(false);
						setUnread(unread);
						setTechSelected((prev) => ({ ...prev, name: 'All Chat', id: 'all' }));
						setMessages(messages);
						if (numPages === 1) {
							document.querySelectorAll('#scrollable-table').forEach((element) => {
								element.scrollTop = document.getElementById('scroll-body').scrollHeight;
							});
						}
					} else {
						setLoading(false);
						setLoadMore(false);
						setAllMessages(false);
						setUnread(unread);
						setTechSelected((prev) => ({ ...prev, name: tech.name, id: tech.tech_id }));
						setMessages(messages);
						if (numPages === 1) {
							document.querySelectorAll('#scrollable-table').forEach((element) => {
								element.scrollTop = document.getElementById('scroll-body').scrollHeight;
							});
						}
					}
				}
			}
		});
	};

	/**
	 * Updates the saved chats.
	 *
	 * @param {Event} e - The event object.
	 * @param {string} type - The type of update ('add' or 'remove').
	 */
	const UpdateSavedChats = (e, type) => {
		if (type === 'add') {
			const name = document.getElementById('chat-select').value;
			const valid = Messages.filter((e) => e.name === name);
			const tech = MessageList.find((tech) => tech.name === name);

			if (name && tech) {
				const list_to_update = [...Messages, { tech_id: tech.tech_id, name: name, unreadCount: 0, saved: true }];
				if (!valid.length) {
					socket.emit('saveChats', JSON.stringify(list_to_update), User, (response) => {
						if (!response) {
							console.error('There was an issue updating users saved chats');
						} else {
							document.getElementById('chat-select').value = '';
							setMessages(list_to_update);
						}
					});
				} else {
					onAlert({
						message: 'WARNING: Cannot add chat because it has already been saved to your list.',
						severity: 'warning',
					});
				}
			} else {
				onAlert({
					message:
						'WARNING: Technicians name cannot be blank.  Please make sure that you select the chat that you would like to add and try again.',
					severity: 'warning',
				});
			}
		} else if (type === 'remove') {
			const user = User;
			const tech = e.target.id;
			const tech_unread = Messages.filter((e) => e.tech_id === Number(tech))[0];
			const list_to_update = Messages.filter((e) => e.tech_id !== Number(tech));
			const unread = Unread - tech_unread.unreadCount;

			socket.emit('saveChats', JSON.stringify(list_to_update), user, (response) => {
				if (!response) {
					console.error('There was an issue updating users saved chats');
				} else {
					socket.emit('clearTechsUnreadMessages', tech, (response) => {
						if (!response) {
							console.error('There was an issue clearing this technicians unread chat messages.');
							setMessages(list_to_update);
							setUnread(unread);
						} else {
							setMessages(list_to_update);
							setUnread(unread);
						}
					});
				}
			});
		}
	};

	/**
	 * Handles the send button click event.
	 *
	 * @param {Event} e - The event object.
	 */
	const ClickSend = (e) => {
		if (e.keyCode === 13) {
			document.getElementById('send-message-button').click();
		}
	};

	/**
	 * Sends a message.
	 */
	const SendMessage = async () => {
		const message = document.getElementById('message-input').value;

		if (message) {
			document.getElementById('message-input').value = '';
			setMessageSending(true);

			if (TechSelected.id === 'all') {
				socket.emit('SelectTechsForAllChat', (returnObject) => {
					const technicians = returnObject;
					const message_to_send = {
						body: message,
						from: User,
						time: CurrentTime(),
					};

					fetch('/api/send_message_all', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ messages: message_to_send, techs: technicians }),
					}).then(() => {
						socket.emit('getMessagesForTech', 'all', false, numPages, (messages) => {
							if (!messages) {
								console.error('There was an issue getting messages from this tech.');
								setMessageSending(false);
							} else {
								setAllMessages(false);
								setAllChat(false);
								setMessages(messages);
								setMessageSending(false);
								document.querySelectorAll('#scrollable-table').forEach((element) => {
									element.scrollTop = document.getElementById('scroll-body').scrollHeight;
								});
							}
						});
					});
				});
			} else {
				socket.emit('getTechNumber', TechSelected.name, (tech_number) => {
					if (!tech_number) {
						console.error('There was an issue getting this technicians phone number');
						setMessageSending(false);
					} else {
						const text_message = {
							body: message.trim(),
							to: tech_number,
							from: FullName,
							time: CurrentTime(),
							type: 'tech',
						};
						fetch('/api/send_message_async', {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({ messages: text_message }),
						})
							.then((response) => {
								if (response.status === 200) {
									socket.emit('getMessagesForTech', TechSelected.id, false, numPages, (messages) => {
										if (!messages) {
											console.error('There was an issue getting messages from this tech.');
											setMessageSending(false);
										} else {
											setAllMessages(false);
											setMessages(messages);
											setMessageSending(false);
											setLoading(false);
											document.querySelectorAll('#scrollable-table').forEach((element) => {
												element.scrollTop = document.getElementById('scroll-body').scrollHeight;
											});
										}
									});
								} else {
									onAlert({
										message: 'ERROR: There was an issue sending this message to this technician',
										severity: 'error',
									});

									setMessageSending(false);
								}
							})
							.catch((error) => {
								onAlert({
									message: 'ERROR: There was an issue sending this message to this technician',
									severity: 'error',
								});
								setMessageSending(false);
								throw error;
							});
					}
				});
			}
		} else {
			onAlert({
				message: 'WARNING: The message you are trying to send cannot be blank.',
				severity: 'warning',
			});
		}
	};

	/**
	 * Clears all messages.
	 */
	const handleClearAllMessages = () => {
		socket.emit('saveChats', JSON.stringify([]), User, (response) => {
			if (!response) {
				console.error('There was an issue updating users saved chats');
				onAlert({
					message: 'ERROR: Server encountered an issue trying to clear messages.  Please notify the developer.',
					severity: 'error',
				});
			} else {
				setMessages([]);
				setUnread(0);
				setNumPages(1);
				onAlert({
					message: 'Success!  Messages have been cleared.',
					severity: 'success',
				});
			}
		});
	};

	/**
	 * Opens the menu.
	 *
	 * @param {Event} event - The event object.
	 */
	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	/**
	 * Closes the menu.
	 */
	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	/**
	 * Renders the chat header.
	 *
	 * @returns {JSX.Element} The rendered chat header.
	 */
	const RenderHeader = () => {
		return (
			<div className='chat-header-container' id='chat-header-container'>
				{!AllMessages ? (
					<div className='chat-header' id='chat-header'>
						<IconButton onClick={BackButton} className='icon-button'>
							<AiOutlineLeft />
						</IconButton>
						<div id='tech-avatar' className='tech-avatar'>
							<Avatar variant='circular' src={`${image_base}${TechSelected.id}.jpg`}>{`${
								TechSelected.name.split(' ')[0][0]
							}${TechSelected.name.split(' ')[1][0]}`}</Avatar>
						</div>
						<div className='tech-name-container'>
							<label className='chat-header-name' id='chat-header-name'>
								{TechSelected.name}
							</label>
						</div>
						<IconButton onClick={ModalHandler} className='icon-button'>
							<AiOutlineClose />
						</IconButton>
					</div>
				) : (
					<div className='chat-header' id='chat-header'>
						<IconButton onClick={handleMenuOpen} className='icon-button'>
							<MdMoreHoriz />
						</IconButton>
						<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
							<MenuItem onClick={handleClearAllMessages}>Clear Messages</MenuItem>
						</Menu>
						<div className='messages-title-container'>
							<h6 className='messages-title'>Messages</h6>
						</div>
						<IconButton onClick={ModalHandler} className='icon-button'>
							<AiOutlineClose />
						</IconButton>
					</div>
				)}
			</div>
		);
	};

	/**
	 * Renders the messaging interface.
	 *
	 * @returns {JSX.Element} The rendered messaging interface.
	 */
	const RenderMessaging = () => {
		let divtorender = null;

		if (!Loading) {
			divtorender = (
				<div className='chat-messages-container'>
					<div className={`message-container ${AllMessages ? 'all-messages' : ''}`} id='message-table'>
						<div className={`scrollable-table ${AllMessages ? 'all-messages' : ''}`} id='scrollable-table'>
							<table className='chat-table' id='chat-table'>
								<tbody id='scroll-body'>{RenderMessageBody()}</tbody>
							</table>
						</div>
					</div>
					{!AllMessages && !MessageSending ? (
						<div className='chat-input-container' id='chat-input-container'>
							<div className='chat-input' id='chat-input'>
								<textarea id='message-input' placeholder='Send a message...' onKeyDown={ClickSend}></textarea>
								<IconButton className='send-message-button' onClick={SendMessage} id='send-message-button'>
									<AiOutlineSend />
								</IconButton>
							</div>
						</div>
					) : !AllMessages && MessageSending ? (
						<div className='chat-input-container' id='chat-input-container'>
							<div className='chat-input' id='chat-input'>
								<Spinner size='small'></Spinner>
							</div>
						</div>
					) : (
						<div className='chat-input-container' id='chat-input-container'>
							<div className='chat-input' id='chat-input'>
								<img className='search-img' src={`${SearchImg}`} width='25px' height='25px'></img>
								<input id='chat-select' list='users' name='message-list' type='text' placeholder='Search...'></input>
								<datalist id='users'>{RenderMessageList()}</datalist>
								<div className='add-button-container'>
									<Button name='add' color='rti-blue' size='small' onClick={(e) => UpdateSavedChats(e, 'add')}></Button>
								</div>
							</div>
						</div>
					)}
				</div>
			);
		} else {
			divtorender = <Spinner margin='chat-margin'></Spinner>;
		}

		return divtorender;
	};

	/**
	 * Renders the chat modal.
	 *
	 * @returns {JSX.Element} The rendered chat modal.
	 */
	const RenderMessages = () => {
		return (
			<div className='chat-modal' id='chat-modal' style={{ maxHeight: '100vh', overflow: 'hidden' }}>
				{RenderHeader()}
				{RenderMessaging()}
			</div>
		);
	};

	return (
		<div className='chat-modal-container'>
			{RenderMessages()}
			<div style={{ paddingTop: '10px', marginLeft: 'auto', width: '63px' }}>
				<Button color='rti-blue' size='medium-circle' onClick={ModalHandler}>
					{!ModalOpen ? <img src={`${ChatImg}`} width='75%'></img> : <img src={`${CloseImg}`} width='75%'></img>}
				</Button>
			</div>
			{Unread ? <span className='message-badge'>{Unread}</span> : null}
			<audio className='ChatRecievedMsg' id='ChatRecievedMsg' src={ChatRecievedMsg} muted={true}></audio>
			<audio id='responseRecievedAudio' src={JobResponse} muted={true}></audio>
		</div>
	);
};

ChatModal.propTypes = {
	allChatPerms: PropTypes.bool.isRequired,
	onAlert: PropTypes.func.isRequired,
};

export default ChatModal;
