// TODO: When selecting a part to send, have it actually ask for the serial/RID number and have it update the related part in the inventory to have the location set to the Kiosk ID of the dispatch.
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { CurrentTime } from '../API/Moment';
import $ from 'jquery';
import '../../StyleSheets/CreateJob.css';

export const CreateJob = (props) => {
	const [AlertIDValue, setAlertIDValue] = useState(null);
	const [KioskIDInputValue, setKioskIDInputValue] = useState('');
	const [, setIssueInputValue] = useState('');
	const [, setTechnicianInputValue] = useState('');
	const [, setPartsValue] = useState('');
	const [partsName, setPartsName] = useState([]);
	const [TrackingNumberValue, setTrackingNumberValue] = useState('N/A');
	const [techDriveTimeList, setTechDriveTimeList] = useState([]);
	const [ItemList, setItemList] = useState([]);
	const [SerialNumber, setSerialNumber] = useState('');
	const [, setIssue] = useState('');
	const [, setAllUsers] = useState([]);
	const [InputOther, setInputOther] = useState(false);
	const [dispatchDirectlyCheckbox, setDispatchDirectlyCheckbox] = useState(false);
	const [sendPartsDirectly, setSendPartsDirectly] = useState(false);

	const parts_to_send = [
		'Cable(s)',
		'Credit Card Reader',
		'Grounding Wire',
		'Harddrive',
		'Modem',
		'Monitor',
		'Motherboard',
		'Paper',
		'Pico Unit',
		'Power Supply 12v',
		'Power Supply 24v',
		'Power Supply 5v',
		'Printer (Datamax)',
		'Printer (Star)',
		'Printer Power Supply',
		'RAM Stick',
		'SSD',
		'USB Hub',
	];

	const reasons_for_dispatch = [
		'Incomplete transaction',
		'Alert without incomplete/internet',
		'Failed reprints',
		'Store called',
		'Customer called',
		'Maintenance',
		'Other',
	];

	useEffect(() => {
		socket.emit('selectAllUsers', (result) => {
			setAllUsers(result);
		});
	}, []);

	useEffect(() => {
		if (props.kioskIDIssueDes !== null) {
			let pattern = '^.{' + props.alertLength + '}';
			let re = new RegExp(pattern, 'g');

			setKioskIDInputValue(props.kioskIDIssueDes.slice(0, 6)); //Sets Imported Kiosk ID from Kiosk Alerts
			setAlertIDValue(props.kioskIDIssueDes.slice(6, props.alertLength)); //Sets Imported Alert ID from Kiosk Alerts
			setIssueInputValue(props.kioskIDIssueDes.replace(re, '')); //Sets Imported Issue from Kiosk Alerts
		}
	}, [props.kioskIDIssueDes, props.alertLength]); // Only re-run the effect if these values change

	useEffect(() => {
		techAndDriveTime();
	}, [props.driveTimes, KioskIDInputValue]);

	const DisplayList = (e) => {
		switch (e.target.className) {
			case 'TechnicianInput':
				document.getElementById('TableKioskIDList').style.display = 'none';
				document.getElementById('TableIssueList').style.display = 'none';
				document.getElementById('TableTechnicianList').style.display = 'block';
				break;

			//Makes the Background for a selected Part in the List Grey
			case 'ItemList': {
				setPartsValue(e.target.id);
				const partsListArray = document.getElementsByClassName('ItemList');
				for (let j = 0; j < partsListArray.length; j++) {
					partsListArray[j].style.backgroundColor = '#ffffff';
				}
				document.getElementById(e.target.id).style.backgroundColor = '#808080';

				break;
			}
		}
	};

	const DispatchDirectlyHandler = (event) => {
		const { checked } = event.target;

		setDispatchDirectlyCheckbox(checked);

		if (dispatchDirectlyCheckbox) {
			const kiosk_id = document.getElementById('KioskIDInput').value;
			// TODO: Have this function set the state of the dispatchDirectly checkbox. Then in the return statement for the JSX, have it only show the tech input if the state is true.
			setKioskIDInputValue(kiosk_id);
			document.getElementById('TechnicianInput').style.display = 'block';
		} else {
			document.getElementById('TechnicianInput').style.display = 'none';
			setTechnicianInputValue('');
		}
	};

	//Displays or hides the Parts list and Tracking number Input
	const SendPartsDirectlyCheckBox = (event) => {
		const { checked } = event.target;
		setSendPartsDirectly(checked);
		if (sendPartsDirectly) {
			setIssueInputValue('Needs New Parts');
		} else {
			setTrackingNumberValue('N/A');
			setIssueInputValue('');
			setPartsName(null);
		}
	};

	//Adds the Current Item in Select to the Parts List
	const AddToList = () => {
		let items = [...ItemList]; // create a copy of ItemList
		let displayVal = '';
		let selectValue = document.getElementById('SendPartsSelect').value;

		SerialNumber === '' ? (displayVal = selectValue) : (displayVal = selectValue + ' : ' + SerialNumber);

		// Check if the item is already in the array
		if (!items.includes(displayVal)) {
			items.push(displayVal);
			setSerialNumber('');
			setItemList(items);
			setPartsName(items);
		}
	};

	//When a Parts List Item is Selected (Grey) Clicking the Minus Button Removes it From the List
	const RemoveFromList = () => {
		const new_item_list = [...ItemList];
		new_item_list.pop();
		setItemList(new_item_list);
	};

	//Displays two boxes for VIN and Plate Number
	const CustomerAffectedCheckBox = () => {
		document.getElementById('TableTechnicianList').style.display = 'none';

		try {
			if (document.getElementById('CustomerAffectedCheckBox').checked) {
				document.getElementById('VINInput').style.display = 'block';
				document.getElementById('PlateInput').style.display = 'block';
			} else {
				document.getElementById('VINInput').style.display = 'none';
				document.getElementById('PlateInput').style.display = 'none';
			}
		} catch (error) {
			`ERROR: CreateJobs.CustomerAffectedCheckBox: ${error} - ${new Date()}`;
		}
	};

	// const SendWarehouseMessage = (jobID, kioskInput) => {
	// 	const users = AllUsers;

	// 	try {
	// 		users.forEach((user) => {
	// 			if (user.Role == 'warehouse user') {
	// 				const message = {
	// 					body:
	// 						'Hi ' +
	// 						user.FullName +
	// 						', \n' +
	// 						'Job: ' +
	// 						jobID +
	// 						' has been dispatched for new parts. \n' +
	// 						'Please update the tracking with the required items to be sent to Kiosk: ' +
	// 						kioskInput +
	// 						'.\n' +
	// 						'Thank You!',
	// 					to: user.FullName,
	// 					from: props.user.fullName,
	// 					time: CurrentTime(),
	// 					type: 'admin',
	// 				};
	// 			}
	// 		});
	// 	} catch (error) {
	// 		`ERROR: CreateJobs.SendWarehouseMessage: ${error} - ${new Date()}`;
	// 	}
	// };

	// const SendWarehouseEmail = (jobID, kioskInput) => {
	// 	const users = AllUsers;
	// 	try {
	// 		users.forEach((user) => {
	// 			if (user.Role == 'warehouse user') {
	// 				const to = user.Email;
	// 				const subject = 'Tracking - Needs New Parts';

	// 				const html =
	// 					`<div width="100%",style="text-align:left;">` +
	// 					`<h1 style="text-align:center; margin-bottom:5%">Kiosk Needs New Parts</h1>` +
	// 					`<p style="text-align:left;">Hi ${user.FullName},</p>` +
	// 					`<p style="text-align:left;">Job: ${jobID} has been dispatched for new parts.  Please update the tracking with the required items to be sent to Kiosk: ${kioskInput}</p>` +
	// 					`<p style="text-align:left;">Thank You!</p>` +
	// 					`</div >`;

	// 				document.querySelectorAll('.some-class').forEach((element) => {
	// 					// ...existing code...
	// 				});
	// 			}
	// 		});
	// 	} catch (error) {
	// 		`ERROR: CreateJobs.SendWarehouseEmail: ${error} - ${new Date()}`;
	// 	}
	// };

	const validateJobInputs = async (kioskInput, _issue, _tech) => {
		if (IsInList(kioskInput.split(' ')[0], props.kioskID) && IsInList(_issue, props.issueDescription)) {
			//Checks if the Tech name inputted exsists. If not, a message is not sent and job not created
			if (dispatchDirectlyCheckbox) {
				if (IsInList(_tech, props.technicianList)) {
					return { valid: true };
				} else {
					props.onAlert({
						message: 'Please enter a valid technician name',
						severity: 'error',
					});
					return { valid: false };
				}
			}

			//Checks if there is atleast one Item in the parts list and a tracking number attached
			if (sendPartsDirectly) {
				if (partsName[0]) {
					return { valid: true };
				} else {
					props.onAlert({
						message: 'The Parts List and/or Tracking Number are invalid or empty.',
						severity: 'error',
					});
					return { valid: false };
				}
			}
		} else {
			props.onAlert({
				message: 'Please enter a valid Kiosk ID and Issue.',
				severity: 'error',
			});
			return { valid: false };
		}

		return { valid: true };
	};

	const getNextJobID = async () => {
		try {
			const res = await new Promise((resolve, reject) => {
				socket.emit('getLastJobID', (res) => {
					if (!res) {
						reject(new Error('No response from getLastJobID'));
					} else {
						resolve(res);
					}
				});
			});
			//SET JOBID
			const dateYearDigit = new Date().getFullYear() % 10;
			let jobID = res;
			const jobIDLetter = String.fromCharCode(81 + dateYearDigit);
			if (!jobID || jobID[0] !== jobIDLetter) {
				jobID = jobIDLetter + '0001';
			} else {
				jobID = jobIDLetter + String(parseInt(1 + jobID.slice(1, 5)) + 1).slice(1, 5);
			}
			return jobID;
		} catch (error) {
			console.error(`ERROR: CreateJob.getNextJobID: ${error} on ${new Date()}`);
			throw error;
		}
	};

	//checks if a passed string is in a passed array
	const IsInList = (passedValue, passedArray) => {
		if (passedValue) {
			if (passedArray) {
				for (let j = 0; j < passedArray.length; ++j) {
					if (passedArray[j].Description) {
						if (
							passedArray[j].Description.toLowerCase().substring(0, passedValue.length) === passedValue.toLowerCase()
						) {
							return true;
						}
					} else {
						if (passedArray[j].toLowerCase().substring(0, passedValue.length) === passedValue.toLowerCase()) {
							return true;
						}
					}
				}
			}
		}
		return false;
	};

	const insertJob = async (objectToInsertIntoCreateJob) => {
		try {
			const boolean = await new Promise((resolve) => {
				socket.emit('insertCreateJob', objectToInsertIntoCreateJob, (boolean) => {
					resolve(boolean);
				});
			});
			if (boolean) {
				//If its attached to an alert, Have alert go to next step and refresh alerts
				if (AlertIDValue) {
					setAlertIDValue(null);
					setIssue(null);
					setDispatchDirectlyCheckbox(false);
					setSendPartsDirectly(false);
					await axios.post('/api/refreshAlert');
				}

				document.getElementById('CreateButton').disabled = false;

				if (document.getElementById('CustomerAffectedCheckBox').checked === true) {
					const customerAffected = 'A customer is affected. \n';
					const customerVIN = 'VIN: ' + document.getElementById('VINInput').value + '\n';
					const customerPlate = 'Plate: ' + document.getElementById('PlateInput').value + '\n';
				}

				//Clear Create Job Text Fields
				setKioskIDInputValue('');
				setIssueInputValue('');
				setTechnicianInputValue('');
				setPartsValue('');
				setPartsName([]);
				setTrackingNumberValue('');
				setDispatchDirectlyCheckbox(false);
				setSendPartsDirectly(false);

				props.onAlert({
					message: 'Job Dispatched Successfully!',
					severity: 'success',
				});

				document.getElementById('other-reason') ? (document.getElementById('other-reason').value = '') : null;
				document.getElementById('dispatch-reason').value = 'Incomplete transaction';
				$('#CreateJobDialog').hide();
				await axios.post('/api/refreshJob');
			} else {
				//Notify user of Failure
				props.onAlert({
					message: 'Oops! Looks like you did not fill out the form correctly. Please try again.',
					severity: 'warning',
				});
				document.getElementById('CreateButton').disabled = false;
			}
		} catch (error) {
			console.error(`ERROR: CreateJob.insertJob: ${error}`);
		}
	};

	// Determines who the message is being sent to and gathers the contact information depending on if they are a primary or a backup
	const decodeBase64Response = (response) => {
		try {
			// Check if the response matches base64 format (starts with "base64:type")
			if (typeof response === 'string' && response.startsWith('base64:type')) {
				const encodedData = response.split(':')[2];
				const decodedString = atob(encodedData);
				return JSON.parse(decodedString);
			}
			// If it's not base64 encoded, return the response as is
			return response;
		} catch (error) {
			console.error('Error handling response:', error);
			return null;
		}
	};

	const getMessageRecipients = async (techListToPass, kioskInput) => {
		try {
			const messagesToSend = await new Promise((resolve, reject) => {
				const getTechList = (techList) => {
					socket.emit('getTechListToSendMsg', kioskInput.replace(/'/g, ''), techList, (res) => {
						if (!res) {
							reject(new Error('No response from getTechListToSendMsg'));
						} else {
							if (res.length === 0 && techList === 'PrimaryTechs') {
								getTechList('BackupTechs');
							} else if (res.length === 0 && techList === 'BackupTechs') {
								props.onAlert({
									message: 'WARNING! There are no Primary or Backup technicians available for this kiosk. Please directly dispatch technicians for this job.',
									severity: 'warning',
								});
								reject(new Error('No technicians available'));
							} else {
								 // Handle both encoded and non-encoded responses
								const processedMessages = res.map(msg => decodeBase64Response(msg)).filter(Boolean);
								resolve(processedMessages);
							}
						}
					});
				};
				getTechList(techListToPass);
			});
			return messagesToSend;
		} catch (error) {
			console.error(`CreateJob.getMessageRecipients: ${error}`);
			throw error;
		}
	};

	// Sends a text message with job details to the primary/backup techs if a technician is not dispatched directly.
	const notifyTechs = async (techListToPass, kioskInput, serverID, _issue, jobID, objectToInsertIntoCreateJob) => {
		const messagesToSend = await getMessageRecipients(techListToPass, kioskInput);
		const KioskID_Loc = props.kiosksInfo.find((el) => el.KioskID == kioskInput.substring(0, 6));
		objectToInsertIntoCreateJob.KioskID_Loc = KioskID_Loc.KioskID_Loc;
		objectToInsertIntoCreateJob.MessagesSentTo = messagesToSend;
		await insertJob(objectToInsertIntoCreateJob);
		const msgs = messagesToSend.map((msg) => ({
			body: `Hi ${
				msg?.Name?.split(' ')[0]
			}, \nKiosk: ${kioskInput} needs a visit for a(n) ${_issue}\nPlease reply with your Job# and ETA. \nExample: ${jobID} 8:30PM \nYour Job# is: ${jobID}.\nThank You!`,
			to: msg.Number,
			from: props.user.fullName,
			time: CurrentTime(),
			type: 'tech',
		}));

		$.post('/api/send_message_bulk', { messages: msgs }, (res) => {
			if (!res) {
				console.error(`ERROR: CreateJob.api/send_message: Error sending messages to technicians for this job.`);
			}
		});
	};

	//Saves the Job to the Database
	const CreateJobBtn = async () => {
		// const itemsToSend = [];
		// const itemsToReceive = [];
		// const partTracking = '';
		const kioskInput = document.getElementById('KioskIDInput').value.toUpperCase();
		const _issue = document.getElementById('IssueInput').value;
		const _tech = document.getElementById('TechnicianInput').value;
		const dispatch_reason = InputOther
			? document.getElementById('other-reason').value
			: document.getElementById('dispatch-reason').value;
		const valid = await validateJobInputs(kioskInput, _issue, _tech);
		//If all fields are cleared to send the appropriate information, this sends it!
		if (valid.valid) {
			const jobID = await getNextJobID();

			document.getElementById('CreateButton').disabled = true;

			const techListToPass = dispatchDirectlyCheckbox ? _tech : 'PrimaryTechs';

			const serverID = props.kioskServerID[kioskInput];

			const objectToInsertIntoCreateJob = {
				JobID: jobID,
				KioskID: kioskInput.replace(/'/g, ''),
				Issue: _issue,
				Dispatcher: props.user.fullName,
				Technician: null,
				PartsList: partsName,
				TrackingNumber: TrackingNumberValue,
				AlertID: AlertIDValue,
				Reason: dispatch_reason,
			};
			await notifyTechs(techListToPass, kioskInput, serverID, _issue, jobID, objectToInsertIntoCreateJob);
		}
	};

	//Closes job modal and resets values to null
	const CreateJobClose = (e) => {
		setKioskIDInputValue(''); //Auto Filled If Opened From Alerts
		setIssueInputValue(''); //Auto Filled If Opened From Alerts
		setTechnicianInputValue(''); //Send Message To Primarys then backups if blank
		setPartsValue('');
		setPartsName([]);
		setTrackingNumberValue('N/A');
		setItemList([]);
		setInputOther(false);
		setSerialNumber('');
		DisplayList(e);
		setDispatchDirectlyCheckbox(false);
		setSendPartsDirectly(false);
		document.getElementById('SendPartsDirectlyCheckBox').checked = false;
		document.getElementById('DispatchDirectlyCheckBox').checked = false;
		document.getElementById('other-reason') ? (document.getElementById('other-reason').value = '') : null;
		document.getElementById('dispatch-reason').value = 'Incomplete transaction';
		document.getElementById('SendPartsContainer').style.display = 'none';
		document.getElementById('TechnicianInput').style.display = 'none';
		document.getElementById('KioskIDInput').value = '';
		document.getElementById('IssueInput').value = '';
		$('#CreateJobDialog').hide();
	};

	const handleKioskSelect = (event) => {
		const { value } = event.target;
		const selectedKioskID = value.slice(0, 6);
		setKioskIDInputValue(selectedKioskID);
	};

	const techAndDriveTime = () => {
		const result = props.driveTimes
			.filter((dt) => dt.KioskID === KioskIDInputValue)
			.map((dt) => {
				const temp = dt.DriveTime.split(' ');
				return temp.length > 2
					? {
							Name: dt.TechName,
							Time: parseInt(temp[0] * 60) + parseInt(temp[2]),
							Display: `${dt.TechName}, ${parseInt(temp[0] * 60) + parseInt(temp[2])} mins`,
					  }
					: {
							Name: dt.TechName,
							Time: parseInt(temp[0]),
							Display: `${dt.TechName}, ${parseInt(temp[0])} mins`,
					  };
			})
			.sort((a, b) => a.Time - b.Time);

		setTechDriveTimeList(result);
	};

	// const SerialInputHandler = (e) => {
	// 	// For eventual use with entering in a specific item that will be sent to the kiosk
	// 	const serial = e.target.value;
	// 	setSerialNumber(serial);
	// };

	const handleDispatchReason = (event) => {
		const val = event.target.value;
		if (val === 'Other') {
			setInputOther(true);
		} else {
			setInputOther(false);
		}
	};

	return (
		<div style={{ width: 310 }} className='CustomDialog' id='CreateJobDialog'>
			<h1 className='CreateJobTitle'>Dispatch A Job</h1>
			<div style={{ height: 54 }} className='SecondLayer'>
				<h3>Kiosk</h3>
				<h3 className='HeaderThree'>
					<input
						style={{ margin: 0, width: 200 }}
						id='KioskIDInput'
						list='kiosk-list'
						name='kiosk-list-dropdown'
						placeholder='Search Kiosk...'
						onChange={handleKioskSelect}
					></input>
					<datalist id='kiosk-list'>
						{props.kiosksInfo.map((kiosk) => (
							<option
								key={kiosk.KioskID}
								value={`${kiosk.KioskID} ${kiosk.ServerID}`}
							>{`${kiosk.KioskID} ${kiosk.ServerID}`}</option>
						))}
					</datalist>
				</h3>
				<h3>
					<input
						className='CustomerAffectedCheckBox'
						id='CustomerAffectedCheckBox'
						type='checkbox'
						onClick={CustomerAffectedCheckBox}
						style={{ display: 'none' }}
					/>
				</h3>
				<input placeholder='VIN' id='VINInput' style={{ display: 'none' }}></input>
				<input placeholder='Plate #' id='PlateInput' style={{ display: 'none' }}></input>
			</div>
			<div style={{ height: 54 }} className='ThirdLayer'>
				<h3>Issue</h3>
				<h3 className='HeaderThree'>
					<select style={{ margin: 0, width: 200 }} id='IssueInput'>
						<option key='-1' value=''>
							Select issue
						</option>
						{props.issueDescription
							.filter((issue) => issue.Status == 'Active')
							.map((issue) => (
								<option key={issue.Description} value={issue.Description}>
									{issue.Description}
								</option>
							))}
					</select>
				</h3>
			</div>
			<div style={{ height: 74 }} className='ThirdLayer'>
				<h3>What prompted the dispatch?</h3>
				<h3 className='HeaderThree'>
					<select id='dispatch-reason' onChange={handleDispatchReason}>
						{reasons_for_dispatch.map((reason) => (
							<option key={reason} value={reason}>
								{reason}
							</option>
						))}
					</select>
				</h3>
				{InputOther ? (
					<h3 style={{ marginTop: 8 }} className='HeaderThree'>
						<input id='other-reason' type='text' placeholder='Other reason...'></input>
					</h3>
				) : null}
			</div>
			<div className='ForthLayer'>
				<h3 className='HeaderThree'>
					<input
						className='DispatchDirectlyCheckBox'
						id='DispatchDirectlyCheckBox'
						type='checkbox'
						onClick={DispatchDirectlyHandler}
					/>
					Dispatch Directly To Tech
					<input
						style={{
							margin: 0,
							width: 200,
							display: dispatchDirectlyCheckbox ? 'block' : 'none',
						}}
						id='TechnicianInput'
						list='tech-list'
						name='tech-list-dropdown'
						placeholder='Search Technician...'
					></input>
					<datalist id='tech-list'>
						{techDriveTimeList.map((tech) => (
							<option key={tech.Name} value={tech.Name}>
								{tech.Display}
							</option>
						))}
					</datalist>
				</h3>
			</div>
			<div className='FifthLayer'>
				<h3 className='HeaderThree'>
					<input
						className='SendPartsDirectlyCheckBox'
						id='SendPartsDirectlyCheckBox'
						type='checkbox'
						onClick={SendPartsDirectlyCheckBox}
					/>
					Send Parts
				</h3>
				<div
					style={{ margin: 0, display: sendPartsDirectly ? 'flex' : null }}
					className='SendPartsContainer'
					id='SendPartsContainer'
				>
					<div className='SendPartsTop'>
						<select className='SendPartsSelect' id='SendPartsSelect' style={{ width: '194px' }}>
							{parts_to_send.map((part) => (
								<option key={part}>{part}</option>
							))}
						</select>
						<button className='RemoveFromList' id='RemoveFromList' onClick={RemoveFromList}>
							-
						</button>
						<button className='AddToList' id='AddToList' onClick={AddToList}>
							+
						</button>
					</div>
					<ul className='TableSendPartsList'>
						{ItemList.map((item) => (
							<li className='ItemList' key={item} value={item}>
								{item}
							</li>
						))}
					</ul>
				</div>
			</div>
			<button className='RTIButton' id='CreateJobClose' onClick={CreateJobClose}>
				Close
			</button>{' '}
			<button className='RTIButton' id='CreateButton' onClick={CreateJobBtn} style={{ float: 'right' }}>
				Dispatch
			</button>
		</div>
	);
};

CreateJob.propTypes = {
	kioskIDIssueDes: PropTypes.string,
	alertLength: PropTypes.number,
	kioskID: PropTypes.array,
	issueDescription: PropTypes.array,
	technicianList: PropTypes.array,
	kiosksInfo: PropTypes.array,
	kioskServerID: PropTypes.object,
	driveTimes: PropTypes.array,
	user: PropTypes.object,
	onAlert: PropTypes.func,
};
